import React, { useEffect } from 'react';
import Popup from 'reactjs-popup';

export default (props) => {
    const { open, onYes, onNo, msg, onClick, showOK } = props;
    //const { state: iniState } = useShared();
    //const { state: srState, processPayment, submitOrder } = useSubmitSR();
    const contentStyleForDesktopView = {
        padding: '0px',
        alignSelf: 'center',
        //width: '30%',
    }
    const overlayStyle = { background: 'rgba(0,0,0,0.5)' };


    return (
        <Popup
            open={open}
            contentStyle={contentStyleForDesktopView}
            overlayStyle={overlayStyle}
            closeOnDocumentClick={false}
            modal
            nested

        >

            <div className="flex max-w-sm mx-auto overflow-hidden bg-white rounded-md shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-green-500">
                    <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z" />
                    </svg>
                </div>

                <div className="px-2 py-1 pb-2 -mx-1 text-center">
                    <div className="mx-3">
                        <span className="font-semibold text-black-500 dark:text-black-500">CONFIRM</span>
                        <p className="text-sm text-gray-600 dark:text-gray-200 mt-4">{msg}</p>
                        {showOK ?
                            <div className="text-center mt-4 mb-4">
                                <button className={`p-1 h-7 w-10 text-sm bg-white border rounded text-black border-black`}
                                    onClick={onNo}>OK</button>
                            </div> :
                            <div className="text-center mt-4 mb-4">
                                <button className={`p-1 h-7 w-10 mx-2 text-sm bg-white border rounded text-black border-black`}
                                    onClick={onYes}>YES</button>
                                <button className={`p-1 h-7 w-10 text-sm bg-white border rounded text-black border-black`}
                                    onClick={onNo}>NO</button>
                            </div>}
                    </div>
                </div>
                <button className="float-right whitespace-normal h-6 w-6 mx-4 my-1 text-sm bg-white border rounded text-black" onClick={onClick}>
                    &times;
                </button>
            </div>
        </Popup >)
};