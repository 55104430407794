
/* eslint-disable no-unused-vars */

const calculatePages = (records, pageSize) => (records % pageSize > 0) ? Math.trunc(records / pageSize) + 1 : records / pageSize

const groupBy = (entries, field) => entries.reduce((agr, item) => {
  let list = agr[item[field]] = agr[item[field]] || [];
  list.push(item);
  return agr;
}, {});

const mapBatchDetails = (entries) => {
  const grouped = groupBy(entries, "Batch");
  return Object.values(grouped).map(entry => {
    return {
      BatchCreatedDate: entry[0].BatchCreatedDate,
      Batch: entry[0].Batch,
      ConsignmentNo: entry[0].ConsignmentNo,
      List: entry
    }
  }).reverse();
}

const cleanGetDeviceByIMEI = (state, payload) => ({
  GetUpdateIMEIDeviceDetails: ''
})

const cleanCancelResult = (state, payload) => ({
  ...state,
  claimFlowApiResponses: "",
  claimFlowApiResponsesForSave: "",
  claimFlowApiResponsesForSubmit: "",
  cancelOrderDetails: '',
  updateIMEIInquiryDetails:"",
  ResumeSRResponse: "",
  bulkSrSaveAndSubmitData: "",
  selectedAgreementBulk:[],
  addressDetails:""
})

const cleanAddressDetails = (state,payload) =>({
  ...state,
  addressDetails: "",
  getCityState: "",
  addressBulkDetails: ''
})

const cleanPreviousResultsNext = (state, payload) => ({
  ...state,
  claimFlowApiResponses: "",
  updateIMEIInquiryDetails:"",
  claimFlowApiResponsesForSave: "",
  claimFlowApiResponsesForSubmit: "",
  claimFlowApiResponsesForCancel: "",
  CustomerCaseGraphResponse: "",
  bulkDetails: "",
  bulkSuccessDetails: "",
  bulkSrSaveAndSubmitData: "",
  bulkClaimStatus: "",
  selectedAgreementBulk: []
})

const cleanPreviousResultsSubmit = (state, payload) => ({
  ...state,
  claimFlowApiResponsesForSave: "",
  claimFlowApiResponsesForSubmit: "",
  claimFlowApiResponsesForCancel: "",
  replacementDetails: "",
  ResumeSRResponse: "",
  CustomerCaseGraphResponse: "",
  bulkSrSaveAndSubmitData: "",
  selectedAgreementBulk:[],
  bulkClaimStatus : "",
  addressDetails: "",
  selectedAddressDetails:""
})

const cleanPreviousResultsPopup = (state, payload) => ({
  ...state,
  claimFlowApiResponsesForSubmit: "",
  claimFlowApiResponsesForCancel: "",
  replacementDetails: "",
  ResumeSRResponse: "",
  addressDetails : "",
  selectedAddressDetails : ""
})

const onGetDeviceDetailsSuccess = (state, payload) => ({
  ...state,
  OrderDetails: { ...payload, TotalPages: calculatePages(+payload.TotalRecords, 10) }, // DeviceList, ToBeBatched, TotalRecords
  isError: false,
  showProgressIndicator: false,
})

const onGetDeviceDetailsError = (state, payload) => ({
  ...state,
  OrderDetails: { Error: payload?.Error },
  isError: true,
  showProgressIndicator: false,
})

const onGetBatchDetailsSuccess = (state, payload) => ({
  ...state,
  BatchDetails: { ...payload, BatchItems: mapBatchDetails(payload.BatchItems), TotalPages: calculatePages(+payload.TotalRecords, 10) }, //BatchItems, TotalRecords
  isError: false,
  showProgressIndicator: false,
})

const onGetBatchDetailsError = (state, payload) => ({
  ...state,
  BatchDetails: { Error: payload?.Error },
  isError: true,
  showProgressIndicator: false,
})

const updateDeviceList = (state, payload) => ({
  ...state,
  OrderDetails: { ...state.OrderDetails, DeviceList: payload }, // DeviceList, ToBeBatched, TotalRecords
  isError: false,
  showProgressIndicator: false,
})

const onContactPointSuccess = (state, payload) => ({
  ...state,
  contactPointDetails: payload,
  isError: false,
  showProgressIndicator: false
})

const onContactPointError = (state, payload) => ({
  ...state,
  isError: true,
  showProgressIndicator: false
})

const onstandardiseaddressSuccess = (state, payload) => {
  return {
    ...state,
    addressDetails: payload,
    isError: false,
    showProgressIndicator : payload?.PickListAddress?.Score == 0 ? false : true
  }
}

const onstandardiseaddressBulkSuccess = (state, payload) => ({
  ...state,
  addressBulkDetails: payload,
  isError: false,
  showProgressIndicator: false
})

const onstandardiseaddressError = (state, payload) => ({
  ...state,
  isError: true,
  showProgressIndicator: false
})

const setProgressIndicator = (state, payload) => ({
  ...state,
  showProgressIndicator: true,
})

const unsetProgressIndicator = (state, payload) => ({
  ...state,
  showProgressIndicator: false,
})

const onBulkSuccess = (state, payload) => ({
  ...state,
  bulkDetails: payload,
  isError: false,
  showProgressIndicator: false
})

const onBulkSubmitError = (state, payload) => ({
  ...state,
  error: payload,
  isError: true,
  showProgressIndicator: false
})

const onBulkSubmitSuccess = (state, payload) => ({
  ...state,
  bulkSuccessDetails: payload,
  isError: false,
  showProgressIndicator: false
})

const onBulkError = (state, payload) => ({
  ...state,
  error: payload,
  isError: true,
  showProgressIndicator: false
})

const setBulkServiceRequest = (state, payload) => ({
  ...state,
  bulkSRdetails: payload,
  showProgressIndicator: false
})

const onGetCustomerCaseGraphSuccess = (state, payload) => {
  return {
    state,
    CustomerCaseGraphResponse: payload,
    isError: false,
    showProgressIndicator: false
  }
}

const onGetCustomerCaseGraphError = (state, payload) => ({
  ...state,
  isError: true,
  showProgressIndicator: false
})

const onResumeServiceRequestSuccess = (state, payload) => ({
  ...state,
  ResumeSRResponse: payload,
  isError: false,
  showProgressIndicator: false
})

const onResumeServiceRequestError = (state, payload) => ({
  ...state,
  isError: true,
  showProgressIndicator: false
})

const selectedAddressDetails = (state, payload) => ({
  ...state,
  selectedAddressDetails: payload,
})

const cancelBulkServiceRequestSuccess = (state, payload) => {
  return {
    ...state,
    cancelBulkData: payload,
    showProgressIndicator: false,
    isError: false,
  }
}

const cancelBulkServiceRequestError = (state, payload) => {
  return {
    ...state,
    isError: true,
    showProgressIndicator: false,
    cancelBulkError: payload
  }
}
const claimFlowApiRequestError = (state, payload) => ({
  ...state,
  isError: true,
  showProgressIndicator: false
})

const claimFlowApiRequestSuccess = (state, payload) => ({
  ...state,
  claimFlowApiResponses: payload,
  isError: false,
  showProgressIndicator: false
})

const claimFlowApiRequestSaveSuccess = (state, payload) => ({
  ...state,
  claimFlowApiResponsesForSave: payload,
  isError: false,
  showProgressIndicator: false
})
const claimFlowApiRequestSubmitSuccess = (state, payload) => ({
  ...state,
  claimFlowApiResponsesForSubmit: payload,
  isError: false,
  showProgressIndicator: false
})

const claimFlowApiRequestCancelSuccess = (state, payload) => ({
  ...state,
  claimFlowApiResponsesForCancel: payload,
  isError: false,
  showProgressIndicator: false
})

const onDetermineIncidentError = (state, payload) => ({
  ...state,
  isError: true,
  showProgressIndicator: false
})

const onDetermineIncidentSuccess = (state, payload) => ({
  ...state,
  bulkClaimStatus: payload,
  isError: false,
  showProgressIndicator: false
})

const onBulkSrSaveAndSubmitSuccess = (state, payload) => {
  return {
    ...state,
    isError: false,
    showProgressIndicator: false,
    bulkSrSaveAndSubmitData: payload
  }
}
const onBulkSrSaveAndSubmitError = (state, payload) => {
  return {
    ...state,
    isError: true,
    showProgressIndicator: false,
    bulkSrSaveAndSubmitData: payload
  }
}

const ongetCityStateError = (state, payload) => ({
  ...state,
  isError: true,
  showProgressIndicator: false
})

const ongetCityStateSuccess = (state, payload) => ({
  ...state,
  isError: false,
  showProgressIndicator: false,
  getCityState: payload
})

const IsAddressValid = (state, payload) => ({
  ...state,
  isAddressValid : payload
})

const onGetDeviceIMEIDetailsSuccess = (state, payload) => {
  return {
    ...state,
    isError: false,
    showProgressIndicator: false,
    GetUpdateIMEIDeviceDetails: payload?.GetDeviceByImeiResponse
  }
}
const onGetDeviceIMEIDetailsError = (state, payload) => {
  return {
    ...state,
    isError: true,
    showProgressIndicator: false,
    //GetUpdateIMEIDeviceDetails: payload
  }
}
const onGetPresignUrlSuccess = (state, payload) => {
  return {
    ...state,
    isError: false,
    showProgressIndicator: false,
    GetPresignUrlResponse: payload?.Files
  }
}
const onGetPresignUrlError = (state, payload) => {
  return {
    ...state,
    isError: true,
    showProgressIndicator: false,
    //bulkSrSaveAndSubmitData: payload
  }
}

const onUploadFileSuccess = (state, payload) => {
  return {
    ...state,
    isError: false,
    showProgressIndicator: false,
    UploadFileResponse: true
  }
}
const onUploadFileError = (state, payload) => {
  return {
    ...state,
    isError: true,
    showProgressIndicator: false,
    UploadFileResponse : false
  }
}

const onUpdateIMEIInquirySuccess = (state, payload) => {
  return {
    ...state,
    isError: false,
    showProgressIndicator: false,
    UpdateIMEIInquiryResponse: payload
  }
}
const onUpdateIMEIInquiryError = (state, payload) => {
  return {
    ...state,
    isError: true,
    showProgressIndicator: false,
    //bulkSrSaveAndSubmitData: payload
  }
}

const onAMTACheckSuccess = (state, payload) => {
  return {
    ...state,
    isError: false,
    showProgressIndicator: false,
    AMTAResponse: payload?.AMTAResponse
  }
}
const onAMTACheckError = (state, payload) => {
  return {
    ...state,
    isError: true,
    showProgressIndicator: false,
    //bulkSrSaveAndSubmitData: payload
  }
}
const cleanUpdateIMEIData = (state, payload) => ({
  ...state,
  GetUpdateIMEIDeviceDetails: "",
  GetPresignUrlResponse: "",
  UploadFileResponse: "",
  UpdateIMEIInquiryResponse: "",
  updateIMEIInquiryDetails:''
})

const onUpdateInquirySuccess = (state, payload) => ({
  ...state,
  showProgressIndicator: false,
  updateIMEIInquiryDetails: payload
})

export const reducers = {
  cleanPreviousResultsPopup,
  onContactPointError,
  onContactPointSuccess,
  onGetDeviceDetailsSuccess,
  onGetDeviceDetailsError,
  onBulkSubmitSuccess,
  onBulkSubmitError,
  onGetBatchDetailsSuccess,
  onGetBatchDetailsError,
  onstandardiseaddressError,
  onstandardiseaddressSuccess,
  unsetProgressIndicator,
  setProgressIndicator,
  cleanPreviousResultsNext,
  cleanPreviousResultsSubmit,
  cleanCancelResult,
  updateDeviceList,
  onBulkSuccess,
  onBulkError,
  setBulkServiceRequest,
  onGetCustomerCaseGraphSuccess,
  onGetCustomerCaseGraphError,
  onResumeServiceRequestSuccess,
  onResumeServiceRequestError,
  selectedAddressDetails,
  cancelBulkServiceRequestSuccess,
  cancelBulkServiceRequestError,
  claimFlowApiRequestError,
  claimFlowApiRequestSuccess,
  claimFlowApiRequestSaveSuccess,
  claimFlowApiRequestSubmitSuccess,
  claimFlowApiRequestCancelSuccess,
  onDetermineIncidentError,
  onDetermineIncidentSuccess,
  onBulkSrSaveAndSubmitSuccess,
  onBulkSrSaveAndSubmitError,
  ongetCityStateError,
  ongetCityStateSuccess,
  cleanAddressDetails,
  onstandardiseaddressBulkSuccess,
  IsAddressValid,
  onGetDeviceIMEIDetailsSuccess,
  onGetDeviceIMEIDetailsError,
  onGetPresignUrlSuccess,
  onGetPresignUrlError,
  onUpdateIMEIInquirySuccess,
  onUpdateIMEIInquiryError,
  onUploadFileSuccess,
  onUploadFileError,
  cleanUpdateIMEIData,
  onAMTACheckSuccess,
  onAMTACheckError,
  cleanGetDeviceByIMEI,
  onUpdateInquirySuccess
}

export const initialState = {
  showProgressIndicator: false,
  isError: false,
  isAddressValid : false
}
