import React, { useEffect } from 'react';
import { MemoryRouter as Router, useHistory } from 'react-router-dom'
import Popup from 'reactjs-popup';
import moment from 'moment';
import { useShared } from '../../../state-management/shared/SharedContextProvider';
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider';
import { useSubmitResumeSR } from '../../../state-management/resumeservicerequest/SubmitRSRContextProvider';
import Loader from '../../loader';

export default (props) => {
    const { open, onClick, data } = props;
    const history = useHistory()
    const { state: iniState, cleanInit } = useShared();
    const { state: resumeState } = useSubmitResumeSR();
    const { state: srState, cleanPreviousResultsNext, cleanPreviousResultsSubmit, claimFlowApiRequest } = useSubmitSR();
    const contentStyleForDesktopView = {
        padding: '0px',
        alignSelf: 'center',
        width: '40%',
    }

    let dispatchDate = srState?.claimFlowApiResponsesForSubmit?.ShippingMethodResponse?.EstimatedShipmentDate || srState?.claimFlowApiResponsesForAddressUpdate?.ShippingMethodResponse?.EstimatedShipmentDate || srState?.claimFlowApiResponsesForSave?.ShippingMethodResponse?.EstimatedShipmentDate || resumeState?.SingleResumeSRResponse?.ResumeServiceRequestResponse?.ShippingMethod?.EstimatedShipmentDate
    const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
    /* let Address;
    if (srState.addressDetails?.CorrectedAddress) {
        Address = Object.assign({}, { ...srState.addressDetails.CorrectedAddress });
    }
    else if (srState.selectedAddressDetails?.FirstName !== '') {
        Address = Object.assign({}, { ...srState.selectedAddressDetails })
    } */
    let claimFlowParameters = {
        EnterpriseClaimApiParameters: {
            PolicyNumber: iniState?.selectedAgreement[0]?.ClientAccount?.ClientAccountNumber,
            ServiceOrderId: srState?.claimFlowApiResponsesForSave?.ServiceOrderResponse?.ServiceOrderId || srState && srState.CustomerCaseGraphResponse && srState.CustomerCaseGraphResponse.CustomerCases && srState.CustomerCaseGraphResponse.CustomerCases[0] && srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests && srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests[0].ServiceOrder?.ServiceOrderId || srState?.claimFlowApiResponsesForSave?.ResumeResponse?.ServiceOrderId,
            ServiceOrderNumber: srState?.claimFlowApiResponsesForSave?.ServiceOrderResponse?.ServiceOrderNumber || srState && srState.CustomerCaseGraphResponse && srState.CustomerCaseGraphResponse.CustomerCases && srState.CustomerCaseGraphResponse.CustomerCases[0] && srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests && srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests[0].ServiceOrder?.ServiceOrderNumber || srState?.claimFlowApiResponsesForSave?.ResumeResponse?.ServiceOrderNumber,
            isOrderUnderReview: srState?.claimFlowApiResponsesForSave?.ChargeOrderResponse?.isOrderUnderReview || false,
            ContactPoint: iniState?.selectedAgreement[0]?.ContactPoints?.ContactPoint,
            Holds: srState?.claimFlowApiResponsesForSave?.UpdateShippingOrderResponse?.Holds || srState?.claimFlowApiResponsesForSave?.ProcessIncidentResponse?.Holds || [],
            CustomerCaseId: srState?.claimFlowApiResponsesForSave?.ResumeResponse?.CustomerCaseId || resumeState?.SingleResumeSRResponse?.ResumeServiceRequestResponse?.CustomerCaseId,
            ServiceRequestId: srState?.claimFlowApiResponsesForSave?.ResumeResponse?.ServiceRequestId || resumeState?.SingleResumeSRResponse?.ResumeServiceRequestResponse?.ServiceRequestId,
            ClientAccountId: iniState?.selectedAgreement[0]?.ClientAccount?.ClientAccountId,
            FullName: iniState?.selectedAgreement[0]?.Customers?.Customer[0]?.FullName,
            InteractionLineId: iniState?.InteractionLineId,
            AgreementId: iniState?.selectedAgreement[0]?.AgreementId,
            ChargeOrderId: srState?.claimFlowApiResponsesForSave?.ChargeOrderResponse?.ChargeOrderId || srState && srState.CustomerCaseGraphResponse && srState.CustomerCaseGraphResponse.CustomerCases && srState.CustomerCaseGraphResponse.CustomerCases[0] && srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests && srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests[0].ChargeOrder && srState && srState.CustomerCaseGraphResponse && srState.CustomerCaseGraphResponse.CustomerCases && srState.CustomerCaseGraphResponse.CustomerCases[0] && srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests && srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests[0].ChargeOrder.filter(i => i.ChargeOrderType === 'SWAPFEE')[0].ChargeOrderId || srState?.claimFlowApiResponsesForSave?.ResumeResponse?.ChargeOrderId,
            CustomerCaseNumber: srState?.claimFlowApiResponsesForSave?.ResumeResponse?.CustomerCaseNumber || resumeState?.SingleResumeSRResponse?.ResumeServiceRequestResponse?.CustomerCaseNumber,
            AuthorizeAdditionalCharges: true,
            ValidateAddress: true,
            ValidateCardSecurityCode: true,
            IpAddressForFraud: "",
            FirstName: iniState?.selectedAgreement[0]?.Customers?.Customer[0]?.FirstName,
            LastName: iniState?.selectedAgreement[0]?.Customers?.Customer[0]?.LastName,
            Address: {
                AddressId: iniState?.selectedAgreement[0]?.Address?.AddressId,
                ...srState.selectedAddressDetails
            },
            PaymentMethodType: "WIR"
        },
        FromRequest: "SUBMIT"
    }

    const onSubmit = () => {
        claimFlowApiRequest(claimFlowParameters);
    }

    useEffect(() => {
        if (srState?.claimFlowApiResponsesForSubmit) {
            cleanInit();
            cleanPreviousResultsNext();
            cleanPreviousResultsSubmit();
            if (resumeState?.isReusmeSR) {
                history.replace('/resume-cancel-service-request')
            }
            else {
                history.replace('/')
            }
        }
    }, [srState?.claimFlowApiResponsesForSubmit]);

    const getAddressStr = (address) => {
        if (address != undefined) {
            const { AddressLine1, AddressLine2, AddressLine3, CityName, PostalCode, CountryCode } = address;
            let returnAddress;
            returnAddress = [AddressLine1, AddressLine2, AddressLine3, CityName, PostalCode, CountryCode].filter(Boolean).join(", ");
            return returnAddress;
        }
        return;
    }

    const getFullName = () => {
        let fullName = srState?.selectedAddressDetails?.FirstName && srState?.selectedAddressDetails?.LastName ? srState?.selectedAddressDetails?.FirstName + ' ' + srState?.selectedAddressDetails?.LastName : data?.Customers.Customer[0].FullName;
        return fullName
    }

    return (
        <Popup
            open={open}
            contentStyle={contentStyleForDesktopView}
            overlayStyle={overlayStyle}
            closeOnDocumentClick={false}
            modal
            nested

        >
            {(iniState?.showProgressIndicator || srState?.showProgressIndicator) && <Loader isModalPopUp = 'isModalPopUp'  />}
            <div className="border bg-gray-100 w-auto p-4 bg-white rounded-md shadow-md dark:bg-gray-800">
                <button className="absolute right-px whitespace-normal h-6 w-6 mx-4 text-sm bg-white border rounded text-black" onClick={onClick}>
                    &times;
                </button>
                <div className="font-bold text-center">CONFIRM SWAP REQUEST</div>
                <div className="">
                    <div className="pl-6 pt-4 text-sm font-normal w-full py-1 text-black font-bold">Device Details</div>
                    <hr className="shadow" />
                    <div className="pl-10 flex flex-row pt-2 justify-start">
                        <div className="text-sm flex-column font-bold">
                            IMEI<br />
                            Model<br />
                        </div>
                        <div className="pl-8 flex-column text-sm flex-grow">
                            {data?.Assets.Asset[0].IMEI}<br />
                            {data?.Assets.Asset[0].Model?.Name}<br />
                        </div>
                    </div>

                    <div className="pl-6 pt-6 text-sm font-normal w-full py-1 text-black font-bold">Delivery Details</div>
                    <hr className="shadow" />
                    <div className="pl-10 flex flex-row pt-2 justify-start">
                        <div className="text-sm flex-column font-bold">
                            Name<br />
                            Address<br />
                            Dispatch Date<br />
                        </div>
                        <div className="pl-8 flex-column text-sm">
                            {getFullName()}<br />
                            {getAddressStr(srState?.selectedAddressDetails)}<br />
                            {dispatchDate && moment(dispatchDate).format('DD MMM YY')}<br />
                            {/* <div>As your enrolled device is <strong>Out of stock </strong>, We have placed your request on priority Back order.<br/>We will contact you once the stock is made available to make delivery arrangements.<br/><br/>This typically takes <strong><i>up to 5 business days</i></strong></div> */}
                        </div>
                    </div>
                    <div className="pl-6 pt-6 text-sm font-normal w-full py-1 text-black font-bold">Swap Allowance</div>
                    <hr className="shadow" />
                    <div className="pl-10 flex flex-row pt-2 justify-start">
                        <div className="text-sm flex-column font-bold">
                            Used<br />
                            Remaining<br />
                        </div>
                        <div className="pl-8 flex-grow text-sm flex-column">
                            {resumeState?.isReusmeSR ? (resumeState?.GetResumeServiceRequest?.result?.TotalAllowance - resumeState?.GetResumeServiceRequest?.result?.SwapRemaining) : data?.TotalAllownce - (data?.SwapAllowanceRemaining - 1)}<br />
                            {resumeState?.isReusmeSR ? resumeState?.GetResumeServiceRequest?.result?.SwapRemaining : (data?.SwapAllowanceRemaining - 1)}<br />
                        </div>
                    </div>
                </div>
                <div className="px-12 py-4 text-center">
                    <button className={`p-1 h-8 w-64 focus:bg-blue-100 text-sm bg-white border rounded text-black border-black cursor-pointer`}
                        onClick={onSubmit}>SUBMIT MY SWAP REQUEST</button>
                </div>
            </div>
        </Popup >)
};