import React, { Component, useContext, useEffect } from 'react';
import { MemoryRouter as Router, Switch, Route, Redirect, NavLink, useHistory } from 'react-router-dom'
import { useShared } from '../../../state-management/shared/SharedContextProvider';
import ResumeOrCancelSR from './resumeorcancelsr';


const ResumeOrCancelServiceRequest = () => {

    return (
        <div className=""><ResumeOrCancelSR/></div>
    )
}

export default ResumeOrCancelServiceRequest