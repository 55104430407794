/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import SharedAPI from './api';
import Promise from 'bluebird';

const token = {
  data: {
    "access_token": "gpFHfq53glTkbKhmgQBzDQfhH",
    "token_type": "bearer",
    "access_token_expires_in": 86400,
    "expires_in": 86400,
    "refresh_token": "-1",
    "refresh_token_expires_in": -1,
    "userId": "xBLFQ4hRa3rbQSAML1DT9D_Usfwi3PyLOlh73ed4IIo"
  }
}
const initialize = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SharedAPI.initialize(data.initializeParam)
    .then((initializeResult) => {
      actions.onInitializeSuccess(initializeResult?.data?.Configuration)
      if (initializeResult?.data?.Configuration) {
        return SharedAPI.interaction(data.interactionParam)
          .then((interactionResult) => {
            actions.onInteractionSuccess(interactionResult?.data)
          })
          .catch((error) => {
            actions.onInteractionError(error?.response?.data ?? error)
          })
      }
    })
    .catch((error) => {
      actions.onInitializeError(error?.response?.data ?? error)
    })
}

const findAgreements = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SharedAPI.findAgreements(data)
    .then((data) => {
      actions.onAgreementSuccess(data?.data)
    })
    .catch((error) => {
      actions.onAgreementError(error?.response?.data ?? error)
    })
}

const getAccessToken = (actions) => (data) => {
  actions.setProgressIndicator(true);
  //return Promise.resolve(token)
  return SharedAPI.getAccessToken(data)
    .then((data) => {
      actions.onAccessTokenSuccess(data?.data)
    })
    .catch((error) => {
      actions.onAccessTokenError(error?.response?.data ?? error)
    })
}

const updateInteraction = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SharedAPI.updateInteraction(data)
    .then((data) => {
      actions.onUpdateInteractionSuccess(data?.data)
    })
    .catch((error) => {
      actions.onUpdateInteractionError(error?.response?.data ?? error)
    })
}

const getLicenses = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return SharedAPI.getLicenses(data)
  .then((data) =>{
    actions.onGetLicensesSuccess(data?.data)
  }).catch((error)=>{
    actions.onGetLicensesError(error)
  })
}

const getUserInfo = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SharedAPI.getUserInfoRequest(data)
    .then((data) => {
      actions.onGetUserInfoSuccess(data?.data)
    })
    .catch((error) => {
      actions.onGetUserInfoError(error?.response?.data ?? error)
    })
}

export const middleware = {
  initialize,
  findAgreements,
  getAccessToken,
  updateInteraction,
  getLicenses,
  getUserInfo
}
