import React, { Component, useContext, useEffect } from 'react';
import { useShared } from '../../../state-management/shared/SharedContextProvider';

const SSOGetEmailId = (props) => {
  const { state: iniState, getUserInfo, getLicenses, setLicenseKey, updateInteraction } = useShared();

 useEffect(()=> {
   if(!iniState?.GetUserInfoResponse && props?.getEmailProps?.Token){
      getUserInfo({GetUserInfo: props?.getEmailProps})
   }
 }, [props?.getEmailProps?.Token])

 useEffect(() => {
     if(!iniState?.GetLicenseKeysResponse && iniState?.GetUserInfoResponse){
      getLicenses({EmailAddress: iniState?.GetUserInfoResponse?.email})
     }
 },[iniState?.GetUserInfoResponse])

 useEffect(() =>{
   if(!iniState.UpdateInteractionResponse && iniState?.GetLicenseKeysResponse){
    setLicenseKey(iniState?.GetLicenseKeysResponse?.LicenseKey)
    updateInteraction(props?.updateInteractionParam)
   }
 },[iniState?.GetLicenseKeysResponse])

  return (
    <>
    </>
  )
}

export default SSOGetEmailId