import React from 'react'
import loader from '../assets/loader.jpg';

const Loader = ({isModalPopUp}) => (
  <div className={`z-1060 bg-black bg-opacity-10 fixed table-cell text-center align-middle h-full bottom-0 w-full ${isModalPopUp === 'isModalPopUp' ? ` left-0` : "top-0"}`}>
      <div className = {isModalPopUp === 'isModalPopUp' ? "flex justify-center center" : "relative inset-50" } >
      <img className="w-1/12 bg-transparent" src={loader} alt="" />
    </div>
  </div>
)

export default Loader
