import { useEffect, useState } from 'react';
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider';
import { validateForm, IsRequired } from '../../../utils/helper';
import Notification from '../../../utils/notification';

const SubmitSRAddress = (props, children) => {
    const { data, isValid } = props;
    const { state: srState, selectedAddressDetails, getCityState } = useSubmitSR();
    const [deliverTo, setDeliverTo] = useState('admin');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [addline1, setAddLine1] = useState('');
    const [addline2, setAddLine2] = useState('');
    const [addline3, setAddLine3] = useState('');
    const [city, setCity] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [postalcode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [showCityCountrySelect, setShowCityCountrySelect] = useState(false);
    const [showCityCountryText, setShowCityCountryText] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [standardCities, setStandardCities] = useState();
    const [invalidAddress, isInvalidAddress] = useState(false);
    const defaultFname = data?.Customers?.Customer[0]?.FirstName;
    const defaultLname = data?.Customers?.Customer[0]?.LastName;
    const defaultAddline1 = data?.Address?.Address1;
    const defaultAddline2 = data?.Address?.Address2;
    const defaultAddline3 = data?.Address?.Address3;
    const defaultCity = data?.Address?.City;
    const defaultPostalcode = data?.Address?.PostalCode;
    const defaultCountry = data?.Address?.CountryCode;

    const onDeliverToChanged = (e) => {
        setDeliverTo(e.target.value);
        if (e.target.value === 'admin') {
            setShowCityCountrySelect(false);
            setShowCityCountryText(true);
        } else {
            setShowCityCountryText(true);
            setCity('')
            setSelectedCity('');
            setPostalCode('');
            setCountry('');
        }
    }

    useEffect(() => {
        if (addline1 || addline2 || addline3 || city || postalcode) {
            isInvalidAddress(false)
            let isInvalid = false;
            let firstName = deliverTo === 'admin' ? defaultFname : fname;
            let lastName = deliverTo === 'admin' ? defaultLname : lname;
            let poAddress = `${addline1},${addline2},${addline3}`;
            let keywords = 'p.o.|po|post|army post|army po|post office|pobox|po box|diplomaticpo|postal|fleetpo|fleet po|armypo|post.off.|postoff|postoffice|postofficebox|correos|oficina|apo|fpo|dpo|po|pob|a.p.o.|f.p.o.|f.p.o|d.p.o.|apartado|aptdo|buzon|callbox|caller|gpobox|poboxs-1190|correos|caja postal|casilla postal|p.o.box|armypostoffice|fleetpostoffice|armyp.o.|fleetp.o.|diplomaticpostoffice|diplomatic.p.o.|private bag|Police Box|lockbag|po box 379';
            keywords = keywords.split('|');
            isInvalid = keywords.map(k => poAddress.toLowerCase().includes(k)).includes(true);

            if (addline1 === '17 post office place' || addline1 === '17 post office pl' || addline2 === '17 post office place' ||
                addline2 === '17 post office pl' || addline3 === '17 post office place' ||
                addline3 === '17 post office pl' || city === 'Victoria' || postalcode === '3844') {
                isInvalid = true
            }
            isInvalidAddress(isInvalid)
            selectedAddressDetails({ FirstName : firstName, LastName : lastName,AddressLine1: addline1, AddressLine2: addline2, AddressLine3: addline3, CityName: city, PostalCode: postalcode })
            isValid(isInvalid);
        }
    }, [addline1, addline2, addline3, city, postalcode])

    useEffect(() => {
        if(isError?.error){
            setErrorMsg(`${isError?.fieldName} is required.`);
        }
        else {
            setErrorMsg('');
        }
        /* isError ? setErrorMsg("Inputs not in desired format. Please check and enter corrected values.") :
            setErrorMsg(''); */
    }, [isError]);

    const onPostalCodeChange = (e) => {
        setPostalCode(e.target.value);
        if (e.target.value.length >= 4) {
            let params = Object.assign({}, { ZipCode: e.target.value })
            getCityState({ ...params })
        }
    }

    useEffect(() => {
        if (srState?.getCityState?.GetCityStatebyZipCodeResponse) {
            if (srState?.getCityState?.GetCityStatebyZipCodeResponse.length == 1) {
                setCity(srState?.getCityState?.GetCityStatebyZipCodeResponse[0].City)
                setCountry(srState?.getCityState?.GetCityStatebyZipCodeResponse[0].Country)
                setShowCityCountryText(true);
                setShowCityCountrySelect(false);
                selectedAddressDetails({
                    FirstName: fname || defaultFname,
                    LastName: lname || defaultLname,
                    AddressLine1: addline1 || defaultAddline1,
                    AddressLine2: addline2 || defaultAddline2,
                    AddressLine3: addline3 || defaultAddline3,
                    CityName: srState?.getCityState?.GetCityStatebyZipCodeResponse[0].City,
                    PostalCode: postalcode || defaultPostalcode,
                    CountryCode: srState?.getCityState?.GetCityStatebyZipCodeResponse[0].Country
                })
            } else {
                let standardCity = srState?.getCityState?.GetCityStatebyZipCodeResponse.map(i => i.City)
                setStandardCities(standardCity)
                setSelectedCity(standardCity[0])
                let standardCountry = srState?.getCityState?.GetCityStatebyZipCodeResponse.map(i => i.Country)
                setCountry(standardCountry[0])
                setShowCityCountrySelect(true)
                setShowCityCountryText(false)
                selectedAddressDetails({
                    FirstName: fname || defaultFname,
                    LastName: lname || defaultLname,
                    AddressLine1: addline1 || defaultAddline1,
                    AddressLine2: addline2 || defaultAddline2,
                    AddressLine3: addline3 || defaultAddline3,
                    CityName: standardCity[0],
                    PostalCode: postalcode || defaultPostalcode,
                    CountryCode: standardCountry[0]
                })
            }
        }
    }, [srState.getCityState])

    useEffect(() => {
        setShowCityCountrySelect(false);
        setShowCityCountryText(true);
        if (srState?.addressDetails?.AddressResult?.Score == 100) {
            setFname(fname)
            setLname(lname)
            setAddLine1(srState.addressDetails?.AddressResult?.CorrectedAddress?.AddressLine1)
            setAddLine2(srState.addressDetails?.AddressResult?.CorrectedAddress?.AddressLine2)
            setAddLine3(srState.addressDetails?.AddressResult?.CorrectedAddress?.AddressLine3)
            setCity(srState.addressDetails?.AddressResult?.CorrectedAddress?.CityName)
            setPostalCode(srState.addressDetails?.AddressResult?.CorrectedAddress?.PostalCode)
            setCountry(srState.addressDetails?.AddressResult?.CorrectedAddress?.CountryCode)
            selectedAddressDetails({
                FirstName: fname,
                LastName: lname,
                AddressLine1: srState.addressDetails?.AddressResult?.CorrectedAddress?.AddressLine1,
                AddressLine2: srState.addressDetails?.AddressResult?.CorrectedAddress?.AddressLine2,
                AddressLine3: srState.addressDetails?.AddressResult?.CorrectedAddress?.AddressLine3,
                CityName: srState.addressDetails?.AddressResult?.CorrectedAddress?.CityName,
                PostalCode: srState.addressDetails?.AddressResult?.CorrectedAddress?.PostalCode,
                CountryCode: srState.addressDetails?.AddressResult?.CorrectedAddress?.CountryCode
            })
        }
        else {
            let isAddressMatch = srState?.addressDetails?.PickListAddress?.Score == "0" ? false : true;
            selectedAddressDetails({
                FirstName: deliverTo !== 'admin' ? fname : defaultFname,
                LastName: deliverTo !== 'admin' ? lname : defaultLname,
                AddressLine1: deliverTo !== 'admin' ? addline1 : defaultAddline1,
                AddressLine2: deliverTo !== 'admin' ? addline2 : defaultAddline2,
                AddressLine3: deliverTo !== 'admin' ? addline3 : defaultAddline3,
                CityName: deliverTo !== 'admin' ? (isAddressMatch ? city : (standardCities && standardCities[0]) || (srState?.selectedAddressDetails?.CityName)) : defaultCity,
                PostalCode: deliverTo !== 'admin' ? postalcode : defaultPostalcode,
                CountryCode: deliverTo !== 'admin' ? country : defaultCountry
            })
        }
    }, [srState.addressDetails]);

    useEffect(() => {
        if(deliverTo !== 'admin'){
            setFname(undefined)
            setLname(undefined)
            setAddLine1('')
            setAddLine2('')
            setAddLine3(undefined)
            setCity(undefined)
            setPostalCode(undefined)
            setCountry(undefined)
        }
        else {
            let params = Object.assign({}, { ZipCode: defaultPostalcode })
            getCityState({ ...params })
            // selectedAddressDetails({
            //     FirstName: defaultFname,
            //     LastName: defaultLname,
            //     AddressLine1: defaultAddline1,
            //     AddressLine2: defaultAddline2,
            //     AddressLine3: defaultAddline3,
            //     CityName: defaultCity,
            //     PostalCode: defaultPostalcode,
            //     CountryCode: defaultCountry
            // })
        }
    }, [deliverTo])

    return (
        <>
            <div className="border-2 border-solid px-2 ">
                <div className="left-px text-sm font-normal w-full py-2 text-black">CONFIRM DELIVERY DETAILS</div>
                <hr className="shadow" />
                <form>
                    <div className={deliverTo === 'admin' ? "bg-blue-100 pt-3" : "pt-3 bg-purple-50"}>
                        <input type="radio" id="admin" name="deliver" value="admin"
                            checked={deliverTo === 'admin'} onChange={onDeliverToChanged} />
                        <label htmlFor="admin" className="pl-2">Deliver to Administrator</label>
                    </div>
                    <div className={deliverTo === 'user' ? "bg-blue-100 pt-3" : "pt-3 bg-purple-50"}>
                        <input type="radio" id="user" name="deliver" value="user" checked={deliverTo === 'user'} onChange={onDeliverToChanged} />
                        <label htmlFor="user" className="pl-2">Deliver to Asset User</label>
                    </div>
                    <div className="text-red-400 text-bold">{errorMsg}</div>
                    <div className="grid grid-cols-2 grid-flow-rows gap-2 pt-2">
                        <div>
                            <label htmlFor="fname">First Name</label><br />
                            <input type="text" id="fname" name="fname" className="border rounded border-solid pl-2 pr-2 w-40"
                                disabled={deliverTo === 'admin'}
                                onChange={(e) => {
                                    setFname(e.target.value);
                                    setError(IsRequired(true, e.target.value, 'First Name'));
                                }}
                                value={deliverTo === 'admin' ? defaultFname : fname} />
                        </div>
                        <div>
                            <label htmlFor="surname">Surname</label><br />
                            <input type="text" id="surname" name="surname" className="border rounded 
                            border-solid pl-2 pr-2 w-40" value={deliverTo === 'admin' ? defaultLname : lname}
                                disabled={deliverTo === 'admin'}
                                onChange={(e) => {
                                    setLname(e.target.value)
                                    setError(IsRequired(true, e.target.value, 'Surname'))
                                }} />
                        </div>
                    </div>
                    <div className="py-2">
                        <label htmlFor="addline1">Address line 1</label>
                        <input type="text" id="addline1" name="addline1" className="border rounded w-full border-solid pl-2 pr-2"
                            onChange={(e) => {
                                setAddLine1(e.target.value)
                                setError(IsRequired(true, e.target.value, 'Address line 1'))
                            }}
                            value={deliverTo === 'admin' ? defaultAddline1 : addline1}
                            disabled={deliverTo === 'admin'} />
                    </div>
                    <div className="py-2">
                        <label htmlFor="addline2">Address line 2</label>
                        <input type="text" id="addline2" name="addline2" className="border rounded w-full border-solid pl-2 pr-2"
                            onChange={(e) => {
                                setAddLine2(e.target.value)
                                //setError(validateForm('text', e.target.value))
                            }}
                            value={deliverTo === 'admin' ? defaultAddline2 : addline2}
                            disabled={deliverTo === 'admin'} />
                    </div>
                    <div className="py-2">
                        <label htmlFor="addline3">Address line 3</label>
                        <input type="text" id="addline3" name="addline3" className="border rounded w-full border-solid pl-2 pr-2"
                            onChange={(e) => {
                                setAddLine3(e.target.value)
                                //setError(validateForm('text', e.target.value))
                            }}
                            value={deliverTo === 'admin' ? defaultAddline3 : addline3}
                            disabled={deliverTo === 'admin'} />
                    </div>
                    <div className="grid grid-cols-2 grid-flow-rows gap-2 py-2">
                        <div>
                            <label htmlFor="city">City/Suburb</label>
                            <br />
                            {showCityCountryText ?
                                <input type="text" id="city" name="city" className="border rounded w-25 border-solid pl-2 pr-2 w-40"
                                    onChange={(e) => {
                                        setCity(e.target.value)
                                        setError(validateForm('text', e.target.value))
                                    }}
                                    value={city} disabled /> :
                                showCityCountrySelect && <select className="border rounded w-25 border-solid pl-2 pr-2 w-40" value={selectedCity} onChange={(e) => {
                                    setSelectedCity(e.target.value);
                                    selectedAddressDetails({
                                        FirstName: fname,
                                        LastName: lname,
                                        AddressLine1: addline1,
                                        AddressLine2: addline2,
                                        AddressLine3: addline3,
                                        CityName: e.target.value,
                                        PostalCode: postalcode,
                                        CountryCode: country
                                    })
                                }}>{standardCities.map((x, y) =>
                                    <option key={y} value={x}>{x}</option>)
                                    }</select>}
                        </div>
                        <div>
                            <label htmlFor="postcode">Postcode</label>
                            <br />
                            <input type="text" id="postcode" name="postcode" className="border rounded w-25 border-solid pl-2 pr-2 w-40"
                                onChange={(e) => {
                                    onPostalCodeChange(e)
                                    setError(IsRequired(true, e.target.value, 'Postcode'))
                                }}
                                value={deliverTo === 'admin' ? defaultPostalcode : postalcode} disabled={deliverTo === 'admin'} />
                        </div>
                    </div>
                    <div className="py-2">
                        <label htmlFor="country">Country</label>
                        <br />
                        <input type="text" id="country" name="country" className="border rounded w-25 border-solid pl-2 pr-2 w-40"
                            onChange={(e) => {
                                setCountry(e.target.value)
                                setError(validateForm('text', e.target.value))
                            }}
                            value={deliverTo === 'admin' ? defaultCountry : country} disabled />
                    </div>
                </form>
                {invalidAddress && <Notification type="error" msg='Please enter valid address' />}
            </div>
            {props.children}
        </>
    )
}

export default SubmitSRAddress;