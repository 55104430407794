
const isEven = (n) => n % 2 === 0;

const headerRow = ({ fields, showCheck, isChecked, showRadioCheck }) => (onHeaderCheckChange, onSort) =>
    (
      <tr className="border border-black bg-black text-white">
        {showRadioCheck && (
          <th className="font-normal py-1 px-2 w-10"> </th>
        )}

        {showCheck && (
          <th className="font-normal py-1 px-2 w-10">
            {/* <input type="checkbox" value={isChecked} onChange={() => onHeaderCheckChange(!isChecked)} name="selectAll" /> */}
          </th>
        )}

        {fields.map((field) => (
          <th className="font-normal py-1 align-top" key={field.name}>
            <div className="flex px-1">
              <span
                className="flex-1 text-left"
                dangerouslySetInnerHTML={{
                  __html: field.label.split('|').join('<br/>'),
                }}
              />
            </div>
          </th>
        ))}
      </tr>
    )

const dataRow = (fields) => (data, i, onRowCheckChange, reprintPDF) => {
  const getValue = (field, data) => {
    const value = (field.formatter ? field.formatter(data, field) : data[field.name]) || '';
    return typeof value === 'string' ? value.split('|').join('<br/>') : value
  }

  return (
    <tr key={`row-${i}`} className={`border border-gray-dark ${isEven(i) ? 'bg-gray-light' : ''}`}>
      {fields.showCheck && (
        <td className="font-normal py-1 px-2 align-top w-10">
          <input type="checkbox" value={fields.isChecked || data.isChecked} checked={data.isChecked} onChange={(e) => onRowCheckChange(data, i)} />
        </td>
      )}  

      {fields.showRadioCheck && (
        <td className="font-normal py-1 px-2 align-top w-10">
          <input type="radio" name={data.name} value={fields.isChecked || data.isChecked || false} checked={data?.isChecked} onChange={(e) => onRowCheckChange(data, i)} />
        </td>
      )} 

      {fields.fields.map((field) => field.name === 'reprintReturnLabel' && getValue(field, data) === 'LABEL PDF' ? (
        <td
          className="py-1 px-1 align-top cursor-pointer"
          key={`${field.name}-${data[fields.key]}`} onClick={()=>reprintPDF(data.serviceRequestId,data.srNo)}>
          {getValue(field, data)}
        </td>
      ) : (<td
        className="py-1 px-1 align-top"
        key={`${field.name}-${data[fields.key]}`}>
        {getValue(field, data)}
      </td>))}
    </tr>
  )
}

const tableFormat = {
  headerRow,
  dataRow
}

export default tableFormat;