import React, { useState, useEffect } from 'react';
import Grid from '../../grid';
import tableFormat from '../../tableformat';
import { useShared } from '../../../state-management/shared/SharedContextProvider';
import { useManageMyAccount } from '../../../state-management/managemyaccount/ManageMyAccountContextProvider';
import moment from 'moment';
import Pagination from '../../pagination'
const INVOKE_ONCE = true;
//import { base64 } from './reprintresponsedummy';

const PreviousServiceRequest = (props) => {
  let { type, data } = props;
  const perpageData = 10;
  const { state: iniState } = useShared();
  const { state: srState, getAllCustomerCaseGraph, reprintLabel } = useManageMyAccount();
  const [allCustomerCaseData, setAllCustomerCaseData] = useState([]);
  const [listData, setListData] = useState([]);
  const [countAllData, setCountAllData] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [noSearchRecords, setNoSearchRecords] = useState(false);

  useEffect(() => {
    setNoSearchRecords(false);
    if (props.searchData.type === '') {
      let newListData = [];
      allCustomerCaseData.forEach((element, index) => {
        if (index + 1 > (pageNumber - 1) * perpageData && index + 1 <= (pageNumber) * perpageData) {
          newListData.push(element);
        }
      })
      setListData(newListData);
    }
    if (props.searchData.type === 'IMEI') {
      if (allCustomerCaseData.filter(i => i.IMEI === props.searchData.data).length > 0) {
        setNoSearchRecords(false);
        setListData(allCustomerCaseData.filter(i => i.IMEI === props.searchData.data))
      } else {
        setListData([])
        setNoSearchRecords(true);
      }
    }
    if (props.searchData.type === 'SRNo') {
      if (allCustomerCaseData.filter(i => i.srNo == props.searchData.data).length > 0) {
        setNoSearchRecords(false);
        setListData(allCustomerCaseData.filter(i => i.srNo == props.searchData.data))
      } else {
        setListData([])
        setNoSearchRecords(true);
      }
    }
    // if (props.searchData.type === 'LICKey') {
    //   if (allCustomerCaseData.filter(i => i.LICKey == props.searchData.data).length > 0) {
    //     setNoSearchRecords(false);
    //     setListData(allCustomerCaseData.filter(i => i.LICKey == props.searchData.data))
    //   } else {
    //     setListData([])
    //     setNoSearchRecords(true);
    //   }
    // }
  }, [props]);

  useEffect(() => {
    if (srState?.allCustomerCaseGraphResponse !== undefined) {
      let newListData = [];
      let getAllDataResponse = srState?.allCustomerCaseGraphResponse?.results;
      newListData = getAllDataResponse.map((element) => {
        return {
          srNo: element?.ClaimId || "",
          IMEI: element?.IMEI || "",
          DeviceModelName: element?.ModelName || "",
          dateSubmitted: element?.ClaimCreatedDate && moment(element?.ClaimCreatedDate).format('DD MMM YYYY') || "",
          dateShipped: element?.ShippedDate && moment(element?.ShippedDate).format('DD MMM YYYY') || "",
          replacementImei: element?.ReeplacedIMEI || "",
          trackingNoSent: element?.TrackingNumber || "",
          trackingNoreturn: element?.ReturnTrackingNumber || "",
          reprintReturnLabel: element?.ReturnTrackingNumber ? "LABEL PDF" : "",
          claimStatus: element?.ClaimStatus,
          printProofToSwap: "xxx-xx-x",
          serviceRequestId: element?.ServiceRequestId,
          LICKey: element?.LicenseKey
        }
      })
      setAllCustomerCaseData(newListData);
      setCountAllData(srState?.allCustomerCaseGraphResponse?.totalRecords);
    }
  }, [srState?.allCustomerCaseGraphResponse])

  useEffect(() => {
    let newListData = [];
    allCustomerCaseData.forEach((element, index) => {
      if (index + 1 > (pageNumber - 1) * perpageData && index + 1 <= (pageNumber) * perpageData) {
        newListData.push(element);
      }
    })
    setListData(newListData);
  }, [allCustomerCaseData, pageNumber])

  useEffect(() => {
    getAllCustomerCaseGraph({ AccountParameter: { "LicenseKey": iniState?.LicenseKey } })
  }, [INVOKE_ONCE])

  const _grdFields = {
    key: 'Service Request No',
    //showCheck: true,
    fields: [
      { name: 'srNo', label: 'Swap Request No' },
      { name: 'IMEI', label: 'IMEI' },
      { name: 'DeviceModelName', label: 'Device Model Name' },
      { name: 'LICKey', label: 'License Key' },
      { name: 'dateSubmitted', label: 'Date Created' },
      { name: 'dateShipped', label: 'Date Shipped' },
      { name: 'replacementImei', label: 'Replacement IMEI' },
      { name: 'trackingNoSent', label: 'Tracking No Send' },
      { name: 'trackingNoreturn', label: 'Tracking No Return' },
      { name: 'reprintReturnLabel', label: 'Reprint Return Label(Elabel)' },
      { name: 'claimStatus', label: 'Status' },
    ],
  }
  const [grdFields, setGrdFields2] = useState(_grdFields);
  const onRowCheckChange = (data, index) => { }
  const onHeaderCheckChange = () => { }

  const reprintPDF = (srID, claimId) => {
    let data = {
      RePrintLabelParameter: {
        CaseNumber: claimId.toString()
      }
    }
    let queryData = {
      srID: srID,
      data: data
    }
    reprintLabel(Object.assign({}, queryData));
  }

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "service_request_label.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useEffect(() => {
    if (srState.reprintSuccess?.rePrintLabel) {
      let base = srState?.reprintSuccess?.rePrintLabel;
      downloadPDF(base);
    }
  }, [srState.reprintSuccess])

  return (
    <div className="overflow-x-auto">
      <Grid
        className="text-sm font-normal w-full border border-black border-collapse"
        onRowCheckChange={onRowCheckChange}
        onHeaderCheckChange={onHeaderCheckChange}
        reprintPDF={reprintPDF}
        list={listData}
        headerRow={tableFormat.headerRow(grdFields)}
        dataRow={tableFormat.dataRow(grdFields)}
      />
      {noSearchRecords && <div>No Records Found</div>}
      <div className="mt-2">
        {listData && <Pagination
          pageNumber={pageNumber ? pageNumber : 1}
          //totalPages={!imei ? iniState?.FindAgreement?.TotalPages : 1}
          totalPages={Math.ceil(countAllData / perpageData) || 1}
          onPageClicked={(page) => setPageNumber(page)} />}
      </div>
    </div>
  )
}

export default PreviousServiceRequest