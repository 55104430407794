import React, { Component, useState, useEffect } from 'react';
import SingleSRSubmit from './submitnewsr';
import ip from 'ip';
import moment from 'moment';
//import { MemoryRouter as Router, Switch, Route, Redirect, NavLink, useHistory } from 'react-router-dom'
import { useShared } from '../../../state-management/shared/SharedContextProvider';
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider'
import Loader from '../../loader';
import NotFound from '../../notFound';
import SSOGetEmailId from './ssogetemailid';
const INVOKE_ONCE = true;

const SubmitServiceRequest = () => {
  const { state: iniState, initialize, findAgreements, getAccessToken, updateInteraction, setInteractionLineId } = useShared();
  const { state: srState} = useSubmitSR();
  const location = window.location.search;
  const authCode = new URLSearchParams(location).get('code');
  const tokenUrl = new URLSearchParams(location).get('api_server_url');
  const license_keys = new URLSearchParams(location).get('orderid');
  //const userName = new URLSearchParams(location).get('user_name');
  const state = new URLSearchParams(location).get('state');
  const [callUserInfo, setCallUserInfo] = useState(false);
  let stateArray = state && state.split(' ');
  let interactionLineId = stateArray && stateArray[0];
  let isUserInfoCall = stateArray && stateArray[1] && stateArray[1] == 'true'? true : false;
  let IsSSOAuth = process.env.REACT_APP_SSO_AUTH;
  let Alias = process.env.REACT_APP_ALIAS;
  let ClientId = process.env.REACT_APP_CLIENTID;
  let RedirectUrl = process.env.REACT_APP_REDIRECT_URL;
  let goBackURL = process.env.REACT_APP_GO_BACK_URL;
  let accessTokenCookie = document.cookie.split(';').find(i => i.match('token'));
  let UserId = document.cookie.split(';').find(i => i.match('userid'));

  let clientConfig = Object.assign({}, {
    CreateInteraction: {
      Interaction: {
        AgentName: "User",
        InteractionType: "ENTERPRISE",
        InteractionStatus: "INPRGS",
        CacheId: iniState.CacheId,
        StartTime: moment().format(),
        ChannelName: "ENTERPRISE",
        ClientProfileId: iniState.ClientId,
        SourceIPAddress: ip.address(),
        ExternalSystemName: "ConsoleOne",
        InteractionLine: {
          InteractionLineReason: "STRTSR",
          Language: "en-US"
        }
      }
    }
  });
  let updateInteractionParam = { UpdateInteraction: { AccessToken: (iniState?.AccessTokenResponse?.access_token || accessTokenCookie), UserId:iniState?.AccessTokenResponse?.userId, IsEnterpriseRequest: IsSSOAuth,InteractionLineId : interactionLineId} }
  let initializeAndInteractionParam = {
    initializeParam : { InitializeApplication: { Client: "Samsung", Language: "en-US", LicenseKeys: license_keys? license_keys : ""} },
    interactionParam : clientConfig
  }
  let accessTokenParam = {
    GetAccessToken: {
    GrantType: 'authorization_code',
    Code: authCode,
    ClientId:  process.env.REACT_APP_CLIENTID,
    ClientSecret: process.env.REACT_APP_CLIENT_SECRET_KEY,
    RedirectUrl: process.env.REACT_APP_REDIRECT_URL,
    BaseUrl: tokenUrl
  }};
  
  let getEmailProps = {
    InteractionLineId : interactionLineId,
    Token : iniState?.AccessTokenResponse?.access_token || accessTokenCookie?.split('=')[1],
    ClientId:  process.env.REACT_APP_CLIENTID,
    BaseUrl: tokenUrl,
    UserId: iniState?.AccessTokenResponse?.userId || UserId?.split('=')[1]
  }
  useEffect(() => {
     if(!authCode){
      initialize(initializeAndInteractionParam); 
    }
  },[INVOKE_ONCE]);

  useEffect(() => {
   if (iniState?.AccessTokenResponse?.access_token || accessTokenCookie) {
      if(!accessTokenCookie) { document.cookie = `token=${iniState?.AccessTokenResponse?.access_token}`;
          document.cookie = `userid=${iniState?.AccessTokenResponse?.userId}`;}
      setInteractionLineId(interactionLineId)
      if(!isUserInfoCall){
        updateInteraction(updateInteractionParam)
      }
    }
  },[iniState?.AccessTokenResponse?.access_token])

  useEffect(() => {
    if (iniState.isInteractionSuccess && IsSSOAuth) {
      window.location.replace(`https://account.samsung.com/accounts/v1/${Alias}/signInGate?response_type=code&client_id=${ClientId}&redirect_uri=${RedirectUrl}&state=${`${iniState?.InteractionResponse?.InteractionLineId}+${!license_keys ? true : false}`}&goBackURL=${goBackURL}`);
    }
  }, [iniState.isInteractionSuccess]);

  useEffect(() => {
    if (authCode && !accessTokenCookie) {
      getAccessToken(accessTokenParam)
    }
  }, [authCode])

  return (
    <>
      {
        //license_keys || iniState?.FindAgreement?.LicenseKeys
          <div className="">
            {(iniState?.showProgressIndicator || srState?.showProgressIndicator) && <Loader />}
            < SingleSRSubmit />
            {(isUserInfoCall && INVOKE_ONCE) && <SSOGetEmailId getEmailProps={getEmailProps} updateInteractionParam={updateInteractionParam}/>}
          </div > /* : 
          //<NotFound />
          <SSOGetEmailId getEmailProps = {getEmailProps}/> */
      }
    </>
  )
}

export default SubmitServiceRequest