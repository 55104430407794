/* eslint-disable no-unused-vars */
const calculatePages = (records, pageSize) => (records % pageSize > 0) ? Math.trunc(records / pageSize) + 1 : records / pageSize;
const unsetProgressIndicator = (state, payload) => ({
  ...state,
  showProgressIndicator: false,
})

const setProgressIndicator = (state, payload) => ({
  ...state,
  showProgressIndicator: true,
})
const onCreateInquirySuccess = (state, payload) => ({
  ...state,
  CreateInquiryResponse : payload,
  isCreateInquirySuccess: true,
  showProgressIndicator: false,
})

const onCreateInquiryError = (state, payload) => ({
  ...state,
  Error: 'Error loading initialize',
  isInquiryError : true,
  showProgressIndicator: false,
})

export const reducers = {
  unsetProgressIndicator,
  setProgressIndicator,
  onCreateInquirySuccess,
  onCreateInquiryError
}

export const initialState = {
  showProgressIndicator: false,
  selectedPage: 0,
  selectedAgreement: []
}
