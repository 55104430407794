import React, { useEffect, useState } from 'react';
import Layout from '../../../components/header';
import PreviousServiceRequest from './previousservicerequest';
import Loader from '../../loader';
import { useManageMyAccount } from '../../../state-management/managemyaccount/ManageMyAccountContextProvider';
import arrowUp from '../../../assets/BiChevronUp.png';
import arrowDown from '../../../assets/BiChevronDown.png';


const ManageMyAccount = () => {
  const { state: srState } = useManageMyAccount();
  const [searchType, setSearchType] = useState('IMEI');
  const [searchData, setSearchData] = useState('');
  const [searchObj, setSearchObj] = useState({});
  const [open, setOpen] = useState(false);

  const _grdFields = {
    key: 'IMEI',
    showCheck: true,
    fields: [
      { name: 'IMEI', label: 'IMEI' },
      { name: 'DeviceModelName', label: 'Device Model Name' },
      { name: 'EnrollmentStart', label: 'Enrollment Start' },
      { name: 'EnrollmentEnd', label: 'Enrollment End' },
      { name: 'ProgramTerm', label: 'Program Term' },
      { name: 'Tier', label: 'Tier' },
      { name: 'Allowance', label: 'Program Allowance' },
      { name: 'SRCompleted', label: 'SR Complete' },
    ],
  }

  const [grdFields, setGrdFields] = useState(_grdFields);

  const search = (e) => {
    let searchitem = { type: searchType, data: searchData };
    setSearchObj(searchitem);
  }

  const onSearchDataChange = (e) => {
    setSearchData(e);
    if (e === '' || e.length <= 0) {
      setSearchType('IMEI');
      let searchitem = { type: '', data: '' };
      setSearchObj(searchitem);
    }
  }
  const onRowCheckChange = (data, index) => { }
  const onHeaderCheckChange = () => { }

  //useEffect(() => { setOpen(!open) }, [open]);

  return (
    <>
      {srState?.showProgressIndicator && <Loader />}
      <Layout label="Manage My Account">
        <div className="py-2">
          <div className="flex flex-row">
            <span className="mt-5 mb-2 text-center pr-2 text-bold h-10">Search By</span>
            <span className="mt-5 mb-2 flex-col pr-2 relative inline-block text-gray-900">
              <select defaultValue={searchType} value={searchType} name="searchSelect" className="w-60 h-7 pl-2 pr-1 text-base placeholder-gray-900 border rounded-lg appearance-none focus:shadow-outline" onChange={(e) => { setSearchData(''); setSearchType(e.target.value) }} onClick={() => setOpen(!open)}>
                <option value="IMEI">IMEI</option>
                {/* <option value="LICKey">License Key</option> */}
                <option value="SRNo">Swap Request No</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex content-center px-2 pointer-events-none">
                <img
                  alt=""
                  className="flex-column cursor-pointer w-6 h-6"
                  src={open ? arrowUp : arrowDown}
                />
              </div>
            </span>
            <span className="mt-5 mb-2 w-50 h-7 border-2 py-1 px-3 flex justify-between rounde-md rounded-md">
              <input type="text" className="outline-none text-gray-600 focus:text-blue-600" name="searchText" onChange={(e) => onSearchDataChange(e.target.value)} value={searchData} />
            </span>
            <span className="mt-5 mb-2 pl-2"><button className={`p-1 h-7 w-24 text-sm bg-white border rounded text-black border-black`}
              onClick={(e) => search()}>Search</button></span>
          </div>
          <div className="mt-5 mb-2 w-full border-none  flex justify-between rounde-md rounded-md">
            <p>Your Previous Swap Requests</p>
          </div>
          <PreviousServiceRequest searchData={searchObj} />
        </div>
      </Layout>
    </>
  )

}

export default ManageMyAccount