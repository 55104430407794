import API from '../api'

const getDeviceList = (data) => API.post('/api/store/getdevicelist', data)
const getBatchDetails = (data) => API.post('/api/store/getbatchdetails', data)
const contactPoint = (data) => API.post('api/v2/contactpoints', data)
const standardiseaddress = (data) => API.post('api/v1/standardiseaddress', data)
const getCityState = (data) => API.get(`/api/v3/citystate?ZipCode=${data.ZipCode}`)
const bulkServiceRequest = (data) => API.post('api/v1/bulksrcreation', data)
const getCustomerCaseGraph = (data) => API.get(`/api/customercasegraph/${data.clientAccountId}`)
const resumeServiceRequest = () => API.post('api/v2/resumeservicerequest')
const claimFlowApiRequest = (data) => API.post(`api/v1/enterpriseclaimapis/${data.FromRequest}`, data)
const determineIncident = (data) => API.post('api/v1/enterpriseclaimapis/CHECK_INPROGRESS_CLAIM', data)
const getAllSaveSubmitSrRequest = (data) => API.post(`/api/v1/getsavedsrs`, data);
const getDeviceIMEIDetailsRequest = (data) => API.get(`/api/v1/getdeviceimei/${data.IMEI}/samsung`)
const getPresignUrlRequest = (data) => API.post(`/api/v1/filesystemupload`, data);
const updateIMEIInquiryRequest = (data) => API.post(`/api/v1/updateimeiinquiry`, data);
const uploadFileToS3 = (data) => API.uploadFileToS3(data.url, data.blob, data.type)
const amtaCheckRequest = (data) => API.get(`/api/v1/amtacheck?imei=${data.IMEI}`)


const SubmitSRAPI = {
  claimFlowApiRequest,
  getDeviceList,
  getBatchDetails,
  contactPoint,
  standardiseaddress,
  getCityState,
  bulkServiceRequest,
  getCustomerCaseGraph,
  resumeServiceRequest,
  determineIncident,
  getAllSaveSubmitSrRequest,
  getDeviceIMEIDetailsRequest,
  getPresignUrlRequest,
  updateIMEIInquiryRequest,
  uploadFileToS3,
  amtaCheckRequest
}

export default SubmitSRAPI
