/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/rules-of-hooks */
import { useReducer } from 'react'

const getKeys = (...objects) => objects.reduce((agr, obj) => [...agr, ...Object.keys(obj)], [])

const actionCreator = (reducers, middleware) => (dispatch) => getKeys(reducers, middleware).reduce((agr, key) => {
  agr[key] = (data) => dispatch({ type: key, payload: data })
  return agr
}, {})

const identity = (data) => data

const createReducer = (reducers) => (state, { type, payload }) => (reducers[type] ?? identity)(state, payload)

const executeMiddleware = (middleware) => (dispatch) => (action) => middleware[action.type] && middleware[action.type](dispatch)(action.payload)

export const useSmartReducer = (initialState, reducers, middleware) => {
  const [state, dispatch] = useReducer(createReducer(reducers), initialState)

  const actions = actionCreator(reducers, middleware)

  const dispatchWithMiddleWare = (action) => {
    executeMiddleware(middleware)(actions(dispatchWithMiddleWare))(action)
    return dispatch(action)
  }

  return [state, actions(dispatchWithMiddleWare)]
}
