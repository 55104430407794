import { useState, useEffect } from 'react'
import tableFormatBulk from '../../tableformatbulk'
import { useHistory } from 'react-router-dom'
import Grid from '../../grid'
import Layout from '../../header';
import { useShared } from '../../../state-management/shared/SharedContextProvider'
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider'
import { filterAndSyncArrAndAppend, getEnrolledAsset, getRawloadProcessedCustomerCaseListId } from '../../../utils/helper'
import SubmitSRBulkPopUp from './submitsrbulk'
import Loader from '../../loader';
import CancelPopup from './confirmationpopup'
import AddressEditPopup from './addressbulkpopup';
import InWarrantyPopup from './inwarrantypopup';

const SubmitBulkConfirmation = () => {
    let history = useHistory()
    const [showPopup, setShowPopup] = useState(false);
    const [popUpData, setPopUpData] = useState({});
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const { state: iniState, setSelectedAgreementBulk, cleanSelectedAgreement } = useShared();
    const { state: srState, bulkRequestMethod, bulkRequestSubmit, bulkCancelRequestMethod, cleanPreviousResultsNext, cleanAddressDetails } = useSubmitSR();
    const [showAddPopup, setAddressShowPopUp] = useState(false);
    const [addressData, setAddressData] = useState({});
    const [allowanceData, setAllowanceData] = useState({});
    const [isOEMWarranty, setIsOEMWarranty] = useState(false);
    

    const _grdFields = {
        key: 'IMEI',
        showCheck: true,
        fields: [
            { name: 'IMEI', label: 'IMEI' },
            { name: 'DeviceModelName', label: 'Device Model Name' },
            { name: 'LicenseKey', label: 'License Key' },
            { name: 'IncidentType', label: 'What Happened to your device?', showSelect: true },
            { name: 'ShippingName', label: 'Shipping Name', showText: true },
            { name: 'ShippingAddress', label: 'Shipping Address', showText: true }
        ],
    }
    let FilteredData = iniState?.selectedAgreementBulk ? iniState?.selectedAgreementBulk.map((value, index) => {
        return {
            AgreementId: value.AgreementId,
            IMEI: getEnrolledAsset(value.Assets)?.IMEI,
            DeviceModelName: getEnrolledAsset(value.Assets)?.Model.Name,
            LicenseKey: value.LicenseKey,
            IncidentType: "",
            ShippingName: value?.Customers?.Customer[0]?.FullName || "",
            ShippingAddress: value?.Address?.Address1 + " " + value?.Address?.Address2 + " " + value?.Address?.Address3 + " " + value?.Address?.City + " " + value?.Address?.PostalCode + " " + value?.Address?.StateProvinceCode + " " + value?.Address?.CountryCode
        }
    }) :
        [{
            AgreementId: iniState.selectedAgreement?.AgreementId,
            IMEI: getEnrolledAsset(iniState.selectedAgreement?.Assets)?.IMEI,
            DeviceModelName: getEnrolledAsset(iniState.selectedAgreement?.Assets)?.Model.Name,
            LicenseKey: iniState.selectedAgreement?.LicenseKey,
            IncidentType: "",
            ShippingName: "",
            ShippingAddress: ""
        }];

    const [grdFields, setGrdFields] = useState(_grdFields);
    const [deviceList, setDeviceList] = useState(FilteredData);

    let combineAgreementAndSavedSR = [];
    iniState.selectedAgreement && iniState.selectedAgreement.length && iniState.selectedAgreement.forEach(val1 => {
        let index = (iniState.selectedSavedSR && iniState.selectedSavedSR.length) ? iniState.selectedSavedSR.findIndex(val2 => val2.AgreementId === val1.AgreementId) : -1;
        if (index != undefined && index != -1) {
            val1.Address.Address1 = iniState?.selectedSavedSR[index]?.Address1 || "";
            val1.Address.Address2 = iniState?.selectedSavedSR[index]?.Address2 || "";
            val1.Address.Address3 = iniState?.selectedSavedSR[index]?.Address3 || "";
            val1.Address.City = iniState?.selectedSavedSR[index]?.City || "";
            val1.Address.CountryCode = iniState?.selectedSavedSR[index]?.Country || "";
            val1.Address.PostalCode = iniState?.selectedSavedSR[index]?.PostalCode || "";
            val1.Address.StateProvinceCode = iniState?.selectedSavedSR[index]?.State || "";
            val1.Customers.Customer[0].FullName = iniState?.selectedSavedSR[index]?.ReceiverName || "";
            val1.savedDetails = {};
            val1.savedDetails.RawloadProcessedCustomerCaseListId = iniState?.selectedSavedSR[index]?.RawloadProcessedCustomerCaseListId;
            val1.savedDetails.IncidentType = iniState?.selectedSavedSR[index]?.IncidentType;
            val1.savedDetails.IsProcessed = iniState?.selectedSavedSR[index]?.IsProcessed;
            val1.savedDetails.AgreementId = iniState?.selectedSavedSR[index]?.AgreementId;
        }
        let cloneObject = JSON.parse(JSON.stringify(val1));
        combineAgreementAndSavedSR.push(cloneObject)
    })
    useEffect(() => {
        if (iniState.selectedAgreementBulk && iniState.selectedAgreementBulk.length) {
            FilteredData = iniState.selectedAgreementBulk ? iniState.selectedAgreementBulk.map((value, index) => {
                return {
                    AgreementId: value.AgreementId,
                    IMEI: getEnrolledAsset(value.Assets)?.IMEI,
                    DeviceModelName: getEnrolledAsset(value.Assets)?.Model.Name,
                    LicenseKey: value.LicenseKey,
                    IncidentType: value?.savedDetails?.IncidentType || "",
                    ShippingName: value?.Customers?.Customer[0]?.FullName || "",
                    ShippingAddress: value?.Address?.Address1 + " " + value?.Address?.Address2 + " " + value?.Address?.Address3 + " " + value?.Address?.City + " " + value?.Address?.PostalCode + " " + value?.Address?.StateProvinceCode + " " + value?.Address?.CountryCode
                }
            }) :
                [{
                    AgreementId: iniState.selectedAgreement?.AgreementId,
                    IMEI: getEnrolledAsset(iniState.selectedAgreement?.Assets)?.IMEI,
                    DeviceModelName: getEnrolledAsset(iniState.selectedAgreement?.Assets)?.Model.Name,
                    LicenseKey: iniState.selectedAgreement?.LicenseKey,
                    IncidentType: "",
                    ShippingName: "",
                    ShippingAddress: ""
                }];
            setDeviceList(FilteredData);
        }
    }, [iniState.selectedAgreementBulk])

    useEffect(() => {
        setSelectedAgreementBulk(combineAgreementAndSavedSR);
    }, [])

    const onRowCheckChange = (data, index, param = 'checkbox') => {
        if (param === 'checkbox') {
            const newData = { ...data, isChecked: !data.isChecked }
            let _deviceList = deviceList || []
            _deviceList = _deviceList.map((row, i) => (i === index ? {...newData, currentSelection : true} : {...row, currentSelection : false}))
            //let getCheckedData = _deviceList.filter((row, i) => (row.isChecked))

            if (!newData.isChecked) {
                grdFields.isChecked = false
                setGrdFields(grdFields)
            }

            setDeviceList(_deviceList)
        } else {
            const newData = { ...data }
            let _deviceList = deviceList || []
            _deviceList = _deviceList.map((row, i) => (i === index ? {...newData, currentSelection : true} : {...row, currentSelection : false}))
            setGrdFields(grdFields)
            setDeviceList(_deviceList)
        }
    }

    const showAddressPopup = (field, data) => {
        if (field === "ShippingAddress") {
            setAddressShowPopUp(true);
            let selectedRowToEditAddress = iniState.selectedAgreementBulk.filter(r => r.AgreementId === data.AgreementId);
            setAddressData(selectedRowToEditAddress[0]);
        }
    }
    const saveAndSubmit = (processValue, ClaimStatus = "", action) => {
        let addressToEditBulk = iniState?.addressToEditBulk;
        let bulkDeviceList = deviceList.map((element) => {
            if (addressToEditBulk && addressToEditBulk.AgreementId && (element.AgreementId === addressToEditBulk.AgreementId)) {
                element.Address = addressToEditBulk.Address;
            }
            return element
        })
        let filterInAgreementList = [];
        if (bulkDeviceList.length > 0 && !isOEMWarranty) {
            filterInAgreementList = filterAndSyncArrAndAppend(iniState.selectedAgreementBulk, bulkDeviceList);
        }
        else {
            filterInAgreementList = filterAndSyncArrAndAppend(iniState.selectedAgreementBulk, bulkDeviceList?.filter(i => i.isChecked));
        }
        const inputData = filterInAgreementList && filterInAgreementList.length && filterInAgreementList.map((val, i) => {
            let returnObj = {
                "AgreementId": val?.AgreementId || "",
                "AssetId": getEnrolledAsset(val?.Assets)?.AssetId || "",
                "LicenseKey": iniState?.LicenseKey || "",
                "AssetCatalogId": getEnrolledAsset(val?.Assets)?.AssetCatalog?.AssetCatalogId || "",
                "ClientAccountId": val?.ClientAccount?.ClientAccountId || "",
                "Imei": getEnrolledAsset(val?.Assets)?.IMEI || "",
                "Address1": val?.Address?.Address1 || "",
                "Address2": val?.Address?.Address2 || "",
                "Address3": val?.Address?.Address3 || "",
                "City": val?.Address?.City || "",
                "Suburb": "",
                "State": val?.Address?.StateProvinceCode || "",
                "Postal": val?.Address?.PostalCode || "",
                "Country": val?.Address?.CountryCode || "",
                //"ClientId": val?.ClientAccount?.ClientAccountId || "",
                //"ClientChannelId": val?.ClientAccount?.ClientChannelId || "",
                "RawloadProcessedCustomerCaseListId": getRawloadProcessedCustomerCaseListId(srState?.bulkSRdetails, val),
                "IsProcessed": processValue,
                "ReceiverName": val?.ReceiverName || "",
                "IncidentType": val?.IncidentType || "Catastrophic Damage",
                PhoneNumber: val?.ContactPoints?.ContactPoint.filter(i => i.ContactPointType == 'MOBILE')[0]?.PhoneNumber
            }
            if (action == 'cancel') {
                returnObj.ClaimStatus = ClaimStatus
            }
            return returnObj;
        })

        const data = {
            "ListOfSRsParameter": inputData
        }
        return data;

    }
    const onSave = () => {
        const requestData = saveAndSubmit(3, "", "save");
        bulkRequestMethod(requestData);
    }
    const getPopUpData = () => {
        const requestData = saveAndSubmit(0, "", "submit");
        bulkRequestSubmit(requestData)
    }

    const onSubmit = () => {
        setPopUpData(deviceList)
        filterAndSetAllowanceData(deviceList);
        setShowPopup(prevState => !prevState);
    }

    const filterAndSetAllowanceData = (list) => {
        let selectedAgreement = iniState.FindAgreement?.Agreements?.Agreement.filter((data, i) => data.AgreementId === list[0].AgreementId);
        let allowanceData = {
            Used: selectedAgreement[0]?.TotalAllownce - (selectedAgreement[0]?.SwapAllowanceRemaining - list.length),
            Remaining: selectedAgreement[0]?.SwapAllowanceRemaining - list.length
        }
        setAllowanceData(allowanceData);
    }

    const onClosePopup = () => {
        setShowPopup(prevState => !prevState);
    }

    const onCloseAddressPopup = () => {
        setAddressShowPopUp(false);
        cleanAddressDetails();
    }

    useEffect(() => {
        if (srState.cancelBulkData !== undefined) {
            setShowCancelPopup(false);
            let unSelectedDeviceList = deviceList.filter((row, i) => row.isChecked !== true);
            let newDeviceList = deviceList.map((element) => {
                delete element.isChecked;
                return element
            })
            setDeviceList(newDeviceList);
            unSelectedDeviceList = unSelectedDeviceList.map((element) => {
                delete element.isChecked;
                return element
            })
            //setDisable(true)
            setDeviceList(unSelectedDeviceList);
            if (unSelectedDeviceList.length <= 0) {
                onBackHandler();
            }
        }
    }, [srState.cancelBulkData])

    useEffect(() => {
        if (iniState?.addressToEditBulk !== undefined) {
            let newDeviceList = deviceList.map(element => {
                if (element.AgreementId === iniState?.addressToEditBulk?.AgreementId) {
                    element.ShippingAddress = iniState?.addressToEditBulk?.Address?.Address1 + " " + iniState?.addressToEditBulk?.Address?.Address2 + " " + iniState?.addressToEditBulk?.Address?.Address3 + " " + iniState?.addressToEditBulk?.Address?.City + " " + iniState?.addressToEditBulk?.Address?.PostalCode + " " + iniState?.addressToEditBulk?.Address?.StateProvinceCode + " " + iniState?.addressToEditBulk?.Address?.CountryCode
                }
                return element
            })
            setDeviceList(newDeviceList);
            FilteredData = iniState?.selectedAgreementBulk.map((element) => {
                if (element.AgreementId === iniState.addressToEditBulk.AgreementId) {
                    element.Address = iniState.addressToEditBulk.Address;
                }
                return element
            })
        }
    }, [iniState?.addressToEditBulk])

    useEffect(() => {
        let changedIncident = deviceList?.filter(i => i.isChecked && i.currentSelection)[0]?.IncidentType;
        if (changedIncident) {
            if (changedIncident === `Swap`) {
                setIsOEMWarranty(true)
            }
        }
    }, [deviceList]);

    const onCancel = () => {
        setShowCancelPopup(true);
    }

    const onCloseWarrantyPopup = () => {
        setIsOEMWarranty(false);
    }

    const onNo = () => {
        setShowCancelPopup(false);
    }

    const onYes = () => {
        const requestData = saveAndSubmit(3, "Cancelled", "cancel");
        bulkCancelRequestMethod(requestData);
        !isOEMWarranty && cleanSelectedAgreement();
        !isOEMWarranty && onBackHandler();
        isOEMWarranty && setIsOEMWarranty(false);
    }

    const onBackHandler = () => {
        cleanPreviousResultsNext();
        history.replace('/submit-service-request')

    }

    return (
        <>
            {srState?.showProgressIndicator && <Loader />}
            <Layout label="Submit Swap Request">
                {showCancelPopup && <CancelPopup msg={"Are you sure you want to cancel?"} open={showCancelPopup} onYes={onYes} onNo={onNo} onClick={onNo} />}
                {showPopup && <SubmitSRBulkPopUp open={showPopup} onClose={onClosePopup} data={popUpData} getPopUpData={getPopUpData} allowanceData={allowanceData} />}
                {showAddPopup && <AddressEditPopup open={showAddPopup} onClick={onCloseAddressPopup} data={addressData} />}
                {isOEMWarranty && <InWarrantyPopup open={isOEMWarranty} onClick={onCloseWarrantyPopup} data={deviceList?.filter(i => i.isChecked)[0]} onYes={onYes}/>}
                <div className="py-2 mt-4">
                    {deviceList && deviceList.length > 0 && <div className="py-2 overflow-x-auto">
                        <Grid
                            className="text-sm font-normal w-full border border-black border-collapse"
                            onRowCheckChange={onRowCheckChange}
                            showAddressPopup={showAddressPopup}
                            //onHeaderCheckChange={onHeaderCheckChange}
                            list={deviceList || []}
                            headerRow={tableFormatBulk.headerRow(grdFields)}
                            dataRow={tableFormatBulk.dataRow(grdFields)}
                        />
                    </div>}
                    <div className="flex flex-row py-6">
                        <div className="flex-1">
                            <button className={"left-px p-1 w-24 mr-6 text-sm bg-white border rounded text-black border-black"} onClick={onBackHandler}>BACK</button>
                        </div>
                        <div className="flex items-end justify-end">
                            <button className={`${'text-black border-black'} p-1 w-24 mr-2 focus:bg-blue-100 text-sm bg-white border rounded`} onClick={onSave}>SAVE</button>
                            <button className={`${'text-black border-black'} p-1 w-24 mr-2 focus:bg-blue-100 text-sm bg-white border rounded`} onClick={onCancel}>CANCEL</button>
                            <button className={`${'text-black border-black'} p-1 w-24 text-sm bg-white border focus:bg-blue-100 rounded`} onClick={onSubmit}>SUBMIT</button>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default SubmitBulkConfirmation;