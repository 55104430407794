import React, { useEffect, useState } from 'react';
import arrowUp from '../../../assets/BiChevronUp.png';
import arrowDown from '../../../assets/BiChevronDown.png';
import faq_json from './faq.json'

function Faq() {
    const [open, setOpen] = useState('');

    const Renderfaq = (props) => {
        return (
            <div
                className="mt-30 text-left bg-white">
                <div className="flex justify-between" onClick={() => { open == props.data.id ? props.setOpen('') : props.setOpen(props.data.id) }}>
                    <p className="flex-column font-SamsungOneRegular font-xs text-justify leading-snug sm:text-16 sm:leading-19 pl-1 pr-1 text-dark-gray inline-block">
                        {props.data.question}
                    </p>
                    <img
                        alt=""
                        className="flex-column content-center cursor-pointer w-6 h-6"
                        src={open == props.data.id ? arrowUp : arrowDown}
                    />
                </div>
                <hr className="shadow" />
                {open == props.data.id ? <> <div
                    dangerouslySetInnerHTML={{ __html: props.data.answer }}
                    className="bg-gray-100 font-AvenirNextRegular text-left text-justify text-18 leading-none sm:text-16 sm:leading-20 text-dark-gray p-2">
                </div><hr className="shadow" /></> : ''}
            </div>
        )
    };

    return (
        <div className="col-span-2 border-solid border-2 border-light-blue-500 overflow-y-auto">
            <div className="">
                {faq_json.data.map((question, index) =>
                    <Renderfaq data={question} key={index} open={open} setOpen={setOpen} />)}
            </div>
        </div>
    )
}
export default Faq;