
/* eslint-disable no-unused-vars */
const setUpdatedResumeAfterDelete = (state, payload) => ({
  ...state,
  GetResumeServiceRequest: {results : payload},
  showProgressIndicator: false,
})

const setProgressIndicator = (state, payload) => ({
  ...state,
  showProgressIndicator: true,
})


const claimFlowApiRequestError = (state, payload) => ({
  ...state,
  isError: true,
  showProgressIndicator: false
})


const claimFlowApiRequestCancelSuccess = (state, payload) => ({
  ...state,
  claimFlowApiResponsesForCancel: payload,
  isError: false,
  showProgressIndicator: false
})

// Resume SR flow
const onResumeSRSuccess = (state, payload) => ({
  ...state,
  GetResumeServiceRequest: payload,
  isAgreementSuccess: true,
  showProgressIndicator: false,
})
 
const onResumeSRError = (state, payload) => ({
  ...state,
  Error: 'Error loading agreement',
  showProgressIndicator: false,
})

const claimFlowApiRequestResumeSuccess = (state, payload) => ({
  ...state,
  SingleResumeSRResponse: payload,
  isError: false,
  showProgressIndicator: false
})

const isReusmeSR = (state, payload) => ({
  ...state,
  isReusmeSR: payload,
})

const setResumeAgreement = (state, payload) => ({
  ...state,
  selectedSR: payload
});

export const reducers = {
  setProgressIndicator,
  claimFlowApiRequestError,
  claimFlowApiRequestCancelSuccess,
  onResumeSRSuccess,
  onResumeSRError,
  isReusmeSR,
  setResumeAgreement,
  setUpdatedResumeAfterDelete,
  claimFlowApiRequestResumeSuccess
}


export const initialState = {
  showProgressIndicator: false,
  isError: false,
}
