/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import ManageMyAccountApi from './api';

const getAllCustomerCaseGraph = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return ManageMyAccountApi.manageAccountDetails(data)
    .then((data) => {
      actions.onAllGetCustomerCaseGraphSuccess(data?.data?.AccountDetails)
    })
    .catch((error) => actions.onAllGetCustomerCaseGraphError(error?.response?.data ?? error))
}

const reprintLabel = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return ManageMyAccountApi.reprintLabel(data)
    .then((data) => {
      actions.onreprintLabelSuccess(data?.data)
    })
    .catch((error) => actions.onreprintLabelError(error?.response?.data ?? error))
}

export const middleware = {
  getAllCustomerCaseGraph,
  reprintLabel
}
