/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import FAQAPI from './api';

const createInquiry = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return FAQAPI.createInquiry(data)
    .then((data) => {
      actions.onCreateInquirySuccess(data?.data)
    })
    .catch((error) => {
      actions.onCreateInquiryError(error?.response?.data ?? error)
    })
}

export const middleware = {
  createInquiry
}
