import moment from 'moment';
import { getEnrolledAsset, getReplacedAsset } from '../../../utils/helper';

const SubmitSRDeviceDetails = (props) => {
    const { data, _isPreviousSwapped } = props;
    let replacedDevice = _isPreviousSwapped && _isPreviousSwapped[0]?.ServiceRequests.map(i => ({
        PreviousIMEI: i?.ReplacedDevice?.IMEI || i?.EnrolledDevice?.IMEI,
        PreviousDesc: `Swap ${_isPreviousSwapped[0]?.CreatedDate && moment(_isPreviousSwapped[0]?.CreatedDate).format('DD MMM YYYY')}`
    }))

    let allReplaced = data?.Assets.Asset.filter((item) => item.AssetInstance === 'REPLACED');
    let prevImei;
    if (allReplaced.length <= 1) {
        prevImei = getEnrolledAsset(data?.Assets).IMEI;
    } else if (allReplaced.length > 1) {
        prevImei = allReplaced[allReplaced.length - 2]?.IMEI;
    } else {
        prevImei = getEnrolledAsset(data?.Assets).IMEI;
    }


    return (
        <>
            <div className="left-px text-sm font-normal w-full py-2 text-black">DEVICE DETAILS</div>
            <hr className="shadow" />
            <div className="text-sm font-normal w-full py-1 text-black">Summary</div>
            <hr className="shadow" />
            <div className="p-2">
                <div className="flex flex-row justify-start">
                    <div className="text-sm flex-column font-bold pr-6">
                        <p className="pb-2">Device Status</p>
                    </div>
                    <div className="text-sm flex-column">
                        <p className="pb-2">{'Active'}</p>
                    </div>
                </div>
                <div className="flex flex-row justify-start">
                    <div className="text-sm flex-column font-bold pr-20">
                        <p className="pb-2">IMEI</p>
                    </div>
                    <div className="text-sm flex-column">
                        <p className="pb-2">{getReplacedAsset(data.Assets)?.IMEI || getEnrolledAsset(data?.Assets)?.IMEI}</p>
                    </div>
                </div>
                <div className="flex flex-row justify-start">
                    <div className="text-sm flex-column font-bold pr-16">
                        <p className="pb-2">Model</p>
                    </div>
                    <div className="text-sm flex-column">
                        <p className="pb-2">{replacedDevice && replacedDevice[0].AssetCatalog?.Description || data?.Assets?.Asset.filter(i => i.AssetInstance === 'ENROLLED')[0]?.AssetCatalog.AssetCatalogName}</p>
                    </div>
                </div>
                <div className="flex flex-row justify-start">
                    <div className="text-sm flex-column font-bold pr-10">
                        <p className="pb-2">Start Date</p>
                    </div>
                    <div className="text-sm flex-column">
                        <p className="pb-2">{data?.StartDate && moment(data?.StartDate).format('DD MMM YYYY')}</p>
                    </div>
                </div>
                <div className="flex flex-row justify-start">
                    <div className="text-sm flex-column font-bold pr-12">
                        <p className="pb-2">End Date</p>
                    </div>
                    <div className="text-sm flex-column">
                        <p className="pb-2">{data?.EndDate && moment(data?.EndDate).format('DD MMM YYYY')}</p>
                    </div>
                </div>
            </div>
            <p className="p-1 leading-6">
                Please confirm the above details match the device you are submitting a Swap Request for before pressing VERIFY MY DEVICE.
            </p>
            <div className="flex flex-row m-3 justify-between">
                <div className="flex-col text-sm font-bold">
                    Previous IMEI
                </div>
                <div className="flex-col text-sm pl-8">
                    {prevImei}<br />
                    {replacedDevice && replacedDevice[0]?.PreviousDesc}
                </div>
            </div>
            <div className="mt-6 mb-2 flex flex-row justify-between">
                <button className={`p-1 w-32 h-8 text-xs bg-white border focus:bg-blue-100 rounded text-black border-black`} onClick={props.onVerifyMyDevice}> VERIFY MY DEVICE</button>
            </div>
        </>
    )

}

export default SubmitSRDeviceDetails;