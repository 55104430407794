export function filterAndSyncArr(arr1, arr2) {
    const ouputArr = [];
    arr2.forEach(element2 => {
        ouputArr.push(arr1.filter((element1) => element2.AgreementId === element1.AgreementId)[0])
    });
    return ouputArr
}

export function filterAndSyncArrAndAppend(arr1, arr2) {
    const ouputArr = [];
    arr2.forEach(element2 => {
        let getArrElement = JSON.parse(JSON.stringify(arr1.filter((element1) => element2.AgreementId === element1.AgreementId)[0]));
        getArrElement.IncidentType = element2.IncidentType;
        getArrElement.ReceiverName = element2.ShippingName;
        if (element2?.Address) {
            getArrElement.Address = element2.Address;
        }
        ouputArr.push(getArrElement)
    });
    return ouputArr
}

export function getEnrolledAsset(assetObj) {
    return assetObj?.Asset.filter((item) => item.AssetInstance === 'ENROLLED')[0]  
}

export function getReplacedAsset(assetObj) {
    return assetObj?.Asset.filter((item) => item.AssetInstance === 'REPLACED')[0]  
}

export function validateForm(type, value, required) {
    if (type === 'text') {
        if (value.length == 0 || value == "")
            return true
    }
    return false
}
export function IsRequired(required, data, fieldName) {
    if(required && (data.trim() == '' || data == null || data == undefined)){
        return {error : true, fieldName : fieldName}
    }
    else {
        return false;
    } 
}

export function getRawloadProcessedCustomerCaseListId(allSrDetails, savedSr) {
    let rawloadProcessedCustomerCaseListId = "";
    if (allSrDetails && allSrDetails.length) {
        rawloadProcessedCustomerCaseListId = allSrDetails.filter(element => element.AgreementId === savedSr.AgreementId)[0]?.RawloadProcessedCustomerCaseListId;
        if (rawloadProcessedCustomerCaseListId != undefined && rawloadProcessedCustomerCaseListId != "") {
            return rawloadProcessedCustomerCaseListId;
        } else {
            rawloadProcessedCustomerCaseListId = savedSr?.savedDetails?.RawloadProcessedCustomerCaseListId;
            return rawloadProcessedCustomerCaseListId;
        }
    } else {
        rawloadProcessedCustomerCaseListId = savedSr?.savedDetails?.RawloadProcessedCustomerCaseListId;
        return rawloadProcessedCustomerCaseListId;
    }
}