import API from '../api'

const reprintLabel = (data) => API.post(`api/v1/reprintlabel?servicerequestid=${data.srID}`, data.data)
const manageAccountDetails = (data) => API.post(`/api/v1/manageaccountdetail`,data)


const ManageMyAccountApi = {
    manageAccountDetails,
    reprintLabel
}

export default ManageMyAccountApi
