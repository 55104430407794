const Grid = ({ headerRow, dataRow, onRowCheckChange, onHeaderCheckChange, onSort, list, className, pagination, showAddressPopup, reprintPDF }) => {
  return (
    <table className={className}>
      <thead>{headerRow(onHeaderCheckChange, onSort)}</thead>
      {list ? (<tbody>{list.map((data, index) => dataRow(data, index, onRowCheckChange, reprintPDF, showAddressPopup))}</tbody>) :
        (<div>
          No Records Found
        </div>)}
    </table>
  )
}

export default Grid
