import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useShared } from '../../../state-management/shared/SharedContextProvider';
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider';
import Notification from '../../../utils/notification';
import { useForm } from 'react-hook-form';
import Loader from '../../loader';

export default (props) => {
    const { open, onClick, data } = props;
    const { state: iniState, onUpdateAddressBulk } = useShared();
    const { state: srState, standardiseaddress, getCityState } = useSubmitSR();

    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [showCityCountrySelect, setShowCityCountrySelect] = useState(false);
    const [showCityCountryText, setShowCityCountryText] = useState(true);
    const [selectedCity, setSelectedCity] = useState('');
    const [bulkAddressValid, IsBulkAddressValid] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            AddLine1: data?.Address?.Address1,
            AddLine2: data?.Address?.Address2,
            AddLine3: data?.Address?.Address3,
            Country: data?.Address?.CountryCode,
            City: data?.Address?.City,
            PostCode: data?.Address?.PostalCode
        }
    })

    const contentStyleForDesktopView = {
        padding: '0px',
        alignSelf: 'center',
        width: '30%',
    }
    const overlayStyle = { background: 'rgba(0,0,0,0.5)' };

    useEffect(() => {
        if (srState?.getCityState?.GetCityStatebyZipCodeResponse) {
            if (srState?.getCityState?.GetCityStatebyZipCodeResponse.length == 1) {
                setCity(srState?.getCityState?.GetCityStatebyZipCodeResponse[0].City)
                setCountry(srState?.getCityState?.GetCityStatebyZipCodeResponse[0].Country)

                setValue("City",srState?.getCityState?.GetCityStatebyZipCodeResponse[0].City);
                setValue("Country",srState?.getCityState?.GetCityStatebyZipCodeResponse[0].Country);
                setShowCityCountryText(true);
                setShowCityCountrySelect(false);
            } else {
                let city = srState?.getCityState?.GetCityStatebyZipCodeResponse.map(i => i.City)
                setCity(city)
                setSelectedCity(city[0])
                let country = srState?.getCityState?.GetCityStatebyZipCodeResponse.map(i => i.Country)
                setCountry(country[0])
                setValue('Country', country[0]);
                setShowCityCountrySelect(true);
                setShowCityCountryText(false);
            }
        }
    }, [srState.getCityState])

    let validatePOAddress = (address) => {
        let isInvalid = false;
        let keywords = 'p.o.|po|post|army post|army po|post office|pobox|po box|diplomaticpo|postal|fleetpo|fleet po|armypo|post.off.|postoff|postoffice|postofficebox|correos|oficina|apo|fpo|dpo|po|pob|a.p.o.|f.p.o.|f.p.o|d.p.o.|apartado|aptdo|buzon|callbox|caller|gpobox|poboxs-1190|correos|caja postal|casilla postal|p.o.box|armypostoffice|fleetpostoffice|armyp.o.|fleetp.o.|diplomaticpostoffice|diplomatic.p.o.|private bag|Police Box|lockbag|po box 379';
        let poAddress = `${address?.Address1},${address?.Address2},${address?.Address3},${address?.City}`;
        let addressArray = poAddress.split(',');
        keywords = keywords.split('|');
        keywords.map((key) => {
            addressArray.map((poAddress) => {
                if (poAddress.toLowerCase() == key.toLowerCase()) {
                    isInvalid = true
                }
            })
        })

        if (address?.Address1 === '17 post office place' || address?.Address1 === '17 post office pl' || address?.Address2 === '17 post office place' ||
            address?.Address2 === '17 post office pl' || address?.Address3 === '17 post office place' ||
            address?.Address3 === '17 post office pl' || address?.City === 'Victoria' || address?.PostalCode === '3844') {
            isInvalid = true
        }
        return isInvalid
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === "PostCode") {
                if (value.PostCode.length >= 4) {
                    let params = Object.assign({}, { ZipCode: value.PostCode })
                    getCityState({ ...params })
                }
            }
        })
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSave = () => {
        IsBulkAddressValid(false)
        let address = {
            Address1: getValues("AddLine1"),
            Address2: getValues("AddLine2"),
            Address3: getValues("AddLine3"), 
            City:  getValues("City"),
            StateProvinceCode: "",
            CountryCode: getValues("Country"),
            PostalCode: getValues("PostCode")
        }
        let selectedRowToEditAddress = iniState.selectedAgreement.filter(r => r.AgreementId === data.AgreementId);
        selectedRowToEditAddress = { ...selectedRowToEditAddress[0], Address: { ...selectedRowToEditAddress[0].Address, ...address } }
        let poBoxValidation = validatePOAddress(address);

        if (!poBoxValidation) {
            let selectedRowToEditAddress = iniState.selectedAgreement.filter(r => r.AgreementId === data.AgreementId);
            selectedRowToEditAddress = { ...selectedRowToEditAddress[0], Address: { ...selectedRowToEditAddress[0].Address, ...address } }
            //onUpdateAddressBulk(selectedRowToEditAddress);
            let QASParams = {
                Address: {
                    PostalCode: selectedRowToEditAddress?.Address?.PostalCode,
                    Address1: selectedRowToEditAddress?.Address?.Address1,
                    Address2: selectedRowToEditAddress?.Address?.Address2,
                    Address3: selectedRowToEditAddress?.Address?.Address3
                },
                isBulk: true
            };
            IsBulkAddressValid(false)
            standardiseaddress({ QASParams: { ...QASParams } });
        } else {
            IsBulkAddressValid(poBoxValidation)
        }
    }

    useEffect(() => {
        setShowCityCountrySelect(false);
        setShowCityCountryText(true);
        if (srState?.addressBulkDetails?.AddressResult?.Score == 100) {
            IsBulkAddressValid(false)
            /* setValue("AddLine1", srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.AddressLine1);
            setValue("AddLine2", srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.AddressLine2);
            setValue("AddLine3", srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.AddressLine3);
            setValue("City", srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.CityName);
            setValue("Country", srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.CountryCode);
            setValue("PostCode", srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.PostalCode);
            setCity(srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.CityName)
            onClick(); */
            let address = {
                Address1: srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.AddressLine1,
                Address2: srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.AddressLine2,
                Address3: srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.AddressLine3, 
                City:  srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.CityName,
                StateProvinceCode: srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.StateProvinceCode,
                CountryCode: srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.CountryCode,
                PostalCode: srState?.addressBulkDetails?.AddressResult?.CorrectedAddress?.PostalCode
            }
            let selectedRowToEditAddress = iniState.selectedAgreement.filter(r => r.AgreementId === data.AgreementId);
            selectedRowToEditAddress = { ...selectedRowToEditAddress[0], Address: { ...selectedRowToEditAddress[0].Address, ...address } }
            onUpdateAddressBulk(selectedRowToEditAddress);
            onClick();
        }
        else if (srState?.addressBulkDetails?.PickListAddress?.Score == "0") {
            IsBulkAddressValid(true)
            onUpdateAddressBulk(undefined);
        }
    }, [srState.addressBulkDetails]);

    return (
        <Popup
            open={open}
            contentStyle={contentStyleForDesktopView}
            overlayStyle={overlayStyle}
            closeOnDocumentClick={false}
            modal
            nested
        >
            {(iniState?.showProgressIndicator || srState?.showProgressIndicator) && <Loader isModalPopUp = 'isModalPopUp' />}
            <div className="border bg-gray-100 w-auto p-4 bg-white rounded-md shadow-md dark:bg-gray-800">
                <button className="absolute right-px whitespace-normal h-6 w-6 mx-4 text-sm bg-white border rounded text-black" onClick={onClick}>
                    &times;
                </button>
                <div className="text-center font-bold">EDIT ADDRESS</div>
                <form onSubmit={handleSubmit(onSave)}>
                    <div className="">
                        <div className="py-2">
                            <label htmlFor="addline1">Address line 1</label>
                            <input type="text" id="addline1" name="addline1" className="border rounded w-full border-solid pl-2 pr-2"
                                {...register("AddLine1", { required: true })}
                            />
                            {errors?.AddLine1?.type === "required" && <p className="text-red-400 text-sm">Address line 1 is required</p>}
                        </div>
                        <div className="py-2">
                            <label htmlFor="addline2">Address line 2</label>
                            <input type="text" id="addline2" name="addline2" className="border rounded w-full border-solid pl-2 pr-2"
                                {...register("AddLine2")}
                            />
                        </div>
                        <div className="py-2">
                            <label htmlFor="addline3">Address line 3</label>
                            <input type="text" id="addline3" name="addline3" className="border rounded w-full border-solid"
                                {...register("AddLine3")}
                            />
                        </div>
                        <div className="grid grid-cols-2 grid-flow-rows gap-2 py-2">
                            <div>
                                <label htmlFor="city">City/Suburb</label>
                                <br />
                                {showCityCountryText &&
                                    <input type="text" id="city" name="city" className="border rounded border-solid w-full"
                                        {...register("City")} disabled />}
                                {showCityCountrySelect && <select className="border rounded border-solid w-full" value={selectedCity} onChange={(e) => { setSelectedCity(e.target.value) }}>{
                                    city.map((x, y) =>
                                        <option key={y} value={x}>{x}</option>)
                                }</select>}
                            </div>
                            <div>
                                <label htmlFor="postcode">Postcode</label>
                                <br />
                                <input type="text" id="postcode" name="postcode" className="border rounded w-full border-solid pl-2 pr-2"
                                    {...register("PostCode", { required: true, pattern: /^[0-9]+$/i, maxLength: 4 })}
                                />
                                {errors?.PostCode?.type === "required" && <p className="text-red-400 text-sm">PostCode is required</p>}
                                {errors?.PostCode?.type === "pattern" && (
                                    <p className="text-red-400 text-sm">Postcode should only contain numbers</p>
                                )}
                                {errors?.PostCode?.type === "maxLength" && (
                                    <p className="text-red-400 text-sm">Postcode should be at most 4</p>
                                )}
                            </div>
                        </div>
                        <div className="py-2">
                            <label htmlFor="country">Country</label>
                            <br />
                            <input type="text" id="country" name="country" className="border rounded w-25 border-solid pl-2 pr-2"
                                {...register("Country")}
                                disabled /><br />
                        </div>
                    </div>
                    <div className="px-12 py-4 text-center">
                        <button className={`p-1 h-8 w-24 text-sm bg-white border rounded text-black border-black`} type="submit" >
                            SAVE</button>
                    </div>
                </form>
                {
                    bulkAddressValid && <Notification type="error" msg='Please enter valid address' />
                }
            </div>
        </Popup >)
};