
const SubmitSRIncident = (props) => {
    const { onChange, type, selectionFlag } = props;
    return (
        <div className="grid grid-rows-2 grid-flow-cols gap-2">
            <div className="border-2 border-solid px-2">
                <div className="left-px text-sm font-normal w-full py-2 text-black">WHAT HAPPENED TO MY DEVICE?</div>
                <hr className="shadow" />
                <div className={!selectionFlag ? "p-4 bg-purple-50" : "p-4 bg-purple-50 opacity-50"} >
                    <div className={type === "My device isn't functioning as it should" ? 'bg-blue-100 pt-3' : `pt-3`}>
                        <input type="radio"
                            id="Functional_Problem"
                            name="problem"
                            value="My device isn't functioning as it should"
                            checked={type === "My device isn't functioning as it should"}
                            onChange={(e) => onChange(e.target.value)}
                            disabled={selectionFlag}
                              />
                        <label htmlFor="Functional_Problem" className="pl-2">My device isn't functioning as it should</label>
                    </div>
                    <div  className={type === "Liquid exposure" ? 'bg-blue-100 pt-3' : `pt-3`}>
                        <input type="radio"
                            id="Liquid_Exposure"
                            name="problem"
                            value="Liquid exposure"
                            checked={type === "Liquid exposure"}
                            onChange={(e) => onChange(e.target.value)}
                            disabled={selectionFlag} />
                        <label htmlFor="Liquid_Exposure" className="pl-2">Liquid exposure</label>
                    </div>
                    <div className={type === "Scratched and Dented" ? 'bg-blue-100 pt-3' : `pt-3`}>
                        <input type="radio"
                            id="Scratched"
                            name="problem"
                            value="Scratched and Dented"
                            checked={type === "Scratched and Dented"}
                            onChange={(e) => onChange(e.target.value)}
                            disabled={selectionFlag} />
                        <label htmlFor="Scratched" className="pl-2">Scratched and dented</label>
                    </div>
                    <div className={type === "Other reason" ? 'bg-blue-100 pt-3' : `pt-3`}>
                        <input type="radio"
                            id="Other reason"
                            name="problem"
                            value="Other reason"
                            checked={type === "Other reason"}
                            onChange={(e) => onChange(e.target.value)}
                            disabled={selectionFlag} />
                        <label htmlFor="Other reason" className="pl-2">Other reason</label>
                    </div>
                </div>
                <br />
            </div>
            <div className="border-2 border-solid p-3 leading-6">
                <p>
                    Your device(s) will be dispatched to the nominated delivery address within 1 business day. By submitting this request, you are confirming that the address and device details have been entered correctly and you accept responsibility for any errors or omissions.
                </p>
                <p className="pt-6">
                    Press SUBMIT to continue
                </p>
            </div>
        </div>
    )
}

export default SubmitSRIncident;