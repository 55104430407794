import { useEffect, useState } from 'react'
import Grid from '../../grid'
import Layout from '../../header'
import { MemoryRouter as Router, Switch, Route, Redirect, NavLink, useHistory, useParams } from 'react-router-dom'
import tableFormat from '../../tableformat'
import Pagination from '../../pagination'
import { useShared } from '../../../state-management/shared/SharedContextProvider'
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider'
import { filterAndSyncArr, getEnrolledAsset, getReplacedAsset } from '../../../utils/helper';
import moment from 'moment';
import Notification from '../../../utils/notification';
import CancelPopup from './confirmationpopup';
import UpdateIMEIPopup from './updateimeipopup';

const SubmitNewServiceRequest = () => {
  const { state: iniState, findAgreements, setSelectedAgreement, unSetSelectedAgreement, cleanFindAgreement, setLicenseKey, setInteractionLineId, setSelectedSavedSR } = useShared();
  const { state: srState, bulkRequestMethod, setBulkServiceRequest, claimFlowApiRequest, determineIncident, getBulkSrSaveAndSubmit, cleanUpdateIMEIData, getCustomerCaseGraph } = useSubmitSR();
  const location = window.location.search;
  const state = new URLSearchParams(location).get('state');
  let stateArray = state && state.split(' ');
  let isUserInfoCall = stateArray && stateArray[1] && stateArray[1] == 'true' ? true : false;
  const license_key = iniState?.LicenseKey;
  //const licenseKeyForNonProd = new URLSearchParams(location).get('orderid');
  let accessTokenCookie = document.cookie.split(';').find(i => i.match('token'));

  const _grdFields = {
    key: 'IMEI',
    showCheck: true,
    fields: [
      { name: 'IMEI', label: 'IMEI' },
      { name: 'DeviceModelName', label: 'Device Model Name' },
      { name: 'LicenseKey', label: 'License Key' },
      { name: 'AgreementStart', label: 'Enrolment Start' },
      { name: 'AgreementEnd', label: 'Enrolment End' },
      { name: 'Term', label: 'Program Term' },
      { name: 'Allowance', label: 'Program Allowance' },
      { name: 'SRCompleted', label: 'SR Complete' },
    ],
  }

  let FilteredData = iniState.FindAgreement?.Agreements ? iniState.FindAgreement?.Agreements?.Agreement.map((value, index) => {
    return {
      AgreementId: value.AgreementId,
      IMEI: getReplacedAsset(value.Assets) ? getReplacedAsset(value.Assets)?.IMEI : getEnrolledAsset(value.Assets)?.IMEI,
      DeviceModelName: getReplacedAsset(value.Assets) ? getReplacedAsset(value.Assets)?.AssetCatalog?.
        AssetCatalogName : getEnrolledAsset(value.Assets)?.AssetCatalog?.AssetCatalogName,
      LicenseKey: value.LicenseKey,
      AgreementStart: moment(value.StartDate).format('DD MMM YYYY'),
      AgreementEnd: moment(value.EndDate).format('DD MMM YYYY'),
      Term: `${value.ClientOffer.ProgramTerm} Months`,
      Allowance: `${value.ClientOffer.ProgramAllowance}%`,
      SRCompleted: value.SRCompleted?.toString(),
      AssetId: getEnrolledAsset(value.Assets)?.AssetId,
      ClientAccountId: value?.ClientAccount?.ClientAccountId,
      IdentificationNumber: value?.IdentificationNumber,
      AgreementStatus:value?.AgreementStatus
    }
  }) :
    [{
      AgreementId: iniState.FindAgreement?.AgreementId,
      IMEI: iniState.FindAgreement?.AssetsList?.Asset[0]?.IMEI,
      DeviceModelName: iniState.FindAgreement?.AssetsList?.Asset[0]?.Model.Name,
      LicenseKey: iniState.FindAgreement?.LicenseKey,
      AgreementStart: moment(iniState.FindAgreement?.AgreementStartDate).format('DD MMM YYYY'),
      AgreementEnd: moment(iniState.FindAgreement?.AgreementEndDate).format('DD MMM YYYY'),
      Term: `${iniState.FindAgreement?.ProgramTerm} Months`,
      Allowance: `${iniState.FindAgreement?.ProgramAllowance}%`,
      SRCompleted: iniState.FindAgreement?.SRCompleted?.toString(),
      AssetId: iniState.FindAgreement?.AssetsList?.Asset[0]?.AssetId,
      ClientAccountId: iniState?.FindAgreement?.ClientAccount?.ClientAccountId,
      IdentificationNumber: iniState?.FindAgreement?.IdentificationNumber,
      AgreementStatus:iniState?.FindAgreement?.AgreementStatus
    }];

  const history = useHistory();
  const [deviceList, setDeviceList] = useState([]);
  const [grdFields, setGrdFields] = useState(_grdFields);
  const [imei, setIMEI] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  //const [selectedDeviceList, setSelectedDeviceList] = useState([]);
  const [isResumeClaim, setIsResumeClaim] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedImeiCount, setSelectedImeiCount] = useState(0);
  const [showBulkLimitPopup, setLimitSelection] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [isBulkResumeClaim, setBulkResumeClaim] = useState(false);
  const [isBulkResume, setBulkResume] = useState([]);
  const [isInquiry, setInquiry] = useState(false);
  const [isBulkInquiry, setBulkInquiry] = useState([]);
  const [allBulkInputData, setAllBulkInputData] = useState([]);
  const [isSwapAllowanceRemaining, setSwapAllowanceRemaining] = useState([]);
  const [showUpdateIMEIPopup, setShowUpdateIMEIPopup] = useState(false);
  const [isUpdateIMEIAllowed, setUpdateIMEIAllowed] = useState("");
  const [showSwapCheck, setShowSwapCheck] = useState(false);
  const [updateIMEIData, setUpdateIMEIData] = useState([]);
  const [isAgreementRMNTD, setIsAgreementTRMNTD] = useState(false);
  let LicenseKeySearch = {
    LicenseKey: license_key,
    PageSize: "10",
    PageNumber: pageNumber.toString(),
    IMEI: imei
  }

  let FindAgreementsParameters = {
    CacheId: iniState?.CacheId,
    IsEnterpriseRequest: true
  }

  let EnterpriseClaimApiParameters = {
    InteractionLineId: iniState?.InteractionLineId
  }

  let IsSSOAuth = process.env.REACT_APP_SSO_AUTH;

  useEffect(() => {
    if (iniState.isAgreementSuccess) {
      setDeviceList(FilteredData);
      let selectedData = iniState?.selectedAgreement?.map((value, index) => {
        return {
          AgreementId: value.AgreementId,
          IMEI: getReplacedAsset(value.Assets) ? getReplacedAsset(value.Assets)?.IMEI : getEnrolledAsset(value.Assets)?.IMEI,
          DeviceModelName: getReplacedAsset(value.Assets) ? getReplacedAsset(value.Assets)?.AssetCatalog?.AssetCatalogName : getEnrolledAsset(value.Assets)?.AssetCatalog?.AssetCatalogName,
          LicenseKey: value.LicenseKey,
          AgreementStart: moment(value.StartDate).format('DD MMM YYYY'),
          AgreementEnd: moment(value.EndDate).format('DD MMM YYYY'),
          Term: `${value.ClientOffer.ProgramTerm} Months`,
          Allowance: `${value.ClientOffer.ProgramAllowance}%`,
          SRCompleted: value.SRCompleted?.toString(),
          AssetId: getEnrolledAsset(value.Assets)?.AssetId,
          ClientAccountId: value?.ClientAccount?.ClientAccountId,
          IdentificationNumber: value?.IdentificationNumber,
          isChecked: true
        }
      })
      if (iniState?.selectedAgreement?.length > 0) {
        let checkForSelected = [];
        let _deviceList = FilteredData || [];
        iniState.FindAgreement?.Agreements?.Agreement.map((val, index) => {
          selectedData.map(j => {
            if (j.AgreementId === val.AgreementId) {
              const newData = { ...j, isChecked: j.isChecked }
              _deviceList = _deviceList.map((row, i) => (i === index ? newData : row));
              checkForSelected.push(index);
            }
          })
        })
        setGrdFields(grdFields)
        setDeviceList(_deviceList);
      }
    }
  }, [iniState.isAgreementSuccess]);

  useEffect(() => {
    if ((iniState.isInteractionSuccess || iniState.UpdateInteractionResponse) && (!IsSSOAuth || (iniState?.AccessTokenResponse?.access_token || accessTokenCookie))) {
      if (!isUserInfoCall || (isUserInfoCall && iniState?.GetLicenseKeysResponse)) {
        if (imei != '' || pageNumber) {
          setDeviceList([]);
          cleanFindAgreement();
        }
        iniState?.InteractionResponse?.InteractionLineId && setInteractionLineId(iniState?.InteractionResponse?.InteractionLineId)
        findAgreements(Object.assign({}, { FindAgreementsParameters: { ...FindAgreementsParameters, ...LicenseKeySearch } }));
      }
    }
  }, [iniState.isInteractionSuccess, iniState.UpdateInteractionResponse, imei, pageNumber]);

  useEffect(() => {
    if (srState.claimFlowApiResponses && srState.claimFlowApiResponses?.GetBulkSRResponse?.getSavedSRs[0]?.IsProcessed == "0") {
      setBulkResumeClaim(true)
    } else if (srState.claimFlowApiResponses && srState.claimFlowApiResponses?.GetBulkSRResponse?.Inquiry[0]?.isInquiryFlag == "1") {
      setInquiry(true);
    }
    // else if (srState.claimFlowApiResponses && srState.claimFlowApiResponses?.DetermineIncidentsPath?.IsEligibleForReship) {
    //   let swapEligible = [true];
    //   setSwapAllowanceRemaining(swapEligible);
    // }
    else if (srState.claimFlowApiResponses && srState.claimFlowApiResponses?.DetermineIncidentsPath?.Type == "StartServiceRequest") {
      history.replace('/submit-service-request-confirmation')
    }
    else if (srState.claimFlowApiResponses && srState.claimFlowApiResponses?.GetBulkSRResponse.getSavedSRs?.length === 0 && srState.claimFlowApiResponses?.DetermineIncidentsPath?.Type == "ResumeServiceRequest") {
      setIsResumeClaim(true)
    }
    else if (srState.claimFlowApiResponses && srState.claimFlowApiResponses?.DetermineIncidentsPath?.Type == "NoActionRequired") {
      setIsSubmitted(true)
    }
  }, [srState.claimFlowApiResponses]);

  useEffect(() => {
    if (srState.bulkSrSaveAndSubmitData != undefined && srState.bulkSrSaveAndSubmitData != "") {
      let savedBulkSrData = srState.bulkSrSaveAndSubmitData && srState.bulkSrSaveAndSubmitData.getSavedSRs;
      let inquiryBulkSrData = srState.bulkSrSaveAndSubmitData && srState.bulkSrSaveAndSubmitData.Inquiry;

      let notSavedData = [];
      let isBulkInquiryProgress = inquiryBulkSrData && inquiryBulkSrData.filter(i => { if (i.isInquiryFlag == '1') return i.isInquiryIMEI })
      let isBulkInprogress = savedBulkSrData && savedBulkSrData.filter(i => { if (i.IsProcessed == "0") return i.IMEI });
      if (isBulkInprogress?.length > 0) {
        setBulkResume(isBulkInprogress.map((i) => i.IMEI))
      } else if (isBulkInquiryProgress?.length > 0) {
        setBulkInquiry(isBulkInquiryProgress.map((i) => i.isInquiryIMEI))
      }
      else {
        notSavedData = allBulkInputData && allBulkInputData.length && allBulkInputData.filter((element1) => {
          return !savedBulkSrData.some(function (element2) {
            return element1.AgreementId === element2.AgreementId;
          });
        })
        if (notSavedData.length) {
          bulkRequestMethod({ "ListOfSRsParameter": notSavedData });
        }
        setSelectedSavedSR(savedBulkSrData);
        if (notSavedData.length == 0) {
          history.replace('/submit-sr-bulk');
        }
      }
    }
  }, [srState.bulkSrSaveAndSubmitData])

  useEffect(() => {
    if (srState.bulkClaimStatus) {
      let allStartedSR = srState.bulkClaimStatus.map((i) => i.Type === 'StartServiceRequest')
      if (allStartedSR.every(val => val === true)) {
        //let selected = deviceList.filter(item => item.isChecked);
        //const findAgreementArr = iniState.FindAgreement?.Agreements['Agreement'] || [];
        const selectedAgreementArr = iniState?.selectedAgreement;//filterAndSyncArr(findAgreementArr, selected);
        const selectedAgreementClientChannelId = selectedAgreementArr[0]?.ClientAccount?.ClientChannelId;
        const allSelectedImei = selectedAgreementArr?.map(val => getReplacedAsset(val.Assets) ? getReplacedAsset(val.Assets)?.IMEI : getEnrolledAsset(val?.Assets)?.IMEI);
        const inputData = selectedAgreementArr && selectedAgreementArr?.length && selectedAgreementArr?.map((val, i) => {
          return {
            AgreementId: val?.AgreementId || "",
            AssetId: getReplacedAsset(val.Assets) ? getReplacedAsset(val.Assets)?.AssetId : getEnrolledAsset(val?.Assets)?.AssetId || "",
            AssetCatalogId: getReplacedAsset(val.Assets) ? getReplacedAsset(val.Assets)?.AssetCatalog?.AssetCatalogId : getEnrolledAsset(val?.Assets)?.AssetCatalog?.AssetCatalogId || "",
            ClientAccountId: val?.ClientAccount?.ClientAccountId || "",
            Imei: getReplacedAsset(val.Assets) ? getReplacedAsset(val.Assets)?.IMEI : getEnrolledAsset(val?.Assets)?.IMEI || "",
            Address1: val?.Address?.Address1 || "",
            Address2: val?.Address?.Address2 || "",
            Address3: val?.Address?.Address3 || "",
            City: val?.Address?.City || "",
            ReceiverName: val?.Customers?.Customer[0]?.FullName || "",
            Suburb: "",
            State: "",
            Postal: val?.Address?.PostalCode || "",
            Country: val?.Address?.CountryCode || "",
            //ClientId: val?.ClientAccount?.ClientAccountId || "",
            //ClientChannelId: val?.ClientAccount?.ClientChannelId || "",
            LicenseKey: iniState?.LicenseKey || "",
            PhoneNumber: val?.ContactPoints?.ContactPoint.filter(i => i.ContactPointType == 'MOBILE')[0]?.PhoneNumber
          }
        })
        setAllBulkInputData(inputData);
        if (allSelectedImei.length) {
          getBulkSrSaveAndSubmit({ Imei: allSelectedImei, ClientChannelId: selectedAgreementClientChannelId, isbulk: true })
        }
      } else {
        let errorIMEI = srState.bulkClaimStatus.filter((i) => i.Type != 'StartServiceRequest');
        setBulkResume(errorIMEI.map((i) => i.IMEI));
      }
    }
  }, [srState.bulkClaimStatus]);

  useEffect(() => {
    if (iniState.selectedAgreement.length > 0) {
      let selectedIMEICount = iniState.selectedAgreement.length;
      setSelectedImeiCount(selectedIMEICount);
    } else {
      setSelectedImeiCount(0);
    }
  }, [iniState.selectedAgreement])

  useEffect(() => {
    if (srState.bulkDetails) {
      const { ListOfSRs } = srState.bulkDetails;
      let getListOfRs = [];
      ListOfSRs && ListOfSRs.length && ListOfSRs.forEach(element => {
        getListOfRs.push(element[0][0])
      });
      setBulkServiceRequest(getListOfRs);
      history.replace('/submit-sr-bulk')
    }
  }, [srState.bulkDetails]);

  useEffect(() => {
    if (iniState?.FindAgreement) {
      setLicenseKey(iniState?.FindAgreement?.LicenseKeys || license_key)
    }
  }, [iniState?.FindAgreement])

  const onCancel = () => {
    setShowUpdateIMEIPopup(false);
    cleanUpdateIMEIData();
  }

  const onRowCheckChange = (data, index) => {
    let _deviceList = deviceList || [];
    const newData = { ...data, isChecked: !data.isChecked }
    let currentSelectedAgreements = [];
    _deviceList = _deviceList.map((row, i) => {
      if (i === index) {
        if (iniState?.selectedAgreement?.length > 0) {
          iniState?.selectedAgreement?.map((sa, index) => {
            currentSelectedAgreements = [...iniState.FindAgreement?.Agreements?.Agreement.filter(item => newData.AgreementId == item.AgreementId)];
            if (sa.AgreementId != newData.AgreementId) {
              if (newData.isChecked) {
                setSelectedAgreement(currentSelectedAgreements);
              } else {
                unSetSelectedAgreement(currentSelectedAgreements)
              }
            } else {
              unSetSelectedAgreement(currentSelectedAgreements);
            }
          })
        } else {
          currentSelectedAgreements = [...iniState.FindAgreement?.Agreements?.Agreement.filter(item => newData.AgreementId == item.AgreementId)];
          setSelectedAgreement(currentSelectedAgreements);
        }
        return newData
      } else {
        return row
      }
    })
    if (!newData.isChecked) {
      grdFields.isChecked = false
      setGrdFields(grdFields)
    }
    setDeviceList(_deviceList)
  }
  
  useEffect(() => {    
    if ([deviceList?.isChecked]) {     
      setIsAgreementTRMNTD(deviceList.filter(i=> i.isChecked==true && i.AgreementStatus== 'TRMNTD').length > 0 ? true:false)
    }
  }, [deviceList])
  const onHeaderCheckChange = (isChecked) => {
    grdFields.isChecked = isChecked
    setGrdFields(grdFields)
  }

  const remainingAllowanceCheck = () => {
    let swapAllowanceCheck = [];
    setSwapAllowanceRemaining([]);
    let licKeys = license_key.split(',');
    licKeys.map(i => {
      let FilterByLicKey = iniState?.selectedAgreement?.filter(item => i == item.LicenseKey)
      if (FilterByLicKey.length > 0) {
        if (FilterByLicKey[0].SwapAllowanceRemaining != 0 && (FilterByLicKey.length <= FilterByLicKey[0].SwapAllowanceRemaining)) {
          return;
        } else {
          swapAllowanceCheck.push(i);
        }
      }
    })
    return swapAllowanceCheck;
  }

  const onNextClick = () => {
    setShowSwapCheck(false);
    const swapCheck = remainingAllowanceCheck();
    setUpdateIMEIAllowed("")
    if (swapCheck.length == 0) {
      //if (iniState.selectedAgreement[0]?.SwapAllowanceRemaining != 0 && (iniState.selectedAgreement?.length <= iniState.selectedAgreement[0]?.SwapAllowanceRemaining)) {
      if (iniState.selectedAgreement?.length === 0) {
        return;
      }
      else if (iniState.selectedAgreement?.length > 10) {
        setLimitSelection(true);
      }
      else if (iniState.selectedAgreement.length === 1) {
        //single SR flow
        setIsResumeClaim(false)
        setIsSubmitted(false)
        setBulkResumeClaim(false)
        setBulkResume([])
        setInquiry(false)
        setShowSwapCheck(false);
        setSwapAllowanceRemaining([]);
        setBulkInquiry([])
        claimFlowApiRequest({
          EnterpriseClaimApiParameters: {
            ClientAccountId: iniState.selectedAgreement[0]?.ClientAccount?.ClientAccountId,
            //CorrelationId: "Node-07DE6FB7F899476FB24F1A42E0820F5E",
            FullName: iniState.selectedAgreement[0]?.Customers?.Customer[0].FullName,
            InteractionLineId: iniState.InteractionLineId,
            AgreementId: iniState.selectedAgreement[0]?.AgreementId,
            CustomerId: iniState.selectedAgreement[0]?.Customers?.Customer[0].CustomerId,
            ClientAccountType: iniState.selectedAgreement[0]?.ClientAccount?.ClientAccountType,
            IMEI: getReplacedAsset(iniState?.selectedAgreement[0]?.Assets)?.IMEI ? getReplacedAsset(iniState.selectedAgreement[0]?.Assets)?.IMEI : getEnrolledAsset(iniState.selectedAgreement[0]?.Assets)?.IMEI || "",
            InteractionId: iniState?.InteractionResponse?.InteractionId || iniState?.UpdateInteractionResponse?.InteractionId
          },
          FromRequest: "NEXT"
        })
      } else {
        setBulkResume([])
        setBulkInquiry([])
        const determineIncidentParams = iniState?.selectedAgreement && iniState?.selectedAgreement?.length && iniState.selectedAgreement.map((val, i) => {
          return {
            AgreementId: val.AgreementId || "",
            ClientId: val?.ClientAccount?.ClientAccountId || "",
            CustomerId: val?.Customers?.Customer[0]?.CustomerId || "",
            ClientAccountType: val?.ClientAccount?.ClientAccountType || "",
            IMEI: getReplacedAsset(val.Assets)?.IMEI ? getReplacedAsset(val.Assets)?.IMEI : getEnrolledAsset(val?.Assets)?.IMEI || "",
          }
        })
        determineIncident(Object.assign({}, { EnterpriseClaimApiParameters: { ...EnterpriseClaimApiParameters, BulkData: [...determineIncidentParams] } }));
      }
    }
    else {
      setSwapAllowanceRemaining(swapCheck);
    }
  }

  const search = (e) => {
    setIMEI(searchData);
  }

  const onNo = () => {
    setLimitSelection(false);
  }

  const onSearchDataChanged = (e) => {
    if (e === '') {
      setSearchData('');
    }
    else {
      setSearchData(e)
    }
  }

  useEffect(() => {
    if (isSwapAllowanceRemaining?.length > 0 || isSwapAllowanceRemaining[0] == true) {
      setShowSwapCheck(true);
    } else {
      setShowSwapCheck(false);
    }
  }, [isSwapAllowanceRemaining]);

  const onUpdateIMEIClick = () => {
    setUpdateIMEIAllowed("")
    let updateIMEIData = [{
      AgreementId: iniState?.selectedAgreement[0]?.AgreementId,
      IMEI: getReplacedAsset(iniState.selectedAgreement[0].Assets) ? getReplacedAsset(iniState?.selectedAgreement[0]?.Assets)?.IMEI : getEnrolledAsset(iniState?.selectedAgreement[0]?.Assets)?.IMEI,
      DeviceModelName: getReplacedAsset(iniState?.selectedAgreement[0]?.Assets) ? getReplacedAsset(iniState.selectedAgreement[0].Assets)?.AssetCatalog?.
        AssetCatalogName : getEnrolledAsset(iniState?.selectedAgreement[0]?.Assets)?.AssetCatalog?.AssetCatalogName,
      LicenseKey: iniState?.selectedAgreement[0]?.LicenseKey,
      AgreementStart: moment(iniState?.selectedAgreement[0]?.StartDate).format('DD MMM YYYY'),
      AgreementEnd: moment(iniState?.selectedAgreement[0]?.EndDate).format('DD MMM YYYY'),
      Term: `${iniState?.selectedAgreement[0]?.ClientOffer.ProgramTerm} Months`,
      Allowance: `${iniState?.selectedAgreement[0]?.ClientOffer.ProgramAllowance}%`,
      SRCompleted: iniState?.selectedAgreement[0]?.SRCompleted?.toString(),
      AssetId: getEnrolledAsset(iniState?.selectedAgreement[0]?.Assets)?.AssetId,
      ClientAccountId: iniState?.selectedAgreement[0]?.ClientAccount?.ClientAccountId,
      IdentificationNumber: iniState?.selectedAgreement[0]?.IdentificationNumber
    }];
    setUpdateIMEIData(updateIMEIData);
    getCustomerCaseGraph({ clientAccountId: iniState?.selectedAgreement[0]?.ClientAccount?.ClientAccountId })
  }

  useEffect(() => {
    srState?.CustomerCaseGraphResponse && setUpdateIMEIAllowed(srState?.CustomerCaseGraphResponse?.CustomerCases && srState?.CustomerCaseGraphResponse?.CustomerCases[0]?.CustomerCaseStatus)
  }, [srState?.CustomerCaseGraphResponse])

  useEffect(() => {
    if ((isUpdateIMEIAllowed !== "") && ((isUpdateIMEIAllowed == "WORKING") || (isUpdateIMEIAllowed == 'NEW') || (isUpdateIMEIAllowed == "CMPLTD"))) {
      return true;
    }
    else if (isUpdateIMEIAllowed !== "") {
      //let selected = deviceList.filter(item => item.isChecked);
      //const findAgreementArr = iniState.FindAgreement?.Agreements['Agreement'] || [];
      //const selectedAgreementArr = filterAndSyncArr(findAgreementArr, selected);
      const selectedAgreementClientChannelId = iniState?.selectedAgreement[0]?.ClientAccount.ClientAccountId;
      //const allSelectedImei = selectedAgreementArr?.map(val => getReplacedAsset(val.Assets) ? getReplacedAsset(val.Assets)?.IMEI : getEnrolledAsset(val?.Assets)?.IMEI);
      const allSelectedImei = getReplacedAsset(iniState?.selectedAgreement[0]?.Assets) ? getReplacedAsset(iniState?.selectedAgreement[0]?.Assets)?.IMEI : getEnrolledAsset(iniState?.selectedAgreement[0]?.Assets)?.IMEI;
      getBulkSrSaveAndSubmit({ Imei: allSelectedImei, ClientChannelId: selectedAgreementClientChannelId, isbulk: false })
    }
  }, [isUpdateIMEIAllowed])

  useEffect(() => {
    if (srState?.updateIMEIInquiryDetails) {
      if (srState?.updateIMEIInquiryDetails.Inquiry[0]?.isInquiryFlag == "1") {
        setInquiry(true);
      } else if (srState?.updateIMEIInquiryDetails.Inquiry[0]?.isInquiryFlag == "0") {
        setShowUpdateIMEIPopup(true);
      }
    }
  }, [srState?.updateIMEIInquiryDetails]);

  return (
    <Layout label="Swap Request">
      {showBulkLimitPopup && <CancelPopup msg={"Only 10 records can be selected in bulk swap request."} open={showBulkLimitPopup} onNo={onNo} showOK={true} onClick={onNo} />}
      {showUpdateIMEIPopup && <UpdateIMEIPopup open={true} onCancel={onCancel} data={updateIMEIData[0]} />}
      <div className="py-2">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <span className="mt-5 mb-2 w-50 h-7 border-2 py-1 px-3 flex justify-between rounde-md rounded-md">
              <input type="text"
                className="outline-none text-gray-600 focus:text-blue-600"
                name="searchText" placeholder="Search IMEI"
                onChange={(e) => { onSearchDataChanged(e.target.value) }}
                value={searchData} />
            </span>
            <span className="mt-5 mb-2 pl-2"><button className={`p-1 h-7 w-24 text-sm bg-white border rounded text-black border-black`}
              onClick={(e) => search()}>Search</button></span>
          </div>
          <div className="flex flex-col">
            <span className="flex-grow text-gray-600 text-gray-600 mt-5 mb-2 border-2 p-1">Selected IMEI Count :{<span className="text-red-600">{selectedImeiCount}</span>}</span>
          </div>
        </div>
        {deviceList && deviceList.length > 0 && deviceList[0].IMEI && <div className="py-2 overflow-x-auto">
          <Grid
            className="text-sm font-normal w-full border border-black border-collapse"
            onRowCheckChange={onRowCheckChange}
            onHeaderCheckChange={onHeaderCheckChange}
            list={deviceList || []}
            headerRow={tableFormat.headerRow(grdFields)}
            dataRow={tableFormat.dataRow(grdFields)}
          />
        </div>}
        {deviceList && deviceList.length > 0 && deviceList[0].IMEI ? <Pagination
          pageNumber={pageNumber ? pageNumber : 1}
          totalPages={!imei ? iniState?.FindAgreement?.TotalPages : 1}
          onPageClicked={(page) => setPageNumber(page)} />
          :
          <p className='text-md'>No Records Found</p>}
        <div className="flex flex-row justify-between mt-5">
          <div className="flex flex-col">
            <button className={` p-1 w-24 ml-2 focus:bg-blue-100 text-sm bg-white border rounded ${iniState?.selectedAgreement?.length == 1 && isAgreementRMNTD==false ? 'text-black border-black' : 'disable-button'}`} disabled={iniState?.selectedAgreement?.length == 1 && isAgreementRMNTD==false ? false : true} onClick={onUpdateIMEIClick} >UPDATE IMEI</button>
          </div>
          <div className="flex flex-col">
            <button className={`p-1 w-24 mr-2 focus:bg-blue-100 text-sm bg-white border rounded ${(selectedImeiCount > 0 || deviceList?.find(i => i.isChecked)) && isAgreementRMNTD==false ? 'text-black border-black' : 'disable-button'}`} disabled={(selectedImeiCount > 0 || deviceList?.find(i => i.isChecked)) && isAgreementRMNTD==false ? false : true} onClick={onNextClick} >NEXT</button>
          </div>
        </div>
        {
          isSubmitted &&
          <Notification type='error' msg='For selected record, one swap request is in submitted status. You can file another swap request once previous swap request is completed.' />
        }
        {
          isResumeClaim &&
          <Notification type='error' msg='For selected record, one swap request is inprogress. Please click on Resume/Cancel Swap Request tab to continue swap request' />
        }
        {
          isBulkResumeClaim &&
          <Notification type='error' msg={`For selected IMEI : ${deviceList?.filter(i => i.isChecked)[0].IMEI} , one swap request is inprogress.`} />
        }
        {
          isBulkResume.length > 0 &&
          <Notification type='error' msg={isBulkResume.length > 1 ? `For selected IMEI's : ${isBulkResume} , swap requests are inprogress/submitted.` : `For selected IMEI : ${isBulkResume} , swap request is inprogress/submitted.`} />
        }
        {
          isInquiry &&
          <Notification type='error' msg={`For selected IMEI : ${deviceList?.filter(i => i.isChecked)[0]?.IMEI} , one inquiry request is inprogress.`} />
        }
        {
          isBulkInquiry.length > 0 &&
          <Notification type='error' msg={isBulkInquiry.length > 1 ? `For selected IMEI's : ${isBulkInquiry} , inquiry request are inprogress/submitted.` : `For selected IMEI : ${isBulkInquiry} , inquiry request is inprogress/submitted.`} />
        }
        {showSwapCheck && <Notification type='error' msg={`Your swap allowance exceeds for ${isSwapAllowanceRemaining}`} />}
        {/* {isSwapAllowanceRemaining[0] == true && <Notification type='error' msg={`For selected record, one swap request is in submitted status`} />} */}
        {
          srState?.CustomerCaseGraphResponse?.CustomerCases?.length > 0 && isUpdateIMEIAllowed && ((isUpdateIMEIAllowed == "WORKING") || (isUpdateIMEIAllowed == 'NEW')) && <Notification type='error' msg='Currently, you have on-going swap request. Please cancel open swap request to update device.' />}
        {
          srState?.CustomerCaseGraphResponse?.CustomerCases?.length > 0 && isUpdateIMEIAllowed && isUpdateIMEIAllowed == ("CMPLTD") && <Notification type='error' msg='You have already submitted swap request. You are not eligible to raise request to update device.' />
        }
        {
          isAgreementRMNTD &&
          <Notification type='error' msg='For selected IMEI enrolment is terminated.' />
        }
      </div>
    </Layout>
  )
}

export default SubmitNewServiceRequest;