import { useEffect, useRef, useState } from 'react'
import { MemoryRouter as Router, useHistory } from 'react-router-dom'
import Layout from '../../header'
import { useShared } from '../../../state-management/shared/SharedContextProvider'
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider';
import { useSubmitResumeSR } from '../../../state-management/resumeservicerequest/SubmitRSRContextProvider';
import SubmitSRDeviceDetails from './submitsrdevicedetails'
import SubmitSRIncident from './submitsrincidentsection'
import SubmitSRAddress from './submitsraddresssection'
import SubmitSRIndividual from './submitsrindividual'
import CancelPopup from './confirmationpopup'
import Loader from '../../loader';
import Notification from '../../../utils/notification';
import InWarrantyPopup from './inwarrantypopup'

const SubmitServiceRequestConfirmation = (props) => {
    const history = useHistory()
    const { state: iniState, cleanInit } = useShared();
    const { state: srState, cleanPreviousResultsNext, cleanPreviousResultsSubmit,
        claimFlowApiRequest, cleanPreviousResultsPopup, standardiseaddress, IsAddressValid } = useSubmitSR();
    const { state: resumeState } = useSubmitResumeSR();
    const [agreementData, setAgreementData] = useState(iniState?.selectedAgreement[0]); //iniState.Agreement.FindAgreementsResults.Agreements.Agreement[0];
    const [incidentType, setIncidentType] = useState(`Other reason`);
    const [showPopup, setShowPopup] = useState(false);
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [isOEMWarranty, setIsOEMWarranty] = useState(false);
    const [isVerified, setVerified] = useState(false);
    const [isSaveCall, setSaveCall] = useState(false);
    const [submitCall, setSubmitCall] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const getPeril = (selectedPeril) => ({
        "MY DEVICE ISN'T FUNCTIONING AS IT SHOULD": "Swap",
        "OTHER REASON": "Catastrophic Damage",
        "SCRATCHED AND DENTED": "Scratched and Dented",
        "LIQUID EXPOSURE": "Liquid Exposure"
    }[selectedPeril]);

    const reversePeril = (selectedPeril) => ({
        "SWAP": "My device isn't functioning as it should",
        "CATASTROPHIC DAMAGE": "Other reason",
        "SCRATCHED AND DENTED": "Scratched and Dented",
        "LIQUID EXPOSURE": "Liquid exposure"
    }[selectedPeril]);

    const _isPreviousSwapped = srState.claimFlowApiResponses && srState.claimFlowApiResponses.CaseGraphResponse && srState.claimFlowApiResponses.CaseGraphResponse && srState.claimFlowApiResponses.CaseGraphResponse.CustomerCaseResults?.CustomerCases.filter(i => i.CustomerCaseStatus === 'CMPLTD');
    let _isPreviousResumeSwapped = srState && srState.CustomerCaseGraphResponse && srState.CustomerCaseGraphResponse.CustomerCases.filter(i => i.CustomerCaseStatus === 'CMPLTD');
    _isPreviousResumeSwapped = _isPreviousResumeSwapped && _isPreviousResumeSwapped.length === 0 ? srState?.CustomerCaseGraphResponse?.CustomerCases.filter(i => i.CustomerCaseStatus === 'WORKING') : _isPreviousResumeSwapped

    let assetCatalogId = agreementData?.Assets?.Asset.filter(i => i.AssetInstance === 'ENROLLED')[0]?.AssetCatalog?.AssetCatalogId;
    let ApprovedServiceFeeChange = false;
    let phoneList = {
        ServiceOrderLineType: "PHN",
        Quantity: 1,
        Priority: "MNDTRY",
        IsSimCard: false
    };
    let addressId = agreementData?.Address?.AddressId;

    let Address;
    if (srState.addressDetails?.AddressResult?.CorrectedAddress) {
        Address = Object.assign({}, { ...srState.addressDetails?.AddressResult?.CorrectedAddress });
    }
    else if (srState.selectedAddressDetails?.FirstName !== '') {
        Address = Object.assign({}, { ...srState.selectedAddressDetails })
    }
    //setAddress(Address)
    let claimFlowRequest = {
        EnterpriseClaimApiParameters: {
            ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
            CorrelationId: iniState?.correlationid,
            FullName: agreementData?.Customers?.Customer[0]?.FullName,
            InteractionLineId: iniState.InteractionLineId,
            AgreementId: agreementData?.AgreementId,
            CustomerId: agreementData?.Customers?.Customer[0]?.CustomerId,
            ClientAccountType: agreementData?.ClientAccount?.ClientAccountType,
            ServiceRequestId: srState?.claimFlowApiResponses?.NewServiceRequestResponse?.CustomerCase?.ServiceRequestId || srState && srState.CustomerCaseGraphResponse && srState.CustomerCaseGraphResponse.CustomerCases &&
                srState.CustomerCaseGraphResponse.CustomerCases[0] && srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests &&
                srState.CustomerCaseGraphResponse.CustomerCases[0].ServiceRequests[0].ServiceRequestId,
            CustomerCaseId: srState?.claimFlowApiResponses?.NewServiceRequestResponse?.CustomerCase?.CustomerCaseId || srState && srState.CustomerCaseGraphResponse && srState.CustomerCaseGraphResponse.CustomerCases &&
                srState.CustomerCaseGraphResponse.CustomerCases[0] && srState.CustomerCaseGraphResponse.CustomerCases[0].CustomerCaseId,
            IdentificationNumber: agreementData?.ClientAccount?.ClientAccountNumber,
            PhoneNumber: agreementData?.ContactPoints?.ContactPoint.filter(i => i.ContactPointType === 'MOBILE')[0]?.PhoneNumber,
            Incident: {
                FailureDescriptiveText: incidentType && getPeril(incidentType.toUpperCase()),
                IncidentType: incidentType && getPeril(incidentType.toUpperCase())
            },
            AssetDetails: {
                AssetCatalogId: assetCatalogId,
                SerialNumber: agreementData?.Assets?.Asset.filter(i => i.AssetInstance === 'ENROLLED')[0]?.IMEI
            },
            Assets: agreementData?.Assets?.Asset,
            FulfillmentOption: "RPLCMNT",
            ApprovedServiceFeeChange: ApprovedServiceFeeChange,
            ServiceOrder: {
                FulfillmentMethodType: "ADVEXCH",
                ServiceOrderLines: {
                    ServiceOrderLine: [
                        {
                            AssetCatalogId: assetCatalogId,
                            ...phoneList
                        }
                    ]
                }
            },
            ChargeOrder: {
                PaymentMethodType: "WIR",
                ChargeOrderStatus: "PREAUTH",
                AddressId: addressId,
                AdditionalChargeAuth: "false",
                ZipCode: ""
            },
            DetermineIncidentsPath: srState?.claimFlowApiResponses?.DetermineIncidentsPath,
            ReceiverAddress: {
                ...Address,
                AddressId: addressId,
                Standardized: false,
                IsOverridden: false
            },
            Warehouse: "SYD",
            CustomerType: "ENTERPRISE",
            IsHold: srState?.claimFlowApiResponses?.DetermineIncidentsPath?.doHoldsExist,
            EntityUpdateOptions: {
                ReturnEntity: true
            },
            ShippingOrder: {
                Remark: "",
                ReceiverName: `${srState?.selectedAddressDetails?.FirstName} ${srState?.selectedAddressDetails?.LastName}`,
                ReceiverAddress: {
                    ...Address,
                    StandardizedSourceName: "QAS", Country: "Australia",
                    IsOverridden: false,
                    AddressId: addressId,
                }
            }
        },
        FromRequest: "SAVE"
    }

    let CancelServiceRequestParams = {
        EnterpriseClaimApiParameters: {
            NoteText: "", Operation: "Cancel",
            Reason: "Cancel-Customer", SubReason: "Customer-Cancelled by Customer (Device Found/Customer Choice)", CancelEntities: "ALL", RequestedBy: "Agent", ReturnEntity: true,
            InteractionLineId: iniState?.InteractionLineId,
            ServiceRequestId: srState?.claimFlowApiResponses?.DetermineIncidentsPath?.ServiceRequestId || srState?.claimFlowApiResponses?.NewServiceRequestResponse?.CustomerCase?.ServiceRequestId || resumeState?.SingleResumeSRResponse?.ResumeServiceRequestResponse?.ServiceRequestId,
            ClientAccountType: agreementData?.ClientAccount?.ClientAccountType
        }, FromRequest: "CANCEL"
    }

    let QASParams = {
        Address: {
            PostalCode: srState?.selectedAddressDetails?.PostalCode,
            Address1: srState?.selectedAddressDetails?.AddressLine1,
            Address2: srState?.selectedAddressDetails?.AddressLine2,
            Address3: srState?.selectedAddressDetails?.AddressLine3
        }
    }

    const onCancel = () => {
        setShowCancelPopup(true);
    }

    const onNo = () => {
        setShowCancelPopup(false);
    }

    const onYes = () => {
        claimFlowApiRequest(CancelServiceRequestParams)
    }

    const onVerifyMyDevice = () => {
        setVerified(true);
    };

    const onSubmit = () => {
        setSubmitCall(true);
        setSaveCall(false);
        IsAddressValid(false)
        !disableButton && standardiseaddress({ QASParams: { ...QASParams } })
    }

    const onSave = () => {
        setSaveCall(true);
        setSubmitCall(false)
        IsAddressValid(false)
        !disableButton && standardiseaddress({ QASParams: { ...QASParams } })
    }

    const onIncidentChanged = (e) => {
        setIncidentType(e);
    }

    const onClosePopup = () => {
        setShowPopup(false);
        setSubmitCall(false);
        setSaveCall(false);
        cleanPreviousResultsPopup()
    }

    const onCloseWarrantyPopup = () => {
        setIsOEMWarranty(false);
    }

    useEffect(() => {
        if (isSaveCall && !submitCall) {
            setSubmitCall(false)
            !disableButton ? IsAddressValid(false) : IsAddressValid(true)
        }
        if (submitCall && !isSaveCall) {
            setSaveCall(false)
            !disableButton ? IsAddressValid(false) : IsAddressValid(true)
        }
    }, [isSaveCall, submitCall])

    useEffect(() => {
        if (srState.claimFlowApiResponses && srState.claimFlowApiResponses?.DetermineIncidentsPath?.InOEMWarranty) {
            if (incidentType === `My device isn't functioning as it should`) {
                setIsOEMWarranty(true)
            }
        }
    }, [srState?.claimFlowApiResponses, incidentType]);


    useEffect(() => {
        if (_isPreviousResumeSwapped && _isPreviousResumeSwapped[0]?.IncidentType) {
            setIncidentType(reversePeril(_isPreviousResumeSwapped[0]?.IncidentType.toUpperCase()))
        }
    }, [_isPreviousResumeSwapped && _isPreviousResumeSwapped[0]?.IncidentType])

    useEffect(() => {
        if (srState?.addressDetails?.PickListAddress?.Score == "0") {
            IsAddressValid(true)
        }
        if (srState?.addressDetails?.PickListAddress?.Score !== "0") {
            srState?.addressDetails && (isSaveCall || submitCall) && claimFlowApiRequest(claimFlowRequest)
        }
    }, [srState?.addressDetails]);

    useEffect(() => {
        if (submitCall && srState?.claimFlowApiResponsesForSave) {
            if (!isSaveCall) { setShowPopup(true) }
            //else { cleanPreviousResultsSubmit() }
        }
    }, [srState?.claimFlowApiResponsesForSave]);

    useEffect(() => {
        if (srState?.claimFlowApiResponsesForCancel) {
            cleanInit();
            cleanPreviousResultsNext();
            cleanPreviousResultsSubmit();
            if (resumeState?.isReusmeSR) {
                history.replace('/resume-cancel-service-request')
            }
            else {
                history.replace('/')
            }
        }
    }, [srState?.claimFlowApiResponsesForCancel]);

    return (
        <>
            {(iniState?.showProgressIndicator || srState?.showProgressIndicator) && <Loader />}
            <Layout label="Swap Request">
                {showCancelPopup && <CancelPopup msg={"Are you sure you want to cancel?"} open={showCancelPopup} onYes={onYes} onNo={onNo} onClick={onNo} />}
                {isOEMWarranty && <InWarrantyPopup open={isOEMWarranty} onClick={onCloseWarrantyPopup} data={agreementData} onCancel={onCancel} onYes={onYes} />}
                {showPopup &&
                    <SubmitSRIndividual open={showPopup} onClick={onClosePopup} data={agreementData} />}
                <div className="py-5 mt-4 grid grid-cols-3 grid-flow-rows gap-2">
                    <div className="border-2 border-solid px-2">
                        <SubmitSRDeviceDetails data={agreementData} _isPreviousSwapped={_isPreviousSwapped || _isPreviousResumeSwapped} onVerifyMyDevice={onVerifyMyDevice} />
                    </div>
                    <SubmitSRIncident type={incidentType} selectionFlag={(srState?.claimFlowApiResponses?.DetermineIncidentsPath?.Type === 'StartServiceRequest' || resumeState?.SingleResumeSRResponse?.ResumeServiceRequestResponse?.Actions == 'CaptureIncidentDetails') ? false : true} onChange={(e) => onIncidentChanged(e)} />
                    <SubmitSRAddress data={agreementData} isValid={setDisableButton} />
                    {srState.isError &&
                        <Notification type="error" msg='Something went wrong, we are working to resolve this. Please try again in 24 hours' />}
                    <div className="flex col-start-3 items-end justify-end">
                        <button className={`p-1 w-24 mr-2 focus:bg-blue-100 text-sm bg-white border rounded ${isVerified ? 'text-black border-black' : 'disable-button'}`} disabled={!isVerified} onClick={onSave}>SAVE</button>
                        <button className={`p-1 w-24 mr-2 text-sm bg-white border focus:bg-blue-100 rounded text-black border-black`} onClick={onCancel}> CANCEL </button>
                        <button className={`p-1 w-24 text-sm bg-white border focus:bg-blue-100 rounded ${isVerified ? 'text-black border-black' : 'disable-button'}`} disabled={!isVerified} onClick={onSubmit} >SUBMIT</button>
                    </div>
                    {
                        srState?.isAddressValid && <Notification type="error" msg='Please enter valid address' />
                    }
                </div>
            </Layout >
        </>
    )
}

export default SubmitServiceRequestConfirmation