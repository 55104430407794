import React, { useEffect, useState } from 'react';
import { MemoryRouter as Router, useHistory } from 'react-router-dom'
import Popup from 'reactjs-popup';
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider'
import { useShared } from '../../../state-management/shared/SharedContextProvider';
import Loader from '../../loader';

export default (props) => {
    const { state: srState, cleanPreviousResultsNext } = useSubmitSR();
    const { state: iniState, cleanSelectedAgreement } = useShared();
    const { open, onClose, data, getPopUpData, allowanceData } = props;
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const history = useHistory()
    const onCloseClick = () => {
        if (!showSuccessMessage) {
            onClose()
        }
        else {
            cleanPreviousResultsNext();
            cleanSelectedAgreement();
            history.replace('/submit-service-request');
        }
    }

    const contentStyleForDesktopView = {
        padding: '0px',
        alignSelf: 'center',
        width: '50%',
    }

    const overlayStyle = { background: 'rgba(0,0,0,0.5)' };

    const onSubmit = (popUpData) => {
        getPopUpData(popUpData);
    }

    useEffect(() => {
        if (srState?.bulkSuccessDetails) {
            setShowSuccessMessage(true)
        }
    }, [srState?.bulkSuccessDetails]);

    return (
        <Popup
            open={open}
            contentStyle={contentStyleForDesktopView}
            overlayStyle={overlayStyle}
            closeOnDocumentClick={false}
            modal
            nested
        >
            {(iniState?.showProgressIndicator || srState?.showProgressIndicator) && <Loader isModalPopUp='isModalPopUp' />}
            <div className="flex justify-around w-700">
                <div className=" border bg-gray-100 w-auto p-4 bg-white rounded-md shadow-md dark:bg-gray-800">
                    <div className="flex flex-row justify-between">
                        <p className="flex flex-col mb-4 pl-48 font-bold justify-center">CONFIRM BULK SWAP REQUEST</p>
                        <button className="flex flex-col justify-right whitespace-normal h-6 w-6 px-2 text-sm bg-white border rounded text-black" onClick={onCloseClick}>
                            &times;</button>
                    </div>
                    <div className="">

                        <hr className="shadow" />
                        <div className="flex pt-2">
                            <div className="pl-3 text-sm flex-column">
                                <table className="table-fixed">
                                    <thead>
                                        <tr>
                                            <td colSpan="4"><hr className="border-black border-1 bg-black" /></td>
                                        </tr>
                                        <tr>
                                            <th className="w-1/4 p-2 m-4">IMEI</th>
                                            <th className="w-1/4 p-2 m-4">Device Modal Name</th>
                                            <th className="w-1/4 p-2 m-4">Shipping Name</th>
                                            <th className="w-1/4 p-2 m-4">Shipping Address</th>
                                        </tr>
                                        <tr className="mb-4">
                                            <td colSpan="4"><hr className="border-black border-1 bg-black mb-2" /></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.length && data.map((element, i) => (
                                            <tr key={i}>
                                                <td className="p-2 m-4">{element.IMEI}</td>
                                                <td className="p-2 m-4">{element.DeviceModelName}</td>
                                                <td className="p-2 m-4">{element.ShippingName}</td>
                                                <td className="p-2 m-4">{element.ShippingAddress}</td>
                                            </tr>
                                        ))}
                                        <tr className="mb-4">
                                            <td colSpan="4"><hr className="border-black border-1 bg-black my-4" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {/* <div className="pl-3 pt-6 text-sm font-normal w-full py-1 text-black font-bold">Swap Allowance</div>
                    <hr className="shadow" />
                    <div className="flex flex-row justify-around pt-2">
                        <div className="text-sm flex-column font-bold">
                           Used<br />
                            Remaining<br />
                        </div>
                        <div className="text-sm flex-column">
                            {allowanceData?.Used}<br />
                            {allowanceData?.Remaining}<br />
                        </div>
                    </div> */}
                    </div>
                    <div className="flex flex-row py-4 justify-around">
                        <div className='flex flex-col'><button className={` p-1 h-8 w-64 focus:bg-blue-100 text-sm bg-white border rounded ${showSuccessMessage ? 'disable-button' : 'text-black border-black cursor-pointer'}`}
                            onClick={() => onSubmit(data)} disabled={showSuccessMessage}>SUBMIT MY SWAP REQUEST</button></div>
                        <div className='flex flex-col'><button className={` p-1 h-8 w-40 focus:bg-blue-100 text-sm bg-white border rounded text-black border-black cursor-pointer`}
                            onClick={onCloseClick}>CLOSE</button></div>
                    </div>
                    {
                        showSuccessMessage && <p className="text-green-500 text-sm text-center">Your Bulk Swap Request has been submitted</p>
                    }
                </div>
            </div>
        </Popup >)
};