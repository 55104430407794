/* eslint-disable no-unused-vars */
const calculatePages = (records, pageSize) => (records % pageSize > 0) ? Math.trunc(records / pageSize) + 1 : records / pageSize;
const unsetProgressIndicator = (state, payload) => ({
  ...state,
  showProgressIndicator: false,
})

const setProgressIndicator = (state, payload) => ({
  ...state,
  showProgressIndicator: true,
})

const onreprintLabelSuccess = (state, payload) => ({
  ...state,
  reprintSuccess: payload,
  showProgressIndicator: false
})

const onreprintLabelError = (state, payload) => ({
  ...state,
  isError: true,
  showProgressIndicator: false
})

const onAllGetCustomerCaseGraphSuccess = (state, payload) => {
  return {
    ...state,
    allCustomerCaseGraphResponse: payload,
    isError: false,
    showProgressIndicator: false
  }
}

const onAllGetCustomerCaseGraphError = (state, payload) => ({
  ...state,
  isError: false,
  allCustomerCaseGraphError: payload,
  showProgressIndicator: false
})

export const reducers = {
  unsetProgressIndicator,
  setProgressIndicator,
  onAllGetCustomerCaseGraphSuccess,
  onAllGetCustomerCaseGraphError,
  calculatePages,
  onreprintLabelSuccess,
  onreprintLabelError
}

export const initialState = {
  showProgressIndicator: false,
  selectedPage: 0,
  isError: false,
}
