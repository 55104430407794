import API from '../api'
const resumeServiceRequest = () => API.post('api/v2/resumeservicerequest')
const claimFlowApiRequest = (data) => API.post(`api/v1/enterpriseclaimapis/${data.FromRequest}`,data)
const getResumeServiceRequest = (data) => API.get(`/api/v1/resumesrlist/${data}`)

const SubmitResumeSRAPI = {
  claimFlowApiRequest,
  resumeServiceRequest,
  getResumeServiceRequest
}

export default SubmitResumeSRAPI
