/* eslint-disable react-hooks/rules-of-hooks */
import { MemoryRouter as Router, Switch, Route, Redirect, NavLink } from 'react-router-dom';
import SubmitServiceRequest from './components/pages/submitservicerequest/index';
import Layout from './components/header';
import { SharedContextProvider } from './state-management/shared/SharedContextProvider';
import { SubmitSRContextProvider } from './state-management/submitservicerequest/SubmitSRContextProvider';
import FAQSection from './components/pages/faq';
import SubmitSRConfirmation from './components/pages/submitservicerequest/submitsrconfirmation';
import { FaqContextProvider } from './state-management/faq/FaqContextProvider';
import { ManageMyAccountContextProvider } from './state-management/managemyaccount/ManageMyAccountContextProvider';
import SubmitBulkConfirmation from './components/pages/submitservicerequest/submitbulkconfirmation';
import { SubmitResumeSRContextProvider } from './state-management/resumeservicerequest/SubmitRSRContextProvider';
import ResumeOrCancelServiceRequest from './components/pages/resumeorcancelservicerequest';
import ManageMyAccount from './components/pages/managemyaccount';

const App = () => (
  <SharedContextProvider>
    <SubmitSRContextProvider>
      <SubmitResumeSRContextProvider>
        <Router>
          <Switch>
            <Route path="/submit-service-request">
              <SubmitServiceRequest />
            </Route>
            <Route path="/resume-cancel-service-request">
              <ResumeOrCancelServiceRequest />
            </Route>
            <Route path="/manage-account">
              <ManageMyAccountContextProvider> <ManageMyAccount /> </ManageMyAccountContextProvider>
            </Route>
            <Route path="/submit-service-request-confirmation">
              <SubmitSRConfirmation />
            </Route>
            <Route path="/submit-sr-bulk">
              <SubmitBulkConfirmation />
            </Route>
            <Route path="/faq">
              <FaqContextProvider> <FAQSection /> </FaqContextProvider>
            </Route>            
            <Route path="/">
              <SubmitServiceRequest />
            </Route>
          </Switch>
        </Router>
      </SubmitResumeSRContextProvider>
    </SubmitSRContextProvider>
  </SharedContextProvider>
)
export default App
