import React, { useContext } from 'react'
import { initialState, reducers } from './reducer'
import { middleware } from './middleware'
import { useSmartReducer } from '../useSmartReducer'

const FAQContext = React.createContext()

const FaqContextProvider = ({ children }) => {
  const [state, actions] = useSmartReducer(initialState, reducers, middleware)
  return <FAQContext.Provider value={[state, actions]}>{children}</FAQContext.Provider>
}

const useFAQ = () => {
  const [state, actions] = useContext(FAQContext)
  return { state, ...actions }
}

export { FaqContextProvider, useFAQ }
