/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
import SubmitSRAPI from './api'

const getDeviceList = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SubmitSRAPI.getDeviceList(data)
    .then((data) => {
      actions.onGetDeviceDetailsSuccess(data?.data?.GetDeviceListResponse)
    })
    .catch((error) => actions.onGetDeviceDetailsError(error?.response?.data?.GetDeviceListResponse ?? error))
}

const standardiseaddress = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SubmitSRAPI.standardiseaddress(data)
    .then((addressData) => {
      if(data?.QASParams?.isBulk){
      actions.onstandardiseaddressBulkSuccess(addressData?.data)}
      else {
        actions.onstandardiseaddressSuccess(addressData?.data)
      }
    })
    .catch((error) => actions.onstandardiseaddressError(error?.response?.data ?? error))
}

const getCityState = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SubmitSRAPI.getCityState(data)
    .then((data) => {
      actions.ongetCityStateSuccess(data?.data)
    })
    .catch((error) => actions.ongetCityStateError(error?.response?.data ?? error))
}

const contactPoint = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SubmitSRAPI.contactPoint(data)
    .then((data) => {
      actions.onContactPointSuccess(data?.data)
    })
    .catch((error) => actions.onContactPointError(error?.response?.data ?? error))
}

const determineIncident = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SubmitSRAPI.determineIncident(data)
    .then((data) => {
      actions.onDetermineIncidentSuccess(data?.data)
    })
    .catch((error) => actions.onDetermineIncidentError(error?.response?.data ?? error))
}

const bulkRequestMethod = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SubmitSRAPI.bulkServiceRequest(data)
    .then((data) => {
      actions.onBulkSuccess(data?.data)
    })
    .catch((error) => actions.onBulkError(error?.response?.data ?? error))
}

const bulkRequestSubmit = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SubmitSRAPI.bulkServiceRequest(data)
    .then((data) => {
      actions.onBulkSubmitSuccess(data?.data)
    })
    .catch((error) => actions.onBulkSubmitError(error?.response?.data ?? error))
}

const getCustomerCaseGraph = (actions) => (data) => {
  return SubmitSRAPI.getCustomerCaseGraph(data)
    .then((data) => {
      actions.onGetCustomerCaseGraphSuccess(data?.data?.CustomerCaseResults)
    })
    .catch((error) => actions.onGetCustomerCaseGraphError(error?.response?.data ?? error))
}
const resumeServiceRequest = (actions) => (data) => {
  return SubmitSRAPI.resumeServiceRequest(data)
    .then((data) => {
      actions.onResumeServiceRequestSuccess(data?.data)
    })
    .catch((error) => actions.onResumeServiceRequestError(error?.response?.data ?? error))
}

const bulkCancelRequestMethod = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return SubmitSRAPI.bulkServiceRequest(data)
    .then((data) => {
      actions.cancelBulkServiceRequestSuccess(data?.data)
    }).catch((error) => {
      actions.cancelBulkServiceRequestError(error)
    })
}

const claimFlowApiRequest = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return SubmitSRAPI.claimFlowApiRequest(data)
    .then((claimData) => {
      if (data.FromRequest === "NEXT") {
        actions.claimFlowApiRequestSuccess(claimData?.data)
      }
      else if (data.FromRequest === "SAVE") {
        actions.claimFlowApiRequestSaveSuccess(claimData?.data)
      }
      else if (data.FromRequest === "SUBMIT") {
        actions.claimFlowApiRequestSubmitSuccess(claimData?.data)
      }
      else if (data.FromRequest === "CANCEL") {
        actions.claimFlowApiRequestCancelSuccess(claimData?.data)
      }
    }).catch((error) => {
      actions.claimFlowApiRequestError(error)
    })
}

const getBulkSrSaveAndSubmit = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return SubmitSRAPI.getAllSaveSubmitSrRequest(data)
    .then((apiData) => {
      if (data.isbulk) {
        actions.onBulkSrSaveAndSubmitSuccess(apiData?.data)
      } else if(!data.isbulk) {
        actions.onUpdateInquirySuccess(apiData?.data)
      }
    }).catch((error) => {
      actions.onBulkSrSaveAndSubmitError(error)
    })
}

const getDeviceIMEIDetails = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return SubmitSRAPI.getDeviceIMEIDetailsRequest(data)
    .then((data) => {
      actions.onGetDeviceIMEIDetailsSuccess(data?.data)
    }).catch((error) => {
      actions.onGetDeviceIMEIDetailsError(error)
    })
}

const getPresignUrl = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return SubmitSRAPI.getPresignUrlRequest(data)
    .then((data) => {
      actions.onGetPresignUrlSuccess(data?.data)
    }).catch((error) => {
      actions.onGetPresignUrlError(error)
    })
}

const uploadFile = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return SubmitSRAPI.uploadFileToS3(data)
  .then((data) => {
      actions.onUploadFileSuccess(data?.data)
  }).catch((error) => {
      actions.onUploadFileError(error)
  })
}

const updateIMEIInquiry = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return SubmitSRAPI.updateIMEIInquiryRequest(data)
    .then((data) => {
      actions.onUpdateIMEIInquirySuccess(data?.data)
    }).catch((error) => {
      actions.onUpdateIMEIInquiryError(error)
    })
}

const amtaCheck = (actions) => (data) => {
  actions.setProgressIndicator(true);
  return SubmitSRAPI.amtaCheckRequest(data)
    .then((data) => {
      actions.onAMTACheckSuccess(data?.data)
    }).catch((error) => {
      actions.onAMTACheckError(error)
    })
}

export const middleware = {
  getDeviceList,
  contactPoint,
  standardiseaddress,
  getCityState,
  bulkRequestMethod,
  getCustomerCaseGraph,
  resumeServiceRequest,
  bulkCancelRequestMethod,
  bulkRequestSubmit,
  claimFlowApiRequest,
  determineIncident,
  getBulkSrSaveAndSubmit,
  getDeviceIMEIDetails,
  getPresignUrl,
  updateIMEIInquiry,
  uploadFile,
  amtaCheck
}
