import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useShared } from '../../../state-management/shared/SharedContextProvider';
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider';
import { useForm } from 'react-hook-form';
import Loader from '../../loader';
import moment from 'moment';
export default (props) => {
    const { open, onCancel, data, onYes } = props;
    const { register, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
        mode: 'onChange'
    })
    const { state: iniState } = useShared();
    const { state: srState, getDeviceIMEIDetails, getPresignUrl, uploadFile, updateIMEIInquiry, amtaCheck, cleanGetDeviceByIMEI } = useSubmitSR();
    const [selectedFile, setSelectedFile] = useState(null);
    const contentStyleForDesktopView = {
        padding: '0px',
        alignSelf: 'center',
        width: '40%',
    }
    const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
    let selectedAgreementData = iniState.FindAgreement?.Agreements?.Agreement.filter(agreement =>{
        return agreement.AgreementId === data?.AgreementId;
    })[0]
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onloadend = () => {
                resolve(fileReader.result.split("base64,")[1]);
            };

            fileReader.onerror = (err) => {
                reject(err);
            };
        });
    };

    const onUpload = () => {
        let params = {
            FileUploadRequest: {
                Files: [
                    {
                        FileName: selectedFile?.name,
                        FolderType: "exception-management",
                        PolicyNumber: selectedAgreementData?.IdentificationNumber,
                        ClientName: "Samsung"
                    }
                ]
            }
        }
        getPresignUrl(params)
    }

    const blobConvertor = (base64) => {
        let binary;
        binary = atob(base64);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)]);
      }

    const checkFileExtension = (fileName) => {
        let ext = ['.jpg', '.jpeg', '.bmp', '.pdf'];
        let isValidExt = ext.map(i => {
            return fileName.endsWith(i) ? true : false;
        })
        return isValidExt.filter(i => i == true)[0] ? true : false;
    }

    let disableButton = !srState?.GetUpdateIMEIDeviceDetails?.IMEIFound || (srState?.GetUpdateIMEIDeviceDetails?.IMEIFound && selectedFile === null) || srState?.AMTAResponse?.status === 'Blocked' || selectedFile !== null && !checkFileExtension(selectedFile?.name) || (srState?.UpdateIMEIInquiryResponse);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === "NewIMEI") {
                if (value.NewIMEI.length == 15) {
                    getDeviceIMEIDetails({ IMEI: getValues('NewIMEI') });
                }
                if (value.NewIMEI.length == 0) {
                    cleanGetDeviceByIMEI()
                }
            }
        })
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if(srState?.GetUpdateIMEIDeviceDetails?.IMEIFound)
        {
            amtaCheck({IMEI : getValues('NewIMEI')})
        }
    },[srState?.GetUpdateIMEIDeviceDetails])

    useEffect(async () => {
        if (srState?.GetPresignUrlResponse) {
            const blob = blobConvertor(await convertBase64(selectedFile));
            let url = srState?.GetPresignUrlResponse[0]?.PreSignedURL[0];
            let type = selectedFile?.type;
            uploadFile({url, blob, type})
        }
    }, [srState?.GetPresignUrlResponse])

    useEffect(() => {
        if (srState?.UploadFileResponse) {
            let dueDate = moment();
            let inquiryParam = {
                CreateInquiryParams: {
                    PolicyNumber: selectedAgreementData?.IdentificationNumber,
                    AgreementId: selectedAgreementData?.AgreementId,
                    FirstName: selectedAgreementData?.Customers?.Customer[0]?.FirstName,
                    LastName: selectedAgreementData?.Customers?.Customer[0]?.LastName,
                    Email: selectedAgreementData?.ContactPoints?.ContactPoint.filter(i => i.ContactPointType == 'EMAIL')[0]?.EmailAddress,
                    ReferenceType: "WUD",
                    CustomerId: selectedAgreementData?.Customers?.Customer[0]?.CustomerId,
                    ClientName: "Samsung",
                    DepartmentType: "Care",
                    InquiryChannel: "Inquiry Channel",
                    InquiryType: "ENRMNT",
                    InquirySubType: "EUD",
                    InquiryDescription: "In-warranty exchange at OEM",
                    CallDriverCategory: "Update Device Info",
                    CallDriverSubCategory: "Update Device Info",
                    ProgramName: selectedAgreementData?.ClientOffer?.ClientOfferName,
                    ProgramType: "Handset Protection",
                    Priority: "HIGH",
                    AssetId: data?.AssetId,
                    Files: [
                        {
                            name: selectedFile?.name,
                            fileType: selectedFile?.type,
                            Path: srState?.GetPresignUrlResponse[0]?.Path1
                        }
                    ],
                    UpdateDeviceDetails: {
                        AssetCatalogId: srState?.GetUpdateIMEIDeviceDetails?.DeviceDetails[0]?.AssetCatalogId,
                        IMEI: getValues('NewIMEI'),
                        ClientAssetSkuNumber: srState?.GetUpdateIMEIDeviceDetails?.DeviceDetails[0]?.ItemId,
                        MobileDeviceNumber: selectedAgreementData?.ContactPoints?.ContactPoint.filter(i => i.ContactPointType == 'MOBILE')[0]?.PhoneNumber,
                        SubscriptionNumber: selectedAgreementData?.IdentificationNumber
                    },
                    DueDate : dueDate.add(2, 'days').format('MM/DD/yyyy')
                }
            }
            updateIMEIInquiry(inquiryParam)
        }
    }, [srState?.UploadFileResponse])

    return (
        <Popup
            open={open}
            contentStyle={contentStyleForDesktopView}
            overlayStyle={overlayStyle}
            closeOnDocumentClick={false}
            modal
            nested
        >
           {(iniState?.showProgressIndicator || srState?.showProgressIndicator) && <Loader isModalPopUp = 'isModalPopUp'  />}
           <div className = "flex justify-center w-auto">
            <div className="border bg-gray-100 p-4 bg-white rounded-md shadow-md dark:bg-gray-800 w-auto">
                <button className="absolute right-px whitespace-normal h-6 w-6 mx-4 text-sm bg-white border rounded text-black" onClick={onCancel}>
                    &times;
                </button>
                <div className="font-bold text-center">UPDATE MY DETAILS</div>
                
                <form onSubmit={handleSubmit(onUpload)}>
                    <div className="mb-3 mt-3">
                        <div className="text-center leading-5">Your IMEI may not match if you have had your device swapped outside the Samsung Care+ program as a result if your warranty swap, or swap from another program. To proceed with your Swap Request please upload your proof of swap document(how did you get this device? This document should include your old (enrolled) IMEI and the replacement (new) IMEI).</div><br />
                        <div className="text-center leading-5">Once this is uploaded and approved, any open swap request will be automatically completed. You will receive an email once your Swap Request is submitted.</div>
                        {/*<div className="text-justify">To continue with making a Swap Request and using your Swap Allowance, please close this pop-up window.</div><br /> */}
                        <div className="pl-6 pt-3 text-sm font-normal w-full py-1 text-black font-bold">Enrolled Device Details</div>
                        <hr className="shadow" />
                        <div className="pl-10 flex flex-row pt-2 justify-start">
                            <div className="text-sm flex-col font-bold">
                                IMEI<br /><br/>
                                Model<br />
                            </div>
                            <div className="pl-8 flex-col text-sm flex-grow">
                                {data?.IMEI}<br /><br/>
                                {data?.DeviceModelName}<br />
                            </div>
                        </div>
                        <div className="pl-6 pt-4 text-sm font-normal w-full py-1 text-black font-bold">New Device Details</div>
                        <hr className="shadow" />
                        <div className="pl-10 flex flex-row pt-2 justify-start">
                            <div className="text-sm flex-col font-bold">
                                IMEI<br /><br/>
                                Model<br /><br/>
                                Document <br/>
                            </div>
                            <div className="pl-8 flex-col text-sm flex-grow">
                                <div className="flex flex-row">
                                    <div className="flex flex-col">
                                        <input type="text"
                                            className="border border-gray-500 rounded text-gray-600 focus:text-blue-600"
                                            name="searchText" placeholder=" Enter New IMEI" maxLength="15"
                                            {...register("NewIMEI", { required: true, pattern: /^[0-9]+$/i})}
                                        /></div>
                                    <div className="flex flex-col justify-right pl-5">
                                        {errors?.NewIMEI?.type === "required" && <p className="text-red-400 text-sm">New Device IMEI is required</p>}
                                        {errors?.NewIMEI?.type === "pattern" && (
                                            <p className="text-red-400 text-sm">IMEI should only contain numbers</p>
                                        )}
                                        {/* {errors?.NewIMEI?.type === "maxLength" && (
                                            <p className="text-red-400 text-sm">IMEI limit is 15 characters</p>
                                        )} */}
                                        {(srState?.GetUpdateIMEIDeviceDetails && 'ValidIMEI' in srState.GetUpdateIMEIDeviceDetails && !srState?.GetUpdateIMEIDeviceDetails?.ValidIMEI) && (
                                            <p className="text-red-400 text-sm">{srState?.GetUpdateIMEIDeviceDetails?.ErrorMessage}</p>
                                        )}
                                        {(srState?.GetUpdateIMEIDeviceDetails && 'IMEIFound' in srState.GetUpdateIMEIDeviceDetails &&
                                            !srState?.GetUpdateIMEIDeviceDetails?.IMEIFound) && (
                                                <p className="text-red-400 text-sm">IMEI not found</p>
                                            )}
                                        {srState?.AMTAResponse?.status === 'Blocked' && (
                                            <p className="text-red-400 text-sm">AMTA check failed</p>
                                        )}</div> </div><br/>      
                                {srState?.AMTAResponse?.status === 'Unblocked' && srState?.GetUpdateIMEIDeviceDetails && srState?.GetUpdateIMEIDeviceDetails?.IMEIFound && srState?.GetUpdateIMEIDeviceDetails?.DeviceDetails[0]?.AssetCatalogName}<br/>
                                {
                                selectedFile && checkFileExtension(selectedFile?.name) && <p className="flex text-sm text-green-500 justify-left pt-4">{selectedFile?.name}</p>
                                }
                                {
                                selectedFile !== null && !checkFileExtension(selectedFile?.name) && <p className="flex text-sm text-red-500 justify-left  pt-4">Supports only .jpg/.jpeg, .bmp and .pdf</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center text-center">
                        <div className="flex flex-col mr-8">
                            <div className="flex flex-row">
                                <label htmlFor='file' className={`p-1 h-8 w-40 focus:bg-blue-100 text-sm bg-white border rounded text-black border-black cursor-pointer`}>UPLOAD</label>
                                <input className={`p-1 h-8 w-1 focus:bg-blue-100 text-sm bg-white border rounded text-black border-black cursor-pointer`} type="file" id="file" onChange={(e) => e.target.files[0] ? setSelectedFile(e.target.files[0]) : selectedFile.name ? setSelectedFile(selectedFile) : setSelectedFile(null)} style={{ opacity: 0 }} accept=".jpg, .jpeg, .bmp, .pdf" disabled={!srState?.GetUpdateIMEIDeviceDetails?.IMEIFound || srState?.AMTAResponse?.status === 'Blocked'} />
                            </div></div>
                        <div className="flex flex-col">
                            <button className={`p-1 h-8 w-40 focus:bg-blue-100 text-sm bg-white border rounded ${disableButton ? 'disable-button' : 'text-black border-black cursor-pointer'} `}
                                        type="submit" disabled = {disableButton}>SAVE</button>
                            </div>
                    </div>
                    {
                        srState?.UpdateIMEIInquiryResponse && <p className="pt-3 text-sm text-center text-green-500">Your Request Submitted Successfully.</p>
                    }
                </form>
                </div>
            </div>
        </Popup >
    )
};