/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
import SubmitResumeSRAPI from './api'

const getResumeServiceRequest = (actions) => (data) => {
  actions.setProgressIndicator(true)
  return SubmitResumeSRAPI.getResumeServiceRequest(data)
    .then((data) => {
      actions.onResumeSRSuccess(data?.data)
    })
    .catch((error) => {
      actions.onResumeSRError(error?.response?.data ?? error)
    })
}

const claimFlowApiRequest = (actions) => (data) =>{
  actions.setProgressIndicator(true);
  return SubmitResumeSRAPI.claimFlowApiRequest(data)
  .then((claimData)=>{
    if (data.FromRequest === "CANCEL"){
      actions.claimFlowApiRequestCancelSuccess(claimData?.data)
    }
    else if (data.FromRequest === "RESUME"){
      actions.claimFlowApiRequestResumeSuccess(claimData?.data)
    }
  }).catch((error)=>{
    actions.claimFlowApiRequestError(error)
  })
}

export const middleware = {
  claimFlowApiRequest,
  getResumeServiceRequest
}
