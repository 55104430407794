/* eslint-disable no-unused-vars */
const calculatePages = (records, pageSize) => (records % pageSize > 0) ? Math.trunc(records / pageSize) + 1 : records / pageSize;

const onInitializeSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isInitializeSuccess: true,
  showProgressIndicator: false,
})

const onInitializeError = (state, payload) => ({
  ...state,
  Error: 'Error loading initialize',
  showProgressIndicator: false,
})

const onInteractionSuccess = (state, payload) => ({
  ...state,
  InteractionResponse : payload,
  isInteractionSuccess: true,
  showProgressIndicator: false,
})

const onInteractionError = (state, payload) => ({
  ...state,
  Error: 'Error loading interaction',
  showProgressIndicator: false,
})

const onAgreementSuccess = (state, payload) => ({
  ...state,
  FindAgreement: payload?.Agreement?.FindAgreementsResults ? payload?.Agreement?.FindAgreementsResults?.Agreements?.Agreement.length > 0 && { ...payload?.Agreement?.FindAgreementsResults, TotalPages: calculatePages(+payload?.Agreement?.FindAgreementsResults?.Agreements?.Agreement[0].TotalRecords ? +payload?.Agreement?.FindAgreementsResults?.Agreements?.Agreement[0].TotalRecords : 1, 10), LicenseKeys : payload?.LicenseKeys } : payload?.Agreement,
  isAgreementSuccess: true,
  showProgressIndicator: false,
})

const onAgreementError = (state, payload) => ({
  ...state,
  Error: 'Error loading agreement',
  showProgressIndicator: false,
})


const unsetProgressIndicator = (state, payload) => ({
  ...state,
  showProgressIndicator: false,
})

const setProgressIndicator = (state, payload) => ({
  ...state,
  showProgressIndicator: true,
})

const setSelectedPage = (state, payload) => ({
  ...state, selectedPage: payload
})
const onAPIError = (state, payload) => ({
  ...state,
  isError: true,
  ErrorDetails: payload,
})

const cleanInit = (state, payload) => ({
  ...state,
  isAgreementSuccess: false,
  isInitializeSuccess: false,
  isInteractionSuccess: false,
  selectedAgreement : [],
  selectedSavedSR : [],
  selectedAgreementBulk:[],
  bulkDetails : [],
  bulkSrSaveAndSubmitData: "",
  bulkClaimStatus: ""
})

const unSetSelectedAgreement = (state, payload) => ({
  ...state,
  selectedAgreement: state.selectedAgreement.filter(i => i.AgreementId != payload[0].AgreementId)
})

const setSelectedAgreement = (state, payload) => ({
  ...state,
  selectedAgreement: [...new Set(state.selectedAgreement.concat(payload))]
});

const cleanSelectedAgreement = (state, payload) => ({
  ...state,
  selectedAgreement: []
})

const cleanFindAgreement = (state, payload) => ({
  ...state,
  isAgreementSuccess: false,
})

const setSelectedAgreementBulk = (state, payload) => ({
  ...state,
  selectedAgreementBulk: payload
});

const setSelectedSavedSR = (state,payload)=>({
  ...state,
  selectedSavedSR : payload
})
const onAccessTokenSuccess = (state, payload) => ({
  ...state,
  AccessTokenResponse: payload,
  showProgressIndicator: false,
})

const onAccessTokenError = (state, payload) => ({
  ...state,
  Error: 'Error loading agreement',
  showProgressIndicator: false,
})

const onUpdateInteractionSuccess = (state, payload) => ({
  ...state,
  UpdateInteractionResponse: payload,
  showProgressIndicator: false,
})

const onUpdateInteractionError = (state, payload) => ({
  ...state,
  Error: 'Error loading agreement',
  showProgressIndicator: false,
})

const onUpdateAddressBulk = (state, payload) => {
  return {
    ...state,
    addressToEditBulk : payload
  }
}
const setLicenseKey = (state, payload) => ({
  ...state, 
  LicenseKey: payload
})

const setInteractionLineId = (state, payload) => ({
  ...state,
  InteractionLineId: payload
})

const onGetLicensesSuccess = (state,payload) =>({
  ...state,
  showProgressIndicator:false,
  GetLicenseKeysResponse: payload
})

const onGetLicensesError = (state,payload) =>({
  ...state,
  showProgressIndicator:false,
  isError:true
})

const onGetUserInfoSuccess = (state, payload) => ({
  ...state,
  GetUserInfoResponse: payload,
  showProgressIndicator: false,
})

const onGetUserInfoError = (state, payload) => ({
  ...state,
  Error: 'Error loading userinfo',
  showProgressIndicator: false,
})

export const reducers = {
  cleanInit,
  onUpdateAddressBulk,
  onInitializeSuccess,
  onInitializeError,
  onInteractionSuccess,
  onInteractionError,
  onAgreementSuccess,
  onAgreementError,
  setSelectedAgreement,
  unsetProgressIndicator,
  setProgressIndicator,
  setSelectedPage,
  onAPIError,
  cleanFindAgreement,
  onAccessTokenSuccess,
  onAccessTokenError,
  onUpdateInteractionSuccess,
  onUpdateInteractionError,
  setLicenseKey,
  setInteractionLineId,
  setSelectedSavedSR,
  setSelectedAgreementBulk,
  onGetLicensesError,
  onGetLicensesSuccess,
  onGetUserInfoSuccess,
  onGetUserInfoError,
  cleanSelectedAgreement,
  unSetSelectedAgreement
}

export const initialState = {
  showProgressIndicator: false,
  selectedPage: 0,
  selectedAgreement: [],
  selectedSavedSR: [],
  selectedAgreementBulk:[]
}
