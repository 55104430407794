import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { useShared } from '../../../state-management/shared/SharedContextProvider';
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider';
import Loader from '../../loader';

export default (props) => {
    const { open, onClick, data, onYes } = props;
    const { state : srState } = useSubmitSR();
    const { state: iniState } = useShared();
    const contentStyleForDesktopView = {
        padding: '0px',
        alignSelf: 'center',
        width: '40%',
    }
    const overlayStyle = { background: 'rgba(0,0,0,0.5)' };

    return (
        <Popup
            open={open}
            contentStyle={contentStyleForDesktopView}
            overlayStyle={overlayStyle}
            closeOnDocumentClick={false}
            modal
            nested

        >
            {(iniState?.showProgressIndicator || srState?.showProgressIndicator) && <Loader isModalPopUp = 'isModalPopUp' />}
            <div className="border bg-gray-100 w-auto p-4 bg-white rounded-md shadow-md dark:bg-gray-800">
                <button className="absolute right-px whitespace-normal h-6 w-6 mx-4 text-sm bg-white border rounded text-black" onClick={onClick}>
                    &times;
                </button>
                <div className="font-bold text-center">YOU MAY BE ELIGIBLE FOR AN IN-WARRANTY CLAIM</div>
                <br />
                <div className="">
                    <div className="text-justify">If your registered device(s) have been swapped outside of the Samsung Care+ program you will need to provide us with the new IMEI details before your Swap Request can be processed. For e.g., your device registered device(s) may have been swapped due to an in-warranty claim through Samsung or your carrier.</div><br />
                    <div className="text-justify">To update your IMEI details, please upload the proof of swap document which shows the details of the original IMEI (registered with us) and the new IMEI.</div><br />
                    <div className="text-justify">Once this is verified by us, any pending Swap Requests will be processed. You will receive an email once your Swap Request is completed.</div><br />
                    <div className="pl-6 pt-4 text-sm font-normal w-full py-1 text-black font-bold">Current Device Details</div>
                    <hr className="shadow" />
                    <div className="pl-10 flex flex-row pt-2 justify-start">
                        <div className="text-sm flex-column font-bold">
                            IMEI<br />
                            Model<br />
                        </div>
                        <div className="pl-8 flex-column text-sm flex-grow">
                            {data?.Assets?.Asset[0]?.IMEI || data.IMEI}<br />
                            {data?.Assets?.Asset[0]?.Model?.Name || data.DeviceModelName}<br />
                        </div>
                    </div>
                </div>
                <div className="px-12 py-4 text-center">
                    {/* {cancelPopup && <Popup
                        trigger={<button className={`p-1 h-8 w-64 focus:bg-blue-100 text-sm bg-white border rounded text-black border-black cursor-pointer`}
                        >CANCEL SWAP REQUEST</button>}
                        position="top center"
                        nested
                    >
                        {<CancelPopup msg={"Are you sure you want to cancel?"} open={true} onYes={onYes} onNo={onNo} onClick={onNo} />}
                    </Popup>} */}
                    <button className={`p-1 h-8 w-64 focus:bg-blue-100 text-sm bg-white border rounded text-black border-black cursor-pointer`}
                        onClick={() => onYes()}>CANCEL SWAP REQUEST</button>
                </div>
            </div>
        </Popup >)
};