import React, { useContext } from 'react'
import { initialState, reducers } from './reducer'
import { middleware } from './middleware'
import { useSmartReducer } from '../useSmartReducer'

const ManageMyAccountContext = React.createContext()

const ManageMyAccountContextProvider = ({ children }) => {
  const [state, actions] = useSmartReducer(initialState, reducers, middleware)
  return <ManageMyAccountContext.Provider value={[state, actions]}>{children}</ManageMyAccountContext.Provider>
}

const useManageMyAccount = () => {
  const [state, actions] = useContext(ManageMyAccountContext)
  return { state, ...actions }
}

export { ManageMyAccountContextProvider, useManageMyAccount }
