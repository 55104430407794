import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFAQ } from '../../../state-management/faq/FaqContextProvider';
import { useShared } from '../../../state-management/shared/SharedContextProvider';

const Inquiry = () => {
  const { state: faqState, createInquiry } = useFAQ();
  const { state: iniState } = useShared();
  const [inquiryType, setInquiryType] = useState('Product Related');
  const location = window.location.search;
  const state = new URLSearchParams(location).get('state');
  let stateArray = state && state.split(' ');
  let prod_userName = stateArray && stateArray[2];
  const userName = new URLSearchParams(location).get('user_name');
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({ mode: 'onChange' })

  useEffect(() => {
    if (faqState.CreateInquiryResponse !== undefined) {
      setValue('Email', '');
      setValue('ContactNumber', '');
      setValue('description', '');
      setInquiryType('Product Related');
    }
  }, [faqState.CreateInquiryResponse])

  const onSubmit = (data) => createInquiryCall(data);

  const createInquiryCall = (data) => {
    let inquirydata = {
      CreateInquiryParams: {
        ContactNumber: data?.ContactNumber,
        InquiryDescription: data?.description,
        Note: data?.description,
        SelectedInquiryType: inquiryType,
        MobileDeviceNumber: data?.ContactNumber,
        EmailAddress: data?.Email,
        FirstName: iniState?.FindAgreement?.Agreements?.Agreement[0]?.Customers?.Customer[0]?.FullName, //prod_userName || userName,
        ClientAccountId: iniState?.FindAgreement?.Agreements?.Agreement[0]?.ClientAccount?.ClientAccountId,
        InteractionLineId: iniState?.InteractionLineId,
        AgreementId : iniState?.FindAgreement?.Agreements?.Agreement[0]?.AgreementId,
        PolicyNumber : iniState?.FindAgreement?.Agreements?.Agreement[0]?.ClientAccount?.ClientAccountNumber
      }
    }
    createInquiry(inquirydata);
  }

  return (
    <div className="col-start-3 flex border-solid border-2 border-light-blue-500">
      <div className="px-4">
        <div className="flex"><br />
          {/* <img src="https://asurion.samsungcareplus.com.au/images/Samsung_CarePlus_icons_Care_blk_RBG.jpg"
            alt="icon" className="object-scale-down h-14 w-14 "
          /> */}
          <div className="left-px text-xl font-normal w-full py-2 text-black font-semibold text-center">Inquiry Form</div>

        </div>
        <p className="text-justify">Please enter your details below and click submit. One of our experts
          will contact you within the next 1-2 business days to assist you.
        </p>
        <br/>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">Email address </label>
          <input type="text" className="border rounded w-full border-solid" {...register("Email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })} />
          {errors?.Email?.type === "required" && <p className="text-red-400 text-sm">Email Address is required</p>}
          {errors?.Email?.type === "pattern" && (
            <p className="text-red-400 text-sm">Email Address should be valid</p>
          )}
          <label htmlFor="number">Contact Number</label>
          <input type="tel" className="border rounded w-full border-solid" {...register("ContactNumber", { required: true, maxLength: 12, minLength:10, pattern: /^[0-9]+$/i })} />
          {errors?.ContactNumber?.type === "required" && <p className="text-red-400 text-sm">Contact Number is required</p>}
          {errors?.ContactNumber?.type === "maxLength" && (
            <p className="text-red-400 text-sm">Contact Number should be at most 12</p>
          )}
          {errors?.ContactNumber?.type === "minLength" && (
            <p className="text-red-400 text-sm">Contact Number should be at least 10</p>
          )}
          {errors?.ContactNumber?.type === "pattern" && (
            <p className="text-red-400 text-sm">Contact Number should only contain numbers</p>
          )}
          <label htmlFor="assist">Choose what you need assistance with</label>
          <div className="relative inline-block w-full text-gray-900">
            <select defaultValue={inquiryType} value={inquiryType} onChange={(e) => setInquiryType(e.target.value)} className="w-full h-10 pl-2 pr-1 text-base placeholder-gray-900 border rounded-lg appearance-none focus:shadow-outline" placeholder="Choose what you need Assistance with">
              <option value="Product Related">Product Related</option>
              <option value="Making a Swap Request">Making a Swap Request</option>
              <option value="Dispatch and Delivery">Dispatch and Delivery</option>
              <option value="Charges and Refund">Charges  and Refund</option>
              <option value="Faulty Device">Faulty Device</option>
              <option value="Others">Others</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" fillRule="evenodd"></path></svg>
            </div>
          </div>
          <label htmlFor="words">Describe your query in a few words</label><br />
          <textarea className="w-full h-12 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" name="description" {...register("description", { required: true, maxLength: 250 })}></textarea>
          {errors?.description?.type === "required" && <p className="text-red-400 text-sm">Description is required</p>}
          <button type="submit" className={`text-black focus:bg-blue-100 border-black p-1 text-sm bg-white border rounded`}>CREATE INQUIRY</button>
          {
            faqState?.CreateInquiryResponse && <p className="text-green-500 mt-5 text-sm font-semibold">{`Thank you for submitting your inquiry WIN-${faqState?.CreateInquiryResponse?.EscalationNumber}. We will respond to you within 1-2 business days.`}</p>
          }
        </form>
      </ div>
    </div>
  );
}

export default Inquiry;
