import axios from 'axios';

//const location = window.location.search;
//const state = new URLSearchParams(location).get('state');
//let stateArray = state && state.split(' ');
//const userName = stateArray && stateArray[2];
//const userNameForNonProd = new URLSearchParams(location).get('user_name');

const API = {
  headers: {
    Accept: 'application/json',
    'Asurion-Client': 'Samsung',
    'Asurion-channel': 'Online',
    'Asurion-enduser': 'Samsung-Enterprise-Portal',
    'Asurion-lineofbusiness': 'MOBILITY',
    'Asurion-region': 'APAC-SEA',
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY
  },
  loginHeader: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': 'no-referrer-when-downgrade'
  },
  get(url) {
    return axios.get(url, { headers: this.headers })
  },
  post(url, data) {
    return axios.post(url, data, { headers: this.headers })
  },
  put(url, data) {
    return axios.put(url, data, { headers: this.headers })
  },
  updateHeaders(headers) {
    this.headers = { ...this.headers, ...headers }
  },
  getAccessToken(url) {
    return axios.post(url)
  },
  uploadFileToS3(url, file, type) {
    return axios({
      method: 'put',
      url: url,
      headers: {
          'Content-Type': type,
      },
      data: file
    })
  }
}

export default API
