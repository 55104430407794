const getPageNumbers = (pageNumber, noOfPages, maxPages = 10) => {
  let pageCount = 1
  let startPage = pageNumber,
    endPage = pageNumber
  while (pageCount < maxPages) {
    if (endPage + 1 <= noOfPages) {
      endPage++
      pageCount++
    }
    if (pageCount === maxPages) {
      break
    }
    if (startPage - 1 >= 1) {
      startPage--
      pageCount++
    }
    if (pageCount === maxPages) {
      break
    }
    if (startPage === 1 && endPage === noOfPages) {
      break
    }
  }
  let pages = []
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i)
  }
  return pages
}

const Pagination = ({ pageNumber, totalPages = 1, onPageClicked }) => {
  const label = `Page ${pageNumber} of ${totalPages}`
  const pages = getPageNumbers(pageNumber, totalPages)
  return (
    <div className="flex justify-between">
      <div className="text-sm">{label}</div>
      <div>
        <ul className="flex">
          <li className="mx-1">
            <button
              type="button"
              onClick={() => pageNumber > 1 && onPageClicked(pageNumber - 1)}
              className={`p-1 px-2 rounded text-sm border border-gray-dark ${pageNumber === 1 ? 'text-gray-400' : ''}`}
            >
              {'<'}
            </button>
          </li>
          {pages.map((page) => (
            <li key={`key-${page}`} className="mx-1">
              <button
                type="button"
                onClick={() => pageNumber !== page && onPageClicked && onPageClicked(page)}
                className={`p-1 px-2 rounded text-sm border border-gray-dark ${pageNumber === page ? 'text-gray-400' : ''}`}
              >
                {page}
              </button>
            </li>
          ))}
          <li className="mx-1">
            <button
              type="button"
              onClick={() => pageNumber < totalPages && onPageClicked(pageNumber + 1)}
              className={`p-1 px-2 rounded text-sm border border-gray-dark ${pageNumber === totalPages ? 'text-gray-400' : ''}`}
            >
              {'>'}
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Pagination
