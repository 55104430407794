import { MemoryRouter as Router, Switch, Route, Redirect, NavLink, useHistory } from 'react-router-dom'

import IconCaretRight from '../icons/icon-caret-right'
import IconCaretLeft from '../icons/icon-caret-left'
import {ReactComponent} from '../assets/samsungcare2.svg';
import IconBell from '../icons/icon-bell'
import IconHelp from '../icons/icon-help'
import IconRefresh from '../icons/icon-refresh'
import { useState } from 'react'
import { useSubmitSR } from '../state-management/submitservicerequest/SubmitSRContextProvider'
import { useShared } from '../state-management/shared/SharedContextProvider'
import { useSubmitResumeSR } from '../state-management/resumeservicerequest/SubmitRSRContextProvider'

const Header = ({ label, count = 32 }) => {

  return (
    <div className="flex flex-col bg-gray-200 -m-4 p-4 pb-2">
      {/* <div className="flex flex-row-reverse border-b border-gray-300 pb-2">
          <span className="block bg-green-300 p-1 m-1 text-xs w-6 h-6 border border-green-300 rounded-full">{count}</span>
          <IconHelp className="w-8 h-8 p-2 cursor-pointer" />
          <IconBell className="w-8 h-8 p-2 cursor-pointer" />
        </div> */}
      <div className="mt-5">
        <p className="text-xl">{label}</p>
        {/* <p className="text-xs mt-2 text-gray-700">
            Last updated on June 23 at 7:59 PM <IconRefresh className="inline-block ml-2 w-3 h-3 cursor-pointer" />{' '}
          </p> */}
      </div>
    </div>
  )
}

const checkActive = (match, location) => {
  if (!location) return false;
  const { pathname } = location;
  console.log(pathname);
  return (pathname === "/") || (pathname === "/submit-service-request");
}

const Layout = ({ children, label }) => {
  return (
    <div className="flex flex-row h-screen">
      <Sidebar className="" />
      <div className="flex-1 p-4 pt-2">
        <Header label={label} />
        {children}
      </div>
    </div>
  )
}


const Sidebar = ({ children, className, ...props }) => {
  let [isExpanded, setIsExpanded] = useState(true)
  const { state: srState, cleanPreviousResultsNext, cleanPreviousResultsSubmit } = useSubmitSR();
  const { state: iniState, cleanInit } = useShared();
  const { state: rsrState, isReusmeSR} = useSubmitResumeSR();

  const cleanPreviousStateData = () => {
    isReusmeSR(false);
    cleanPreviousResultsNext();
    cleanPreviousResultsSubmit();
    cleanInit();
  }

  return (
    <div className={`bg-gray-800 text-white ${className} flex flex-row ${isExpanded ? 'w-56' : 'w-6'}`} {...props}>
      {isExpanded ? (
        <div className="flex-1 py-2 px-4">
        {/*   <h1 className="text-2xl">SAMSUNG</h1> */}
          <ReactComponent className ='h-15 mt-2'/>
          <p className="text-lg mt-4">Swap Request Portal</p>
          <ul className="mt-12">
            <li className="py-2">
              <NavLink activeClassName="bg-gray-700 block -mx-4 pr-6 pl-4 -mr-10" isActive={checkActive} to="/submit-service-request" onClick={cleanPreviousStateData}>
              Submit New Swap Request
              </NavLink>
            </li>
            <li className="py-2">
              <NavLink activeClassName="bg-gray-700 block -mx-4 px-4 -mr-10" to="/resume-cancel-service-request" onClick={() => { cleanPreviousStateData(); isReusmeSR(true) }}>
              Resume / Cancel Open Swap Request
              </NavLink>
            </li>
            <li className="py-2">
              <NavLink activeClassName="bg-gray-700 block -mx-4 px-4 -mr-10" to="/manage-account" onClick={cleanPreviousStateData}>
                Manage My Account
              </NavLink>
            </li>
            <li className="py-2">
              <NavLink activeClassName="bg-gray-700 block -mx-4 px-4 -mr-10" to="/faq" onClick={cleanPreviousStateData}>
                FAQ
              </NavLink>
            </li>
            <li className="py-2">
              <NavLink activeClassName="bg-gray-700 block -mx-4 px-4 -mr-10"  to={{pathname:"https://central.samsungknox.com/"}} target="_blank">
              Samsung B2B Portal
              </NavLink>
            </li>
          </ul>
        </div>
      ) : (
        true
      )}

      <div className="flex flex-col">
        {isExpanded ? (
          <IconCaretLeft className="my-auto cursor-pointer bg-gray-600" onClick={() => setIsExpanded(false)} />
        ) : (
          <IconCaretRight className="my-auto cursor-pointer bg-gray-600" onClick={() => setIsExpanded(true)} />
        )}
      </div>
    </div>
  )
}

export default Layout;