import React, { Component, useContext, useEffect } from 'react';
//import SingleSRSubmit from './SubmitNewSR';
import { MemoryRouter as Router, Switch, Route, Redirect, NavLink, useHistory } from 'react-router-dom'
import { useShared } from '../../../state-management/shared/SharedContextProvider';
import Layout from '../../header';
import Inquiry from './inquiry';
import Faq from './faq';
import Loader from '../../loader';
import { useFAQ } from '../../../state-management/faq/FaqContextProvider';

const FAQSection = () => {
    const { state: faqState, createInquiry } = useFAQ();

    return (
        <>
            {faqState?.showProgressIndicator && <Loader />}
            <Layout label="FAQ">
                <div className="py-6">
                    <div className="grid grid-cols-3 grid-flow-rows gap-2">
                        <Faq />
                        <Inquiry />
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default FAQSection