import API from '../api'

const initialize = (data) => API.post('/api/v2/initialize', data)
const interaction = (data) => API.post('/api/v2/interaction', data)
const updateInteraction = (data) => API.put('/api/v2/interaction', data)
const findAgreements = (data) => API.post('/api/findagreements',data)
const getAccessToken = (data) => API.post('/api/v1/ssoaccesstoken',data)
const getLicenses = (data) => API.post('/api/v1/getlicenses',data)
const getUserInfoRequest = (data) => API.post('/api/v1/ssouserinfo',data)

const SharedAPI = {
  initialize,
  interaction,
  findAgreements,
  getAccessToken,
  updateInteraction,
  getLicenses,
  getUserInfoRequest
}

export default SharedAPI
