import { useEffect, useState } from 'react'
import Grid from '../../grid'
import Layout from '../../header'
import { MemoryRouter as Router, Switch, Route, Redirect, NavLink, useHistory, useParams } from 'react-router-dom'
import tableFormat from '../../tableformat'
import Pagination from '../../pagination'
import { useShared } from '../../../state-management/shared/SharedContextProvider'
import { useSubmitSR } from '../../../state-management/submitservicerequest/SubmitSRContextProvider'
import { useSubmitResumeSR } from '../../../state-management/resumeservicerequest/SubmitRSRContextProvider'
import moment from 'moment';
import CancelPopup from '../submitservicerequest/confirmationpopup'
import Loader from '../../loader';
import arrowUp from '../../../assets/BiChevronUp.png';
import arrowDown from '../../../assets/BiChevronDown.png';

const ResumeOrCancelSR = () => {
  const perpageData = 10;
  const _grdFields = {
    key: 'IMEI',
    showRadioCheck: true,
    fields: [
      { name: 'SRNumber', label: 'Swap Request No.' },
      { name: 'IMEI', label: 'IMEI' },
      { name: 'DeviceModelName', label: 'Device Model Name' },
      //{ name: 'LicenseKey', label: 'License Key' },
      { name: 'SROpen', label: 'SR Open' },
      { name: 'DateStarted', label: 'Date Started' }
    ],
  }
  const history = useHistory();
  const [deviceList, setDeviceList] = useState([]);
  const [selectedSR, setResumedata] = useState([]);
  const [grdFields, setGrdFields] = useState(_grdFields);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedAgreementList, setSelectedAgreementList] = useState([]);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [searchType, setSearchType] = useState('IMEI');
  const [searchData, setSearchData] = useState('');
  const { state: iniState, findAgreements, setSelectedAgreement, cleanFindAgreement } = useShared();
  const { state: srState, getCustomerCaseGraph } = useSubmitSR();
  const { state: rsrState, claimFlowApiRequest, getResumeServiceRequest, setUpdatedResumeAfterDelete, cleanPreviousGetResumeServiceRequest } = useSubmitResumeSR();
  const [open, setOpen] = useState(false);
  let license_key = iniState?.LicenseKey;
  const [countAllData, setCountAllData] = useState(0);
  const [allGetResumeData, setAllResumeData] = useState([]);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    getResumeServiceRequest(license_key);
  }, [rsrState.isReusmeSR]);

  useEffect(() => {
    if (rsrState.GetResumeServiceRequest) {
      if (rsrState.GetResumeServiceRequest?.results.length <= 0) {
        setNoData(true);
      } else {
        setNoData(false)
        let FilteredData = rsrState?.GetResumeServiceRequest?.results?.map((value, index) => {
          return {
            SRNumber: value.ClaimId,
            IMEI: value.IMEI,
            DeviceModelName: value.AssetCatalogName,
            //LicenseKey: value.LicenseKey,
            SROpen: 1,
            DateStarted: moment(value.ClaimCreatedDate).format('DD MMM YYYY')
          }
        })
        setAllResumeData(FilteredData);
      }
    }
  }, [rsrState.GetResumeServiceRequest]);


  useEffect(() => {
    let newListData = [];
    setCountAllData(allGetResumeData.length);
    allGetResumeData.forEach((element, index) => {
      if (index + 1 > (pageNumber - 1) * perpageData && index + 1 <= (pageNumber) * perpageData) {
        newListData.push(element);
      }
    })
    setDeviceList(newListData);
  }, [allGetResumeData, pageNumber])

  const onRowCheckChange = (data, index) => {
    const newData = { ...data, isChecked: !data.isChecked, name: 'isresume' }
    // Set selected radio button sr details only.
    setResumedata([newData])
    let _deviceList = deviceList || []
    _deviceList = _deviceList.map((row, i) => (i === index ? newData : row))
    if (!newData.isChecked) {
      grdFields.isChecked = false
      setGrdFields(grdFields)
    }
    setDeviceList(_deviceList)
  }

  const onHeaderCheckChange = (isChecked) => {
    grdFields.isChecked = isChecked
    setGrdFields(grdFields)
  }

  // RESUME SR FLOW
  useEffect(() => {
    if (srState.CustomerCaseGraphResponse) {
      history.replace('/submit-service-request-confirmation')
    }
  }, [srState.CustomerCaseGraphResponse]);

  // Search filter
  const search = (e) => {
    //setNoData(false)
    if (searchType === 'IMEI') {
      setDeviceList(allGetResumeData.filter(i => i.IMEI === searchData))
    }
    if (searchType === 'SRNo') { setDeviceList(allGetResumeData.filter(i => i.SRNumber == searchData)) }
  }

  // Resume scenario
  const onResumeClick = () => {
    let selected = selectedSR;
    let _selectedResumeSR = rsrState.GetResumeServiceRequest?.results.filter(item => selected.find(i => i.SRNumber === item.ClaimId));
    let _selectedAgreementList = [...iniState.FindAgreement?.Agreements?.Agreement.filter(item => _selectedResumeSR.find(i => i.AgreementId === item.AgreementId))]
    setSelectedAgreementList(_selectedAgreementList);
    setSelectedAgreement(_selectedAgreementList);
    if (selected.length === 0) {
      //TODO: Do nothing
      return;
    }
    if (selected.length === 1) {
      //single SR flow
      let resumeRequest = {
        EnterpriseClaimApiParameters: {
          ServiceRequestId: _selectedResumeSR[0].ServiceRequestId,
          InteractionLineId: iniState?.InteractionLineId,
          ClientAccountType: "Enterprise"
        },
        FromRequest: "RESUME"
      }
      claimFlowApiRequest(resumeRequest)
      getCustomerCaseGraph({ clientAccountId: _selectedAgreementList[0]?.ClientAccount?.ClientAccountId })
    }
  }

  // Cancel Resume scenario
  const onCancel = () => {
    setShowCancelPopup(true);
  }

  const onNo = () => {
    setShowCancelPopup(false);
  }

  // Go for cancel SR
  const onYes = () => {
    let selected = selectedSR;
    let _selectedResumeSR = rsrState.GetResumeServiceRequest?.results.filter(item => selected.find(i => i.SRNumber === item.ClaimId));
    let CancelServiceRequestParams = {
      EnterpriseClaimApiParameters: {
        NoteText: "", Operation: "Cancel",
        Reason: "Cancel-Customer", SubReason: "Customer-Cancelled by Customer (Device Found/Customer Choice)", CancelEntities: "ALL", RequestedBy: "Agent", ReturnEntity: true,
        InteractionLineId: iniState?.InteractionLineId,
        ServiceRequestId: _selectedResumeSR[0]?.ServiceRequestId,
        ClientAccountType: "Enterprise"
      }, FromRequest: "CANCEL"
    }
    claimFlowApiRequest(CancelServiceRequestParams);
    setShowCancelPopup(false);
  }

  // Cancel SR success response. Show loader and remove SR row from grid
  useEffect(() => {
    if (rsrState?.claimFlowApiResponsesForCancel?.CancelServiceRequestResponse?.Result?.CustomerCases[0]) {
      const cancelSR = rsrState.claimFlowApiResponsesForCancel?.CancelServiceRequestResponse?.Result?.CustomerCases[0]?.CustomerCaseNumber;
      let updatedListAfterCancel = rsrState.GetResumeServiceRequest?.results;
      let index = updatedListAfterCancel.findIndex(i => i.ClaimId === cancelSR);
      updatedListAfterCancel.splice(index, 1);
      let newGetResumeServiceRequestObj = Object.create(updatedListAfterCancel);
      setUpdatedResumeAfterDelete(newGetResumeServiceRequestObj);
    }
  }, [rsrState.claimFlowApiResponsesForCancel])

  const onSearchDataChanged = (e) => {
    if (e === '') {
      setDeviceList(allGetResumeData);
      setSearchData('');
    } else {
      setSearchData(e)
    }
  }

  const onSearchTypeChanged = (e) => {
    if (e === '') {
      setDeviceList(allGetResumeData);
      setSearchData('');
      setSearchType('');
    } else {
      setSearchData('');
      setSearchType(e)
    }
  }
  // End 

  return (
    <>
      {(iniState?.showProgressIndicator || srState?.showProgressIndicator || rsrState?.showProgressIndicator) && <Loader />}
      <Layout label="Resume or Cancel Swap Request">
        {showCancelPopup && <CancelPopup msg={"Are you sure you want to cancel?"} open={showCancelPopup} onYes={onYes} onNo={onNo} onClick={onNo} />}
        <div className="py-2">
          <div className="flex flex-row">
            <span className="mt-5 mb-2 text-center pr-2 text-bold h-10">Search By</span>
            <span className="mt-5 mb-2 flex-col pr-2 relative inline-block text-gray-900">
              <select defaultValue={searchType} name="searchSelect" className="w-60 h-7 pl-2 pr-1 text-base placeholder-gray-900 border rounded-lg appearance-none focus:shadow-outline" onChange={(e) => onSearchTypeChanged(e.target.value)} onClick={() => setOpen(!open)}>
                <option value="IMEI">IMEI</option>
                <option value="SRNo">Swap Request No</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex content-center px-2 pointer-events-none">
                <img
                  alt=""
                  className="flex-column cursor-pointer w-6 h-6"
                  src={open ? arrowUp : arrowDown}
                />
              </div>
            </span>
            <span className="mt-5 mb-2 w-50 h-7 border-2 py-1 px-3 flex justify-between rounde-md rounded-md">
              <input type="text" className="outline-none text-gray-600 focus:text-blue-600" name="searchText" onChange={(e) => onSearchDataChanged(e.target.value)} value={searchData} />
            </span>
            <span className="mt-5 mb-2 pl-2"><button className={`p-1 h-7 w-24 text-sm bg-white border rounded text-black border-black`}
              onClick={(e) => search()}>Search</button></span>
          </div>
          {!noData ? <>
            {deviceList && deviceList.length > 0 && <div className="py-2 overflow-x-auto">
              <Grid
                className="text-sm font-normal w-full border border-black border-collapse"
                onRowCheckChange={onRowCheckChange}
                onHeaderCheckChange={onHeaderCheckChange}
                list={deviceList || []}
                headerRow={tableFormat.headerRow(grdFields)}
                dataRow={tableFormat.dataRow(grdFields)}
              />
            </div>}
            {deviceList && <Pagination
              pageNumber={pageNumber ? pageNumber : 1}
              totalPages={Math.ceil(countAllData / perpageData) || 1}
              onPageClicked={(page) => setPageNumber(page)} />}
          </> : <div>No Records Found</div>
          }
          <div className="flex flex-row py-9">
            <div className="flex-1">
              {/*<input className={`p-1 w-48 mr-4 text-sm bg-white border rounded text-gray-400 border-gray-400  ${deviceList?.filter(i => i.isChecked).length === 1 ? 'text-black border-black' : 'text-gray-400 border-gray-400'}`} type="button" value="UPDATE IMEI" /> */}
            </div>
            <div>
              {/* <button onClick={backButtonForLandingPage} className={`p-1 w-24 mr-2 focus:bg-blue-100 text-sm bg-white border rounded  ${deviceList?.find(i => i.isChecked) ? 'text-black border-black' : 'text-gray-400 border-gray-400'}`} >BACK</button> */}
              {/* <button className={`p-1 w-24 mx-4 text-sm bg-white border rounded text-gray-400 border-gray-400 ${deviceList?.find(i => i.isChecked) ? 'text-black border-black' : 'text-gray-400 border-gray-400'}`}>RESUME</button> */}
              <button disabled={noData} onClick={onResumeClick} className={`p-1 w-24 mr-2 text-sm bg-white border focus:bg-blue-100 rounded text-black border-black ${deviceList?.find(i => i.isChecked) ? 'text-black border-black' : 'disable-button'}`}>RESUME</button>
              <button disabled={noData} onClick={onCancel} className={`p-1 w-24 text-sm bg-white border focus:bg-blue-100 rounded  ${deviceList?.find(i => i.isChecked) ? 'text-black border-black' : 'disable-button'}`}>CANCEL</button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ResumeOrCancelSR;
