const isEven = (n) => n % 2 === 0;

const headerRow = ({ fields, showCheck, isChecked }) => (onHeaderCheckChange, onSort) =>
(
  <tr className="border border-black bg-black text-white">
    {showCheck && (
      <th className="font-normal py-1 px-2 w-10">
        {/* <input type="checkbox" value={isChecked} onChange={() => onHeaderCheckChange(!isChecked)} name="selectAll" /> */}
      </th>
    )}
    {fields.map((field) => (
      <th className="font-normal py-1 align-top" key={field.name}>
        <div className="flex px-1">
          <span
            className="flex-1 text-left"
            dangerouslySetInnerHTML={{
              __html: field.label.split('|').join('<br/>'),
            }}
          />
        </div>
      </th>
    ))}
  </tr>
)

const dataRow = (fields) => (data, i, onRowCheckChange, reprintPDF, showAddressPopup) => {

  const getValue = (field, data) => {
    const value = (field.formatter ? field.formatter(data, field) : data[field.name]) || ''
    return typeof value === 'string' ? value.split('|').join('<br/>') : value
  }

  return (
    <tr key={`row-${i}`} className={`border border-gray-dark ${isEven(i) ? 'bg-gray-light' : ''}`}>
      {fields.showCheck && (
        <td className="font-normal py-1 px-2 align-top w-10">
          <input type="checkbox" value={fields.isChecked || data.isChecked || false} checked={data?.isChecked} onChange={(e) => onRowCheckChange(data, i)} />
        </td>
      )}

      {fields.fields.map((field) => {
        return (

          field.showSelect ? <td
            className="py-1 px-1 align-top"
            key={`${field.name}-${data[fields.key]}`}>
            <select name={field.name} defaultValue={data[field.name]} value={data[field.name]} disabled={!data.isChecked} onChange={(e) => onRowCheckChange({ ...data, [e.target.name]: e.target.value }, i, 'select')} className={`pl-2 pr-2 text-base  appearance-none focus:shadow-outline ${isEven(i) ? 'bg-gray-light' : ''}`}
              >
              <option value="Catastrophic Damage">Other reason</option>
              <option value="Swap">My device isn't functioning as it should</option>
              <option value="Liquid Exposure">Liquid exposure</option>
              <option value="Scratched and dented">Scratched and dented</option>
            </select>
            <br />
            {/* <span className="text-red-600">{JSON.stringify(data)}</span> */}
          </td> :
            field.showText ?
              <td
                className="py-1 px-1 align-top"
                key={`${field.name}-${data[fields.key]}`}>
  
                <input type="text" disabled={!data.isChecked} className={`${isEven(i) ? `bg-gray-light ${!data.isChecked ? 'text-gray-500' : 'text-black'}` : `${!data.isChecked ? 'text-gray-500' : 'text-black'}`}`} name={field.name} value={getValue(field, data)} onChange={(e) => onRowCheckChange({ ...data, [e.target.name]: e.target.value, validationError: { [e.target.name]: getValue(field, data).length < 1 && "input is required." } }, i, 'input')} onClick={(e) => showAddressPopup(e.target.name, data)} />
                <br />
  
                {data.isChecked && getValue(field, data).length === 0 && <span className="text-red-600">{`${field?.label} is required.`}</span>}
              </td > :
              <td
                className="py-1 px-1 align-top"
                key={`${field.name}-${data[fields.key]}`}>
                {getValue(field, data)}
              </td>
        )
      })
      }
    </tr >
  )
}

const tableFormatBulk = {
  headerRow,
  dataRow
}

export default tableFormatBulk;