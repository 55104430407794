/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { initialState, reducers } from './reducer'
import { middleware } from './middleware'
import { useSmartReducer } from '../useSmartReducer'

const SubmitSRContext = React.createContext()

const SubmitResumeSRContextProvider = ({ children }) => {
  const [state, actions] = useSmartReducer(initialState, reducers, middleware)
  return <SubmitSRContext.Provider value={[state, actions]}>{children}</SubmitSRContext.Provider>
}

const useSubmitResumeSR = () => {
  const [state, actions] = useContext(SubmitSRContext)
  return { state, ...actions }
}

export { SubmitResumeSRContextProvider, useSubmitResumeSR }
