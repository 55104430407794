import React, { useContext } from 'react'
import { initialState, reducers } from './reducer'
import { middleware } from './middleware'
import { useSmartReducer } from '../useSmartReducer'

const SharedContext = React.createContext()

const SharedContextProvider = ({ children }) => {
  const [state, actions] = useSmartReducer(initialState, reducers, middleware)
  return <SharedContext.Provider value={[state, actions]}>{children}</SharedContext.Provider>
}

const useShared = () => {
  const [state, actions] = useContext(SharedContext)
  return { state, ...actions }
}

export { SharedContextProvider, useShared }
